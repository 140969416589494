/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, DateRange, Grid, Heading, HFlow, Icon, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { Form, FormRenderProps, RadioGroupField, RadioOption } from 'components/form'
import { CheckboxGroupField, CheckboxOption } from 'components/form/field/CheckboxGroupField'
import DateRangeField from 'components/form/field/DateRangeField'
import { useGerarRelatorioRndsCsvMutation } from 'graphql/hooks.generated'
import { StatusRelatorioEnvioRndsEnum, TipoRegistroRndsEnum } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { noop } from 'lodash'
import { Fragment, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, periodBeforeEqualToday, required, requiredPeriod } from 'util/validation'

import { RndsRelatorioImpressaoModel } from './model-rndsRelatorio'

const meta = metaPath<RndsRelatorioImpressaoModel>()

const initialValues: RndsRelatorioImpressaoModel = {
  periodo: {
    startDate: null,
    endDate: null,
  },
  statusEnvioRegistro: StatusRelatorioEnvioRndsEnum.TENTATIVAS_ERROS,
  tipoRegistro: [],
}

const opcoesTipoRegistro: CheckboxOption<TipoRegistroRndsEnum>[] = [
  {
    label: 'Imunobiológico administrado em: Campanha (RIA-C) ou Rotina (RIA-R)',
    value: TipoRegistroRndsEnum.VACINA,
  },
  {
    label: 'Atendimento Clínico (RAC)',
    value: TipoRegistroRndsEnum.ATENDIMENTO_INDIVIDUAL,
  },
  {
    label: 'Prescrição de Medicamentos (RPM)',
    value: TipoRegistroRndsEnum.PRESCRICAO_MEDICAMENTO,
  },
]

const opcoesStatusEnvioRegistro: RadioOption<StatusRelatorioEnvioRndsEnum>[] = [
  {
    label: 'Tentativas com erro',
    value: StatusRelatorioEnvioRndsEnum.TENTATIVAS_ERROS,
  },
  {
    label: 'Enviados com sucesso',
    value: StatusRelatorioEnvioRndsEnum.ENVIADOS_SUCESSO,
  },
]

const validate = createValidator<RndsRelatorioImpressaoModel>({
  periodo: [requiredPeriod, periodBeforeEqualToday],
  statusEnvioRegistro: [required],
  tipoRegistro: [required],
})

export function RelatorioRndsForm() {
  const theme = useTheme()
  const styles = createStyles(theme)
  const { analytics } = useFirebase()

  const [periodo, setPeriodo] = useState<DateRange>({
    startDate: null,
    endDate: null,
  })
  const [loading, setLoading] = useState(false)
  const [gerarRelatorioRnds] = useGerarRelatorioRndsCsvMutation()

  const onSubmit = (values: RndsRelatorioImpressaoModel) => {
    if (!loading) {
      setLoading(true)
      gerarRelatorioRnds({
        variables: {
          input: {
            statusEnvioRegistro: values.statusEnvioRegistro,
            startDate: values.periodo.startDate.getTime(),
            endDate: values.periodo.endDate.getTime(),
            tipoRegistro: values.tipoRegistro,
          },
        },
      })
        .catch(noop)
        .finally(() => {
          setLoading(false)
          analytics.logEvent('gerar_relatorio_rnds')
        })
    }
  }

  const checkAllFilledFields = ({
    periodo,
    statusEnvioRegistro,
    tipoRegistro,
  }: RndsRelatorioImpressaoModel): boolean => {
    return periodo?.startDate && periodo?.endDate && statusEnvioRegistro && tipoRegistro.length > 0
  }

  const checkAllEmptyFields = ({
    periodo,
    statusEnvioRegistro,
    tipoRegistro,
  }: RndsRelatorioImpressaoModel): boolean => {
    return !periodo.startDate && !periodo.endDate && !statusEnvioRegistro && tipoRegistro.length === 0
  }

  const renderForm = (formProps: FormRenderProps<RndsRelatorioImpressaoModel>) => {
    const { handleSubmit, form, values } = formProps

    const onCancel = () => {
      form.reset()
      form.getRegisteredFields().forEach((item) => form.resetFieldState(item))
    }

    const cancelButtonDisabled = checkAllEmptyFields(values)
    const submitButtonDisabled = !checkAllFilledFields(values)

    return (
      <Fragment>
        <VFlow vSpacing={1} style={styles.relatoriosForm}>
          <HFlow>
            <Heading level={4}>Gerar relatórios de envio ou erros</Heading>
          </HFlow>

          <Grid gap={2}>
            <Cell size={4}>
              <RadioGroupField
                required
                label='Status do envio do registro'
                name={meta.statusEnvioRegistro.absolutePath()}
                options={opcoesStatusEnvioRegistro}
                hSpacing={1}
              />
            </Cell>
            <Cell size={4}>
              <DateRangeField
                required
                label='Período'
                name={meta.periodo.absolutePath()}
                value={periodo}
                maxDate={new Date()}
                onChange={(date: DateRange) => setPeriodo(date)}
              />
            </Cell>
          </Grid>
          <CheckboxGroupField
            required
            label='Tipo de registro'
            name={meta.tipoRegistro.absolutePath()}
            options={opcoesTipoRegistro}
            hSpacing={2}
          />
          <HFlow justifyContent='flex-end' style={styles.buttons}>
            <Tooltip
              text={cancelButtonDisabled ? 'Só é possível cancelar após preencher ao menos um campo' : 'Cancelar'}
            >
              <Button size='small' kind='normal' disabled={cancelButtonDisabled} onClick={onCancel}>
                Cancelar
              </Button>
            </Tooltip>
            <Tooltip
              text={
                submitButtonDisabled
                  ? 'Só é possível gerar um relatório após preencher todos os campos obrigatórios'
                  : 'Gerar relatório'
              }
            >
              <Button
                size='small'
                kind='primary'
                disabled={submitButtonDisabled}
                loading={loading}
                onClick={handleSubmit}
              >
                <Icon icon='fileWithItemsOutline' style={styles.buttonIcon} />
                Gerar relatório
              </Button>
            </Tooltip>
          </HFlow>
        </VFlow>
      </Fragment>
    )
  }

  return (
    <Form<RndsRelatorioImpressaoModel>
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      render={renderForm}
    />
  )
}

const createStyles = (theme: Theme) => ({
  relatoriosForm: css`
    position: relative;
    padding-top: 1rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -1rem;
      width: calc(100% + 2rem);
      height: 1px;
      background-color: ${theme.pallete.divider};
    }
  `,
  buttons: css`
    padding-top: 1rem;
  `,
  buttonIcon: css`
    margin-right: 0.5rem;
  `,
})
