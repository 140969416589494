/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Ellipsis } from 'components/Ellipsis'
import { ListaAtendimentoQuery } from 'graphql/types.generated'
import { formatNomeProfissional } from 'view/profissional/utils/utils-profissional'

type ProfissionalResponsavel = ListaAtendimentoQuery['atendimentos']['content'][0]['responsavel']['profissional']

interface ResponsavelAtendimentoTextProps {
  showParticipante: boolean
  profissionalResponsavel: ProfissionalResponsavel
  profissionalParticipanteAgendamento?: ProfissionalResponsavel
}

export function ResponsavelAtendimentoText(props: ResponsavelAtendimentoTextProps) {
  const { showParticipante, profissionalResponsavel, profissionalParticipanteAgendamento } = props

  return (
    <div css={styles.profissionalResponsavelContainer}>
      <Ellipsis
        css={[
          styles.nomeProfissional,
          css`
            font-weight: bold;
          `,
        ]}
      >
        {formatNomeProfissional(profissionalResponsavel)}
      </Ellipsis>
      {showParticipante && (
        <Ellipsis css={styles.nomeProfissional}>{`com ${formatNomeProfissional(
          profissionalParticipanteAgendamento
        )}`}</Ellipsis>
      )}
    </div>
  )
}

const styles = {
  profissionalResponsavelContainer: css`
    display: grid;
  `,
  nomeProfissional: css`
    line-height: 1rem;
  `,
}
