/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, isEmpty, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { usePecField } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { ButtonLink } from 'components/route'
import { Fragment, useMemo } from 'react'
import { MetaPath } from 'util/metaPath'

import { MChatResultadoView } from './mchat-resultado/MChatResultadoView'
import { MChatPendingIcon } from './MChatPendingIcon'
import { MChatRFModel, MChatRiscoEnum } from './model-mchat'

type MChatSection = 'mchat-r' | 'consulta-seguimento'

interface MChatFieldItemProps {
  name: MetaPath<MChatRFModel>
  section: MChatSection
  title: string
  description: string
  buttonLinkTo: string
  onDelete: () => void
}

export function MChatFieldItem(props: MChatFieldItemProps) {
  const { name, section, title, description, buttonLinkTo, onDelete } = props

  const theme = useTheme()
  const {
    input: { value },
  } = usePecField({ name, subscription: { value: true } })

  const currentSectionValue = section === 'mchat-r' ? value.mchatr : value.consultaSeguimento
  const hasMChatRPreenchido = !isEmpty(value.mchatr)
  const hasConsultaSeguimentoPreenchido = !isEmpty(value.consultaSeguimento)
  const hasDadosPreenchidos =
    (section === 'mchat-r' && hasMChatRPreenchido) ||
    (section === 'consulta-seguimento' && hasConsultaSeguimentoPreenchido)
  const isRiscoMedio = value?.mchatr?.risco === MChatRiscoEnum.MEDIO
  const isConsultaSeguimentoAtiva = hasMChatRPreenchido && isRiscoMedio

  const classes = useMemo(() => createStyles(theme, section, isConsultaSeguimentoAtiva), [
    theme,
    section,
    isConsultaSeguimentoAtiva,
  ])

  const handleDelete = () => mChatConfirmDeleteDialog(onDelete)

  return (
    <HFlow style={classes.section} justifyContent='flex-start' hSpacing={0.625}>
      <div css={classes.iconContainer}>
        {!hasMChatRPreenchido && !hasConsultaSeguimentoPreenchido && <MChatPendingIcon />}
        {hasMChatRPreenchido && section === 'mchat-r' && (
          <Fragment>
            <Icon icon='checkCircleFilled' fill='primary' size={1.5} />
            {isRiscoMedio && section === 'mchat-r' && <span css={classes.connector} />}
          </Fragment>
        )}
        {hasMChatRPreenchido &&
          section === 'consulta-seguimento' &&
          (isRiscoMedio ? <Icon icon='exclamationTriangleFilled' fill='alert' /> : <MChatPendingIcon />)}
      </div>

      <VFlow vSpacing={hasDadosPreenchidos ? 0 : 0.5}>
        <HFlow justifyContent='space-between'>
          <HFlow alignItems='center' hSpacing={1}>
            <Text fontWeight='bold' fontSize={1} style={classes.textColor}>
              {title}
            </Text>
            {!hasDadosPreenchidos && (
              <ButtonLink
                kind={section === 'mchat-r' || isConsultaSeguimentoAtiva ? 'primary' : 'normal'}
                size='small'
                to={buttonLinkTo}
              >
                Avaliar
              </ButtonLink>
            )}
          </HFlow>
          {hasDadosPreenchidos && (
            <HFlow hSpacing={0.5}>
              <Tooltip text='Imprimir'>
                <Button type='button' kind='normal' skin='ghost' size='small' disabled>
                  <Icon icon='printerOutline' />
                </Button>
              </Tooltip>
              <Tooltip text='Editar'>
                <Button type='button' kind='normal' skin='ghost' size='small' disabled>
                  <Icon icon='penOutline' />
                </Button>
              </Tooltip>
              <Tooltip text='Excluir'>
                <Button type='button' kind='normal' skin='ghost' size='small' onClick={handleDelete}>
                  <Icon icon='trashOutline' />
                </Button>
              </Tooltip>
            </HFlow>
          )}
        </HFlow>
        {!hasDadosPreenchidos ? (
          <Text style={classes.textColor}>{description}</Text>
        ) : (
          <MChatResultadoView values={currentSectionValue} />
        )}
      </VFlow>
    </HFlow>
  )
}

const mChatConfirmDeleteDialog = (handleConfirm: () => void) => {
  confirm({
    confirmLabel: 'Excluir',
    cancelLabel: 'Cancelar',
    type: 'danger',
    size: 'auto',
    onConfirm: handleConfirm,
    render: () => (
      <HFlow>
        <Icon icon='exclamationTriangleOutline' fill='danger' size={3} />
        <VFlow vSpacing={0.125}>
          <Heading level={1}>Deseja excluir a avaliação?</Heading>
          <Text>As informações registradas e o resultado do M-CHAT-R™ serão perdidos.</Text>
        </VFlow>
      </HFlow>
    ),
  })()
}

const createStyles = (theme: Theme, section: MChatSection, isConsultaSeguimentoAtiva: boolean) => ({
  section: css`
    padding: 1rem;
    background-color: ${section === 'mchat-r' || isConsultaSeguimentoAtiva
      ? theme.pallete.gray.c100
      : theme.pallete.gray.c90};
    border-top: ${section === 'mchat-r' || isConsultaSeguimentoAtiva ? 'none' : `1px solid ${theme.pallete.gray.c80}`};
    grid-auto-columns: 1.5rem 1fr;
    position: relative;
  `,
  textColor: css`
    color: ${section === 'mchat-r' || isConsultaSeguimentoAtiva ? theme.pallete.gray.c10 : theme.pallete.gray.c40};
  `,
  connector: css`
    z-index: 1;
    height: calc(100% - 24px);
    position: absolute;
    top: 46px;
    left: 27px;
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: ${theme.pallete.gray.c80};
  `,
  iconContainer: css`
    margin-top: 0.375rem;
  `,
})
