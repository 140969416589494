/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, TableFooter, Text, Tooltip, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { AccordionControls } from 'components/accordion/useAccordionControl'
import { DateTime } from 'components/date'
import { usePagination } from 'components/table/usePagination'
import theme from 'config/theme'
import { useCuidadosCompartilhadoPlanoQuery } from 'graphql/hooks.generated'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import { formatNomeProfissional } from 'view/profissional/utils/utils-profissional'

import {
  cuidadoCompartilhadoClassificacaoPrioridadeRecord,
  CuidadoCompartilhadoPlanoModel,
  CuidadoCompartilhadoPlanoTableItemModel,
} from '../model-cuidadocompartilhado'
import { CuidadoCompartilhadoTableActions } from './CuidadoCompartilhadoTableActions'
import { CuidadoCompartilhadoTablePanel } from './CuidadoCompartilhadoTablePanel'
import { joinCuidadosCompartilhado } from './util-cuidadoCompartilhadoTable'

interface CuidadoCompartilhadoTableProps extends AccordionControls {
  prontuarioId: ID
  cuidadosAtendimentoAtual?: CuidadoCompartilhadoPlanoModel[]
  lotacaoAtual?: CuidadoCompartilhadoPlanoTableItemModel['lotacaoSolicitante']
  dataInicioAtendimento?: Instant
  viaFolhaRosto?: boolean
  onEdit?: (id: ID) => void
  onDelete?: (id: ID) => void
}

export const CuidadoCompartilhadoTable = (props: CuidadoCompartilhadoTableProps) => {
  const {
    prontuarioId,
    cuidadosAtendimentoAtual,
    lotacaoAtual,
    dataInicioAtendimento,
    viaFolhaRosto = false,
    onEdit,
    onDelete,
    resetExpandedItems,
    ...accordionProps
  } = props

  const {
    data: { cuidadosCompartilhado },
    loading,
  } = useCuidadosCompartilhadoPlanoQuery({
    variables: {
      input: {
        prontuarioId,
        executadosPelaMinhaEquipe: false,
        solicitadosPelaMinhaEquipe: false,
        somenteSemExecutante: false,
        somenteUnidadeExecutante: false,
        somenteUnidadeAcesso: false,
        somenteUnidadesBasicas: false,
      },
    },
  })

  const joinedItems = joinCuidadosCompartilhado(
    cuidadosAtendimentoAtual,
    cuidadosCompartilhado?.content,
    lotacaoAtual,
    dataInicioAtendimento
  )

  const { paginatedItems, tableProps } = usePagination<CuidadoCompartilhadoPlanoTableItemModel>({
    items: joinedItems,
    onChange: resetExpandedItems,
  })

  return (
    <VFlow vSpacing={0}>
      <AccordionDataTable
        {...accordionProps}
        rows={paginatedItems}
        columns={[
          {
            name: 'data',
            header: 'Data',
            render: renderData,
            size: 2,
          },
          {
            name: 'prioridade',
            header: 'Prioridade',
            render: renderPrioridade,
            size: 2,
          },
          {
            name: 'executante',
            header: 'Compartilhado para',
            render: renderLotacaoExecutante,
            size: 7,
          },
          {
            name: 'actions',
            size: 1,
            style: styles.actionsColumn,
            render: (row) => (
              <CuidadoCompartilhadoTableActions
                row={row}
                viaFolhaRosto={viaFolhaRosto}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ),
          },
        ]}
        components={{ AccordionPanel: CuidadoCompartilhadoTablePanel }}
        loading={loading}
      />
      <TableFooter {...tableProps} style={styles.tableFooter} />
    </VFlow>
  )
}

const renderData = (row: CuidadoCompartilhadoPlanoTableItemModel) => (
  <HFlow hSpacing={0.5}>
    <DateTime format='DD/MM/YYYY' value={row.dataInicioAtendimento} />

    {row.isRegistradoAgora && (
      <Tooltip text='Registrado agora'>
        <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
      </Tooltip>
    )}
  </HFlow>
)

const renderLotacaoExecutante = (row: CuidadoCompartilhadoPlanoTableItemModel) => (
  <Text>
    {row.lotacaoExecutanteAtual
      ? `${formatNomeProfissional(row.lotacaoExecutanteAtual.profissional)} | ${row.cboExecutanteAtual.nome}`
      : `${row.cboExecutanteAtual.nome}`}
  </Text>
)

const renderPrioridade = (row: CuidadoCompartilhadoPlanoTableItemModel) => {
  const { label, mainColor } = cuidadoCompartilhadoClassificacaoPrioridadeRecord[row.prioridadeAtual]

  return <StatusSquare color={mainColor} description={label} />
}

const styles = {
  actionsColumn: css`
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: flex-end;
  `,
  tableFooter: css`
    border-top-width: 0;
  `,
}
