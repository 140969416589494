import { Button, HFlow, Icon, TableFooter, Text, Tooltip, useTheme } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { useAlert } from 'components/alert'
import { confirm } from 'components/modals/confirm'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { CategoriaArquivoAtendProfEnum, Cbo } from 'graphql/types.generated'
import { useFilter } from 'hooks/filter/useFilter'
import { noop } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { emptyArray } from 'util/array'
import { formatDate } from 'util/date/formatDate'
import { ProfissionalAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import {
  AnexoArquivoFilterModel,
  AnexoArquivoFormModel,
  AnexoArquivoRowModel,
  categoriaAnexoLabelRecord,
} from '../../model-anexoArquivos'
import { mergeSortAnexoArquivos } from '../../utils/mergeSortAnexoArquivos'
import { SortAnexoArquivosField } from '../../utils/sortAnexoArquivos'
import { visualizarArquivoAnexado } from '../../visualizarArquivoAnexado'
import { AnexoArquivosAccordionPanel } from './AnexoArquivosAccordionPanel'
import { AnexoArquivosTableHeader } from './AnexoArquivosTableHeader'
import { AnexoArquivosTableRow } from './AnexoArquivosTableRow'

interface AnexoArquivosTableProps {
  atendimentoProfissionalId: ID
  profissional: ProfissionalAtendimento
  dataAtendimento: Instant
  lotacaoId: ID
  cbo: Cbo
  arquivosAnexados?: AnexoArquivoFormModel[]
  arquivosAnexadosDatabase?: AnexoArquivoRowModel[]
  anexoArquivosLoading: boolean
  onRemoveRow?(index: number): void
  onUpdateRow?(index: number, value: AnexoArquivoFormModel): void
}

const handleFilterByCategoria = (categoria: CategoriaArquivoAtendProfEnum) => (anexoArquivo: AnexoArquivoRowModel) =>
  categoria ? anexoArquivo.categoria === categoria : true

const handleFilterBySomenteMeus = (shouldFilter: boolean, profissionalId: ID) => (
  anexoArquivo: AnexoArquivoRowModel
) => {
  return shouldFilter ? anexoArquivo.atendimentoProfissional.lotacao.profissional.id === profissionalId : true
}

export function AnexoArquivosTable(props: AnexoArquivosTableProps) {
  const {
    atendimentoProfissionalId,
    dataAtendimento,
    lotacaoId,
    profissional,
    cbo,
    arquivosAnexados = emptyArray,
    arquivosAnexadosDatabase = emptyArray,
    anexoArquivosLoading,
    onRemoveRow = noop,
    onUpdateRow = noop,
  } = props

  const { ...accordionProps } = useAccordionControl({})

  const [sort, setSort] = useState<SortAnexoArquivosField[]>(['-data'])
  const [editingRowId, setEditingRowId] = useState<ID | null>(null)

  const isEditing = useCallback((cacheId: ID): boolean => cacheId !== undefined && cacheId === editingRowId, [
    editingRowId,
  ])

  const arquivosAnexadosMerged = useMemo(
    () =>
      mergeSortAnexoArquivos(
        arquivosAnexadosDatabase ?? emptyArray,
        arquivosAnexados ?? emptyArray,
        atendimentoProfissionalId,
        dataAtendimento,
        lotacaoId,
        profissional,
        cbo,
        sort
      ),
    [
      arquivosAnexadosDatabase,
      arquivosAnexados,
      sort,
      atendimentoProfissionalId,
      dataAtendimento,
      lotacaoId,
      profissional,
      cbo,
    ]
  )

  const [filter, setFilter] = useState<AnexoArquivoFilterModel>({ categoria: null, somenteMeus: false })

  const anexoArquivosFiltrados = useFilter<AnexoArquivoRowModel, AnexoArquivoFilterModel>({
    items: arquivosAnexadosMerged,
    filter: filter,
    filtersType: [],
    customFilters: [
      handleFilterByCategoria(filter.categoria),
      handleFilterBySomenteMeus(filter.somenteMeus, profissional.id),
    ],
  })

  const { paginatedItems, tableProps } = usePagination<AnexoArquivoRowModel>({
    items: anexoArquivosFiltrados,
  })

  const theme = useTheme()

  const alert = useAlert()

  const component = useMemo(
    () => ({
      AccordionPanel: AnexoArquivosAccordionPanel,
      Row: (props) => (
        <AnexoArquivosTableRow
          isEditing={isEditing(props.row.cacheId)}
          onUpdate={onUpdateRow}
          onCancel={() => setEditingRowId(null)}
          {...props}
        />
      ),
    }),
    [isEditing, onUpdateRow]
  )

  const handleVisualization = (e: Event, values: AnexoArquivoRowModel) => {
    e.stopPropagation()
    visualizarArquivoAnexado(values.arquivo.id)
  }

  const handleEdit = (e: Event, cacheId: ID) => {
    e.stopPropagation()
    setEditingRowId(cacheId)
  }

  const handleRemove = (e: Event, cacheId: ID) => {
    e.stopPropagation()
    confirm({
      title: 'Deseja excluir o arquivo anexado?',
      onConfirm: () => {
        onRemoveRow(cacheId)
        alert('success', 'Anexo de arquivo excluído com sucesso')
      },
      confirmLabel: 'Excluir',
    })()
  }

  return (
    <TableBox header={<AnexoArquivosTableHeader onFilterChange={setFilter} currentFilter={filter} />}>
      <AccordionDataTable<AnexoArquivoRowModel>
        {...accordionProps}
        loading={anexoArquivosLoading}
        rows={paginatedItems}
        columns={[
          {
            name: 'data',
            header: 'Data',
            render: (item: AnexoArquivoRowModel) => {
              const isItemFromDatabase = !!item.id

              return (
                <HFlow alignItems='center'>
                  <Text>{formatDate(item.atendimentoProfissional.iniciadoEm)}</Text>
                  {!isItemFromDatabase && (
                    <Tooltip text='Adicionado agora'>
                      <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
                    </Tooltip>
                  )}
                </HFlow>
              )
            },
            size: 2,
            sortable: true,
          },
          {
            name: 'categoria',
            header: 'Categoria do arquivo',
            render: (item: AnexoArquivoRowModel) => categoriaAnexoLabelRecord[item.categoria],
            size: 3,
            sortable: false,
          },
          {
            name: 'titulo',
            header: 'Título',
            render: (item: AnexoArquivoRowModel) => item.titulo,
            size: 5,
            sortable: false,
          },
          {
            name: 'buttons',
            header: '',
            render: (item: AnexoArquivoRowModel) => {
              const isItemFromDatabase = !!item.id

              return (
                <HFlow hSpacing={0.5} alignItems='center' justifyContent='flex-end'>
                  <HFlow hSpacing={0} alignItems='center' justifyContent='flex-end'>
                    <Tooltip text='Visualizar arquivo'>
                      <Button
                        size='small'
                        kind='normal'
                        skin='ghost'
                        onClick={(e) => {
                          handleVisualization(e, item)
                        }}
                      >
                        <Icon icon='fileVisualizationOutline' />
                      </Button>
                    </Tooltip>
                    {!isItemFromDatabase && (
                      <>
                        <Tooltip text='Editar'>
                          <Button
                            size='small'
                            kind='normal'
                            skin='ghost'
                            onClick={(e) => {
                              handleEdit(e, item.cacheId)
                            }}
                          >
                            <Icon icon='penOutline' />
                          </Button>
                        </Tooltip>
                        <Tooltip text='Excluir'>
                          <Button
                            size='small'
                            kind='normal'
                            skin='ghost'
                            onClick={(e) => {
                              handleRemove(e, item.cacheId)
                            }}
                          >
                            <Icon icon='trashOutline' />
                          </Button>
                        </Tooltip>
                      </>
                    )}
                  </HFlow>
                </HFlow>
              )
            },
            size: 2,
            sortable: false,
          },
        ]}
        components={component}
        sort={sort}
        onSortChange={(sort: string[]) => setSort(sort as SortAnexoArquivosField[])}
        disableRow={(row) => isEditing(row.cacheId)}
      />
      <TableFooter {...tableProps} />
    </TableBox>
  )
}
