/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { AccordionButton } from 'components/accordion/AccordionButton'
import { usePecField } from 'components/form'
import { noop } from 'lodash'
import { useMemo } from 'react'
import { Accordion, AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'
import { Route, useRouteMatch } from 'react-router'
import { MetaPath } from 'util/metaPath'

import { MChatFieldItem } from './MChatFieldItem'
import { MChatModal } from './MChatModal'
import { MCHAT_URL_MODAL, MChatFieldModel, MChatRFModel } from './model-mchat'

interface MChatFieldProps {
  name: MetaPath<MChatRFModel>
}

export function MChatField(props: MChatFieldProps) {
  const { name } = props

  const match = useRouteMatch()
  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme), [theme])

  const {
    input: { onChange },
  } = usePecField({ name: name.mchatr })

  const handleSave = (values: MChatFieldModel) => {
    onChange(values)
  }

  const handleDelete = () => {
    onChange(null)
  }

  return (
    <VFlow>
      <Accordion allowZeroExpanded preExpanded={['mchat']}>
        <AccordionItem uuid='mchat'>
          <AccordionButton style={classes.button} wrapperStyle={classes.wrapperButton}>
            <HFlow justifyContent='space-between'>
              <HFlow alignItems='center'>
                <Icon icon='angleDown' size={1.5} />
                <Text fontWeight='bold' fontSize={1}>
                  M-CHAT-R/F™ (Checklist Modificado para Autismo em Crianças Pequenas)
                </Text>
              </HFlow>
            </HFlow>
          </AccordionButton>
          <AccordionItemPanel>
            <VFlow style={classes.panel} vSpacing={0}>
              <MChatFieldItem
                name={name}
                section='mchat-r'
                title='M-CHAT-R&#8482;'
                description='Aplicação a partir dos 16 meses. Leva X minutos.'
                buttonLinkTo={`${match.url}${MCHAT_URL_MODAL}`}
                onDelete={handleDelete}
              />
              <MChatFieldItem
                name={name}
                section='consulta-seguimento'
                title='Consulta de seguimento'
                description='A consulta de seguimento é recomendada para resultados dentro do Risco Médio no M-CHAT-R.'
                buttonLinkTo={null}
                onDelete={noop}
              />
            </VFlow>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>

      <Route path={`${match.url}${MCHAT_URL_MODAL}`}>
        <MChatModal name={name.mchatr} onSave={handleSave} onDelete={handleDelete} />
      </Route>
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  button: css`
    padding: 1rem 1rem;
    border: 1px solid ${theme.pallete.gray.c60};
    background-color: ${theme.pallete.gray.c90};
    margin: 0;
    position: relative;
  `,
  wrapperButton: css`
    padding-bottom: 0;
  `,
  panel: css`
    border: 1px solid ${theme.pallete.gray.c60};
    border-top: none;
  `,
})
