import useSession from 'components/auth/useSession'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { CategoriaArquivoAtendProfEnum } from 'graphql/types.generated'
import React from 'react'
import { NIVEL_SUPERIOR } from 'types/NivelCbo'
import { emptyArray } from 'util/array'

import { categoriaAnexoLabelRecord, CategoriaArquivoSelectModel } from '../model-anexoArquivos'

const categoriasExames = [
  CategoriaArquivoAtendProfEnum.EXAME_DE_IMAGEM,
  CategoriaArquivoAtendProfEnum.EXAME_DE_IMAGEM_ODONTOLOGICO,
]

interface CategoriaArquivoSelectFieldProps
  extends Omit<SelectFieldProps<CategoriaArquivoSelectModel>, 'items' | 'itemToString'> {}

export function CategoriaArquivoSelectField(props: CategoriaArquivoSelectFieldProps) {
  const { checkAuthorization } = useSession()

  const isNivelSuperior = checkAuthorization(NIVEL_SUPERIOR)
  const valuesToOmit = isNivelSuperior ? emptyArray : categoriasExames

  return (
    <SelectField<CategoriaArquivoSelectModel>
      itemToString={itemToString}
      items={items.filter((item) => !valuesToOmit.includes(item))}
      {...props}
    />
  )
}

const items = Object.values(CategoriaArquivoAtendProfEnum)
const itemToString = (item: CategoriaArquivoSelectModel) => item && categoriaAnexoLabelRecord[item]
