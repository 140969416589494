import useSession from 'components/auth/useSession'
import { StatusAtendimento, TipoAtendimentoProfissional } from 'graphql/types.generated'
import { AlertasSdcProvider } from 'hooks/sdc/useAlertasSdc'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { useRetificacaoStorage } from 'view/retificacao-atendimento/useJustificativaRetificacaoStorage'

import { urlFromRegistroTardio } from '../../registro-tardio/RegistroTardioRootView'
import { AtendimentoIndividualView } from '../atendimento-individual/AtendimentoIndividualView'
import { isResponsavelAtendimentoProfissional } from '../util'
import { AtendimentoModel } from './model-atendimento'

interface AtendimentoBaseViewProps {
  atendimento: AtendimentoModel
  isRetificacao?: boolean
}

export function AtendimentoBaseView(props: AtendimentoBaseViewProps) {
  const { atendimento, isRetificacao = false } = props
  const atendimentoProfissional = atendimento?.atendimentoProfissional
  const hasAtendimentoProfissional = !!atendimentoProfissional
  const atendimentoId = atendimento.id

  const { data: sessao } = useSession()
  const { url } = useRouteMatch()
  const { getRetificacaoStorageValue } = useRetificacaoStorage(atendimentoId)

  const fromRegistroTardio = urlFromRegistroTardio(url)
  const moduloInvalido =
    (fromRegistroTardio && !atendimento?.isRegistroTardio) || (!fromRegistroTardio && atendimento?.isRegistroTardio)

  const statusInvalido =
    (!isRetificacao && atendimento?.statusAtendimento === StatusAtendimento.ATENDIMENTO_REALIZADO) ||
    atendimento?.statusAtendimento === StatusAtendimento.NAO_AGUARDOU

  const tipoInvalido =
    atendimentoProfissional?.tipo === TipoAtendimentoProfissional.ESCUTA_INICIAL ||
    atendimentoProfissional?.tipo === TipoAtendimentoProfissional.VACINACAO

  const { justificativa: justificativaRetificacao } = getRetificacaoStorageValue()
  const semJustificativaRetificacao = isRetificacao && !justificativaRetificacao

  if (statusInvalido || tipoInvalido || !hasAtendimentoProfissional || moduloInvalido || semJustificativaRetificacao) {
    return <Redirect to='/404' />
  } else if (!isResponsavelAtendimentoProfissional(sessao.acesso, atendimentoProfissional)) {
    return <Redirect to='/403' />
  } else {
    return (
      <AlertasSdcProvider cacheKey={`ALERTA_SDC|${atendimentoId}|${atendimentoProfissional?.lotacao.id}`}>
        <AtendimentoIndividualView
          atendimentoProfissionalId={atendimentoProfissional?.id}
          isRetificacao={isRetificacao}
        />
      </AlertasSdcProvider>
    )
  }
}
