import { AtendimentoModel, AtendimentoProfissionalModel } from './model-atendimento'

export const convertAtendimentoProfissional = (
  atendimentoProfissional: AtendimentoProfissionalModel
): AtendimentoModel => {
  if (!atendimentoProfissional) return null
  const { atendimento, ...rest } = atendimentoProfissional
  return {
    ...atendimento,
    atendimentoProfissional: rest,
  }
}
