import { AtendimentoIndividualRetificacaoViewQuery } from 'graphql/types.generated'
import { partition } from 'lodash'
import { convertMedicoesAnterioresQueryRetificacaoToForm } from 'view/atendimentos/detail/components/modals/medicoes/converter-medicoes'
import {
  convertFichasNotificacaoCasoSuspeitoQueryRetificacaoToForm,
  convertProcedimentosAdmistrativosQueryRetificacaoToForm,
} from 'view/atendimentos/detail/soap/finalizacao/converter-finalizacao'
import { convertObjetivoQueryRetificacaoToForm } from 'view/atendimentos/detail/soap/objetivo/converter-objetivo'
import { convertPlanoQueryRetificacaoToForm } from 'view/atendimentos/detail/soap/plano/converter-plano'
import { convertSubjetivoQueryRetificacaoToForm } from 'view/atendimentos/detail/soap/subjetivo/converter'

import { SoapState } from '../atendimentos/atendimento-individual/model'

export type AtendimentoIndividualRetificacaoModel = AtendimentoIndividualRetificacaoViewQuery['atendimentoIndividual']

export const initialValueRetificacao = (
  atendimentoIndividualRetificacao: AtendimentoIndividualRetificacaoModel,
  justificativaRetificacao: string
): SoapState => {
  const {
    evolucaoObjetivo,
    ivcf,
    marcadorConsumoAlimentar,
    medicoes,
    evolucaoPlano,
    evolucaoSubjetivo,
    orientacoes,
    procedimentosAtendimento,
    notificacoesCasoSuspeito,
    racionalidadeSaude,
  } = atendimentoIndividualRetificacao
  const [medicaoAtual, medicoesAnteriores] = partition(medicoes, (medicao) => !medicao.medicaoAnterior)

  return {
    openedAccordions: ['S', 'O', 'P', 'D'],
    //TODO (RNG): Implementar conversores do model para o form do SoapState (#22001)
    subjetivo: convertSubjetivoQueryRetificacaoToForm(evolucaoSubjetivo),
    medicoesAnteriores: convertMedicoesAnterioresQueryRetificacaoToForm(medicoesAnteriores),
    plano: convertPlanoQueryRetificacaoToForm(evolucaoPlano, orientacoes),
    objetivo: convertObjetivoQueryRetificacaoToForm(evolucaoObjetivo, marcadorConsumoAlimentar, medicaoAtual[0], ivcf),
    finalizacao: {
      procedimentosAdministrativos: convertProcedimentosAdmistrativosQueryRetificacaoToForm(procedimentosAtendimento),
      fichasNotificacaoCasoSuspeito: convertFichasNotificacaoCasoSuspeitoQueryRetificacaoToForm(
        notificacoesCasoSuspeito
      ),
      racionalidadeEmSaude: racionalidadeSaude,
    },
    justificativaRetificacao,
  }
}
