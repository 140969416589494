import { SimNaoEnum } from 'components/form'

import { MChatAplicacaoModel, MChatFieldModel, MChatPerguntaEnum, MChatRiscoEnum } from './model-mchat'

const PERGUNTAS_RISCO_SIM = [MChatPerguntaEnum.PERGUNTA_2, MChatPerguntaEnum.PERGUNTA_5, MChatPerguntaEnum.PERGUNTA_12]

const PERGUNTAS_RISCO_NAO = Object.values(MChatPerguntaEnum).filter(
  (pergunta) => !PERGUNTAS_RISCO_SIM.includes(pergunta)
)

export function calcularResultadoMChat(aplicacao: MChatAplicacaoModel): MChatFieldModel {
  let risco: MChatRiscoEnum = null
  let resultado: number = 0

  Object.entries(aplicacao).forEach(([pergunta, resposta]) => {
    if (PERGUNTAS_RISCO_NAO.includes(pergunta as MChatPerguntaEnum) && resposta === SimNaoEnum.NAO) {
      resultado += 1
    } else if (PERGUNTAS_RISCO_SIM.includes(pergunta as MChatPerguntaEnum) && resposta === SimNaoEnum.SIM) {
      resultado += 1
    }
  })

  if (resultado >= 8) {
    risco = MChatRiscoEnum.ELEVADO
  } else if (resultado >= 3) {
    risco = MChatRiscoEnum.MEDIO
  } else {
    risco = MChatRiscoEnum.BAIXO
  }

  return {
    aplicacao,
    resultado,
    risco,
  }
}
