import { VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import {
  useCuidadoCompartilhadoEvolucoesQuery,
  useCuidadoCompartilhadoFinalizacoesQuery,
} from 'graphql/hooks.generated'
import React from 'react'

import { CuidadoCompartilhadoEvolucaoItem } from './CuidadoCompartilhadoEvolucaoItem'
import { CuidadoCompartilhadoFinalizacaoItem } from './CuidadoCompartilhadoFinalizacaoItem'
import { joinCuidadoCompartilhadoEvolucaoFinalizacao } from './util-cuidadocompartilhadoevolucoes'

interface CuidadoCompartilhadoEvolucoesProps {
  cuidadoCompartilhadoId: ID
}

export const CuidadoCompartilhadoEvolucoes = (props: CuidadoCompartilhadoEvolucoesProps) => {
  const { cuidadoCompartilhadoId } = props

  const { CUIDADO_COMPARTILHADO_CIDADAO_OBITO_ENABLED } = useFlags()

  const { data: evolucoesData } = useCuidadoCompartilhadoEvolucoesQuery({
    variables: {
      input: {
        cuidadoCompartilhadoId,
      },
    },
  })

  const { data: finalizacoesData } = useCuidadoCompartilhadoFinalizacoesQuery({
    variables: {
      input: {
        cuidadoCompartilhadoId,
      },
    },
    skip: !CUIDADO_COMPARTILHADO_CIDADAO_OBITO_ENABLED,
  })

  const evolucoes = evolucoesData?.cuidadoCompartilhadoEvolucoes?.content ?? []
  const finalizacoes = finalizacoesData?.cuidadoCompartilhadoFinalizacoes?.content ?? []

  const allItems = joinCuidadoCompartilhadoEvolucaoFinalizacao(evolucoes, finalizacoes)

  const lastLineNumber = allItems.length - 1

  return (
    <VFlow vSpacing={0}>
      {allItems.map((item, index) => {
        if (item.type === 'evolucao') {
          return (
            <CuidadoCompartilhadoEvolucaoItem
              evolucao={item}
              key={item.id}
              isFirstEvolucao={index === 0}
              isLastEvolucao={index === lastLineNumber}
            />
          )
        } else if (item.type === 'finalizacao') {
          return (
            <CuidadoCompartilhadoFinalizacaoItem
              finalizacao={item}
              key={item.id}
              isFirstFinalizacao={index === 0}
              isLastFinalizacao={index === lastLineNumber}
            />
          )
        } else {
          return null
        }
      })}
    </VFlow>
  )
}
