import {
  GarantiaAcessoFilter,
  GarantiaAcessoItemCreateInput,
  GarantiaAcessoItemUpdateInput,
  UnidadesSaudeByGarantiaAcessoCidadaoInput,
} from 'graphql/types.generated'

import {
  GarantiaAcessoEdicaoModel,
  GarantiaAcessoFilterModel,
  GarantiaAcessoFormModel,
  GarantiaAcessoOrigemRegistroEnum,
} from './model-garantiaAcesso'

export const convertGarantiaAcessoFormModelToInput = (
  model: GarantiaAcessoFormModel
): GarantiaAcessoItemCreateInput => ({
  cidadaoId: model.cidadao.id,
  equipeId: model.equipe?.id,
  tipoAtendimento: model.tipoAtendimento?.value,
  tipoServico: model.tipoServico?.id,
  motivo: model.motivo,
})

export const convertGarantiaAcessoFilterModelToInput = (filter: GarantiaAcessoFilterModel): GarantiaAcessoFilter => ({
  pageParams: filter.pageParams,
  nomeCpfCns: filter.nomeCpfCns,
  lotacoesIds: filter.lotacoes.map((item) => item.id),
  equipes: filter.equipes.map((item) => item.id),
  tiposAtendimento: filter.tiposAtendimento.map((item) => item.value),
  tiposServicoIds: filter.tiposServico.map((item) => item.id),
  isCuidadoCompartilhado: filter.origem.includes(GarantiaAcessoOrigemRegistroEnum.CUIDADO_COMPARTILHADO),
  isGarantiaAcesso: filter.origem.includes(GarantiaAcessoOrigemRegistroEnum.GARANTIA_ACESSO),
  ordenacao: filter.ordenacao,
  periodo: filter.periodo,
})

export const convertFormModelToUbsByGarantiaAcessoCidadaoInput = (
  values: GarantiaAcessoFormModel
): UnidadesSaudeByGarantiaAcessoCidadaoInput => ({
  cidadaoId: values.cidadao.id,
  tipoAtendimento: values.tipoAtendimento?.value,
  tipoServico: values.tipoServico?.id,
})

export const convertGarantiaAcessoEdicaoModelToInput = (
  values: GarantiaAcessoEdicaoModel
): GarantiaAcessoItemUpdateInput => ({
  garantiaAcessoId: values.garantiaAcessoId,
  equipeId: values.equipe?.id,
  tipoAtendimento: values.tipoAtendimento?.value,
  tipoServico: values.tipoServico?.id,
  motivo: values.motivo,
})

export const convertEdicaoModelToUbsByGarantiaAcessoCidadaoInput = (
  values: GarantiaAcessoEdicaoModel,
  cidadaoId: ID
): UnidadesSaudeByGarantiaAcessoCidadaoInput => ({
  cidadaoId: cidadaoId,
  tipoAtendimento: values.tipoAtendimento?.value,
  tipoServico: values.tipoServico?.id,
})
