import { TickProps } from 'bold-ui/lib/components/Chart/Tick'
import moment, { Moment } from 'moment'
import React from 'react'

export function TickSemanaGestacional(props: TickProps & { dataInicioGestacao: Moment }) {
  const {
    payload: { value: dataMedicao },
    dataInicioGestacao,
    ...rest
  } = props

  const precision = 'weeks'
  const tempoGestacao = moment.duration(moment(dataMedicao).diff(dataInicioGestacao, precision), precision)
  return (
    <>
      <text dy={15} {...rest}>
        {Math.round(tempoGestacao.asWeeks())}
      </text>
    </>
  )
}
