import useSession from 'components/auth/useSession'
import { useLocalStorage } from 'hooks/useStorage'
import { useCallback } from 'react'

export const KEY_RETIFICACAO_STORAGE = 'retificacao-atendimento'

interface RetificacaoStorageModel {
  justificativa: string
  callbackUrl?: string
  justificativaVerProntuarioId?: ID
}

interface UseRetificacaoStorageResult {
  getRetificacaoStorageValue(): RetificacaoStorageModel
  setRetificacaoStorageValue(value: RetificacaoStorageModel): void
  deleteStorageRetificacao(): void
}

export function useRetificacaoStorage(atendimentoId: string): UseRetificacaoStorageResult {
  const {
    data: { acesso },
  } = useSession()

  const [getRetificacaoStorageValueInternal, setRetificacaoStorageValue, deleteStorageRetificacao] = useLocalStorage<
    RetificacaoStorageModel
  >(`${acesso.id}/${atendimentoId}/${KEY_RETIFICACAO_STORAGE}`)

  const getRetificacaoStorageValue = useCallback(
    () => getRetificacaoStorageValueInternal() ?? ({} as RetificacaoStorageModel),

    [getRetificacaoStorageValueInternal]
  )

  return { getRetificacaoStorageValue, setRetificacaoStorageValue, deleteStorageRetificacao }
}
