import { HFlow, Text, VFlow } from 'bold-ui'
import { SelectFieldWithAddButton } from 'components/form/final-form/SelectField/SelectFieldWithAddButton'
import { Cpf } from 'components/label'
import { ProfissionalSelectDocument } from 'graphql/hooks.generated'
import { ProfissionalSelectQuery, ProfissionalSelectQueryVariables } from 'graphql/types.generated'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { formatNomeProfissional } from 'view/profissional/utils/utils-profissional'

import { AsyncSelectFieldProps, DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type ProfissionalSelectModel = ProfissionalSelectQuery['profissionais']['content'][0]

export interface ProfissionalSelectFieldProps extends AsyncSelectFieldProps<ProfissionalSelectModel> {
  mostrarCns?: boolean
  mostrarSemLotacaoAtiva?: boolean
}

const itemToString = (item: ProfissionalSelectModel) => item && item.nome

export function ProfissionalSelectField(props: ProfissionalSelectFieldProps) {
  const { mostrarCns, mostrarSemLotacaoAtiva } = props

  const { url } = useRouteMatch()
  const history = useHistory()

  const { selectProps } = useAsyncQuerySelect<
    ProfissionalSelectModel,
    ProfissionalSelectQuery,
    ProfissionalSelectQueryVariables
  >({
    query: ProfissionalSelectDocument,
    extractItems: (data: ProfissionalSelectQuery) => data?.profissionais?.content,
    variables: (inputString) => ({
      input: {
        query: inputString,
        mostrarSemLotacaoAtiva: mostrarSemLotacaoAtiva,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
      },
    }),
  })

  const onClickCadastrarButton = () => {
    return history.push(`/profissional/create?callbackLink=${url}`)
  }

  const renderItem = (item: ProfissionalSelectModel) => (
    <VFlow vSpacing={0}>
      <Text component='p' fontWeight='bold'>
        {formatNomeProfissional(item)}
      </Text>
      <HFlow hSpacing={0.25}>
        <Text fontWeight='bold'>CPF</Text>
        <Cpf value={item.cpf} />
      </HFlow>
      {mostrarCns && (
        <HFlow hSpacing={0.25}>
          <Text fontWeight='bold'>CNS</Text>
          <Text>{item.cns}</Text>
        </HFlow>
      )}
    </VFlow>
  )

  return (
    <SelectFieldWithAddButton<ProfissionalSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      menuMinWidth={496}
      onClickAddButton={onClickCadastrarButton}
      addText='Cadastre um profissional se não o encontrar na busca.'
      addButtonText='Cadastrar profissional'
      showingAppend={false}
      {...selectProps}
      {...props}
    />
  )
}
