/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Theme, useTheme } from 'bold-ui'
import { Color } from 'csstype'
import { FunctionComponent } from 'react'

interface CuidadoCompartilhadoItemWrapperProps {
  Icon: FunctionComponent
  backgroundColorWrapper: Color
  backgroundColorIcon: Color
  isFirstItem: boolean
  isLastItem: boolean
  children: React.ReactNode
}

export const CuidadoCompartilhadoItemWrapper = ({
  Icon,
  backgroundColorWrapper,
  backgroundColorIcon,
  isFirstItem,
  isLastItem,
  children,
}: CuidadoCompartilhadoItemWrapperProps) => {
  const theme = useTheme()
  const styles = createStyles(theme, isFirstItem, isLastItem, backgroundColorWrapper, backgroundColorIcon)

  return (
    <div css={styles.wrapper}>
      <HFlow hSpacing={0.5}>
        <div css={styles.lineColumn}>
          <div css={styles.topLine} />
          <div css={styles.iconWrapper}>
            <Icon />
          </div>
          <div css={styles.bottomLine} />
        </div>
        {children}
      </HFlow>
    </div>
  )
}

const createStyles = (
  theme: Theme,
  isFirstItem: boolean,
  isLastItem: boolean,
  backgroundColorWrapper: Color,
  backgroundColorIcon: Color
) => ({
  wrapper: css`
    padding: 0 1rem;
    background: ${backgroundColorWrapper};
  `,
  lineColumn: css`
    display: grid;
    flex-direction: column;
    justify-items: center;
    height: 100%;
    grid-template-rows: 0.5rem 1.5rem 1fr;
  `,
  iconWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background: ${backgroundColorIcon};
    border-radius: 50%;

    svg {
      width: 1rem;
      height: 1rem;
      * {
        fill: ${theme.pallete.surface.main};
      }
    }
  `,
  icon: css`
    fill: ${theme.pallete.surface.main};
  `,
  topLine: css`
    width: 1px;
    height: inherit;
    background-color: ${theme.pallete.gray.c80};
    opacity: ${isFirstItem ? '0' : '1'};
  `,
  bottomLine: css`
    width: 1px;
    height: inherit;
    background-color: ${theme.pallete.gray.c80};
    opacity: ${isLastItem ? '0' : '1'};
  `,
})
