import { VFlow } from 'bold-ui'
import { ErrorField } from 'components/form/final-form/ErrorField/ErrorField'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import {
  CuidadoCompartilhadoModel,
  CuidadoCompartilhadoVideochamadaState,
  DiscussaoCasoExecutanteFieldsModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { AgendarConsultaCuidadoCompartilhadoField } from '../components/discussao-caso-fields/AgendarConsultaCuidadoCompartilhadoField'
import { DevolutivaCuidadoCompartilhadoField } from '../components/discussao-caso-fields/DevolutivaCuidadoCompartilhadoField'
import { SugestaoAgendamentoGrupoCuidadoCompartilhadoField } from '../components/discussao-caso-fields/SugestaoAgendamentoGrupoCuidadoCompartilhadoField'
import { TrocaExecutanteCuidadoCompartilhadoField } from '../components/discussao-caso-fields/TrocaExecutanteCuidadoCompartilhadoField'

interface DiscussaoCasoExecutanteFieldsProps {
  path: MetaPath<DiscussaoCasoExecutanteFieldsModel>
  cuidadoCompartilhado: CuidadoCompartilhadoModel
  videochamadaUuidState: CuidadoCompartilhadoVideochamadaState
  isEspecializada: boolean
  isExecutanteFromEstabelecimentoEspecializado: boolean
}

export const DiscussaoCasoExecutanteFields = (props: DiscussaoCasoExecutanteFieldsProps) => {
  const {
    path,
    cuidadoCompartilhado,
    videochamadaUuidState,
    isEspecializada,
    isExecutanteFromEstabelecimentoEspecializado,
  } = props

  const { lotacaoSolicitante, lotacaoExecutanteAtual, unidadeSaudeExecutanteAtual, cidadao } = cuidadoCompartilhado
  const { CUIDADO_COMPARTILHADO_CIDADAO_OBITO_ENABLED } = useFlags()

  return (
    <VFlow vSpacing={0.5}>
      <DevolutivaCuidadoCompartilhadoField path={path} cidadaoFaleceu={cidadao.faleceu} />

      {(!CUIDADO_COMPARTILHADO_CIDADAO_OBITO_ENABLED || !cidadao.faleceu) && (
        <VFlow vSpacing={0.5}>
          <TrocaExecutanteCuidadoCompartilhadoField
            path={path}
            lotacaoSolicitante={lotacaoSolicitante}
            profissionalExecutanteId={lotacaoExecutanteAtual.profissional.id}
            equipeExecutanteId={lotacaoExecutanteAtual.equipe?.id}
            unidadeSaudeExecutanteId={unidadeSaudeExecutanteAtual.id}
            isEspecializada={isEspecializada}
          />

          {!isExecutanteFromEstabelecimentoEspecializado && (
            <AgendarConsultaCuidadoCompartilhadoField
              path={path}
              cuidadoCompartilhado={cuidadoCompartilhado}
              videochamadaUuidState={videochamadaUuidState}
            />
          )}

          <SugestaoAgendamentoGrupoCuidadoCompartilhadoField path={path} />
        </VFlow>
      )}

      <ErrorField name={path} ignoreObjectError />
    </VFlow>
  )
}
