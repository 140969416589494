/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { AgendamentosDiaQuery } from 'graphql/types.generated'
import { ReactComponent as AgendamentoCompartilhadoIcon } from 'images/agenda/calendar-add-filled.svg'
import { formatNomeProfissional } from 'view/profissional/utils/utils-profissional'

type ProfissionalAgendamentoDia = AgendamentosDiaQuery['agendados']['content'][number]['lotacaoAgendada']['profissional']

interface AgendamentosDiaLabelProps {
  prof: ProfissionalAgendamentoDia
  horarioInicial: string
  cbo: string
  profParticipante?: ProfissionalAgendamentoDia
  cboProfParticipante?: string
}

export const AgendamentosDiaLabel = (props: AgendamentosDiaLabelProps) => {
  const { prof, horarioInicial, cbo, profParticipante, cboProfParticipante } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <VFlow vSpacing={0} style={styles.container}>
      <HFlow hSpacing={0} alignItems='center'>
        {profParticipante && <AgendamentoCompartilhadoIcon css={styles.iconeAgendCompartilhado} />}
        <Text fontWeight='bold'>
          <DateTime value={horarioInicial} format='HH:mm' />
          {` -  ${formatNomeProfissional(prof)}`}
        </Text>
      </HFlow>
      <Text>{cbo.titleCase()}</Text>

      {profParticipante && (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold' style={styles.nomeProfParticipante}>
            Com {formatNomeProfissional(profParticipante)}
          </Text>

          <Text>{cboProfParticipante.titleCase()}</Text>
        </VFlow>
      )}
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    padding-left: 0.5rem;
  `,
  iconeAgendCompartilhado: css`
    height: 1rem;
    width: 1rem;
    margin-right: 0.25rem;
    fill: ${theme.pallete.gray.c20};
  `,
  nomeProfParticipante: css`
    margin-top: 0.25rem;
  `,
})
