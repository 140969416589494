import { Cell, Grid, InfoLabel, Text } from 'bold-ui'
import React, { Fragment } from 'react'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import { cuidadoCompartilhadoClassificacaoPrioridadeRecord } from 'view/atendimentos/detail/soap/plano/cuidado-compartilhado/model-cuidadocompartilhado'
import { formatCiapCid } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import { HistoricoPlanoPecGuiaEncaminhamento } from './HistoricoPlanoPecGuiaEncaminhamentoList'

interface HistoricoPlanoPecGuiaEncaminhamentoPanelProps {
  guiaEncaminhamento: HistoricoPlanoPecGuiaEncaminhamento
}

export const HistoricoPlanoPecGuiaEncaminhamentoPanel = (props: HistoricoPlanoPecGuiaEncaminhamentoPanelProps) => {
  const { ciap, cid10, cbo, discussao, classificacaoPrioridade } = props.guiaEncaminhamento

  const {
    label: classificacaoRiscoLabel,
    mainColor: classificacaoRiscoColor,
  } = cuidadoCompartilhadoClassificacaoPrioridadeRecord[classificacaoPrioridade]

  return (
    <Fragment>
      <Grid gap={2}>
        <Cell size={4}>
          <InfoLabel title='CBO'>
            <Text>{cbo.nome.capitalize()}</Text>
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Problemas/condições'>
            <Text>{formatCiapCid(ciap, cid10)}</Text>
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Classificação de prioridade'>
            <StatusSquare description={classificacaoRiscoLabel} color={classificacaoRiscoColor} />
          </InfoLabel>
        </Cell>
      </Grid>
      <Grid gap={2}>
        <Cell size={12}>
          <InfoLabel title='Discussão'>
            <Text dangerouslySetInnerHTML={{ __html: discussao }} />
          </InfoLabel>
        </Cell>
      </Grid>
    </Fragment>
  )
}
