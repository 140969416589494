import { green, orange, red } from 'bold-ui/lib/styles/colors'
import { SimNaoEnum } from 'components/form'

export type MChatAplicacaoModel = Record<MChatPerguntaEnum, SimNaoEnum>

export const MCHAT_URL_MODAL = '/mchat/'

export const MCHAT_PERGUNTAS: Record<MChatPerguntaEnum, string> = {
  PERGUNTA_1:
    'Se você apontar para algum objeto no quarto, o seu filho olha para este objeto? (Por exemplo, se você apontar para um brinquedo ou animal, o seu filho olha para o brinquedo ou para o animal?)',
  PERGUNTA_2: 'Alguma vez você se perguntou se o seu filho pode ser surdo?',
  PERGUNTA_3:
    'O seu filho brinca de faz de contas? (Por exemplo, faz de conta que bebe em um copo vazio, faz de conta que fala ao telefone, faz de conta que dá comida a uma boneca ou a um bichinho de pelúcia?)',
  PERGUNTA_4: 'O seu filho gosta de subir nas coisas? (Por exemplo, móveis, brinquedos em parques ou escadas)',
  PERGUNTA_5:
    'O seu filho faz movimentos estranhos com os dedos perto dos olhos? (Por exemplo, mexe os dedos em frente aos olhos e fica olhando para os mesmos?)',
  PERGUNTA_6:
    'O seu filho aponta com o dedo para pedir algo ou para conseguir ajuda? (Por exemplo, aponta para um biscoito ou brinquedo fora do alcance dele?)',
  PERGUNTA_7:
    'O seu filho aponta com o dedo para mostrar algo interessante para você? (Por exemplo, aponta para um avião no céu ou um caminhão grande na rua)',
  PERGUNTA_8:
    'O seu filho se interessa por outras crianças? (Por exemplo, seu filho olha para outras crianças, sorri para elas ou se aproxima delas?)',
  PERGUNTA_9:
    'O seu filho traz coisas para mostrar para você ou as segura para que você as veja - não para conseguir ajuda, mas apenas para compartilhar? (Por exemplo, para mostrar uma flor, um bichinho de pelúcia ou um caminhão de brinquedo)',
  PERGUNTA_10:
    'O seu filho responde quando você o chama pelo nome? (Por exemplo, ele olha para você, fala ou emite algum som, ou para o que está fazendo quando você o chama pelo nome?)',
  PERGUNTA_11: 'Quando você sorri para o seu filho, ele sorri de volta para você?',
  PERGUNTA_12:
    'O seu filho fica muito incomodado com barulhos do dia a dia? (Por exemplo, seu filho grita ou chora ao ouvir barulhos como os de liquidificador ou de música alta?)',
  PERGUNTA_13: 'O seu filho anda?',
  PERGUNTA_14:
    'O seu filho olha nos seus olhos quando você está falando ou brincando com ele/ela, ou vestindo a roupa dele/dela?',
  PERGUNTA_15:
    'O seu filho tenta imitar o que você faz? (Por exemplo, quando você dá tchau, ou bate palmas, ou joga um beijo, ele repete o que você faz?)',
  PERGUNTA_16:
    'Quando você vira a cabeça para olhar para alguma coisa, o seu filho olha ao redor para ver o que você está olhando?',
  PERGUNTA_17:
    'O seu filho tenta fazer você olhar para ele/ela? (Por exemplo, o seu filho olha para você para ser elogiado/aplaudido, ou diz: “olha mãe!” ou “óh mãe!”)',
  PERGUNTA_18:
    'O seu filho compreende quando você pede para ele/ela fazer alguma coisa? (Por exemplo, se você não apontar, o seu filho entende quando você pede: “coloca o copo na mesa” ou “liga a televisão”)?',
  PERGUNTA_19:
    'Quando acontece algo novo, o seu filho olha para o seu rosto para ver como você se sente sobre o que aconteceu? (Por exemplo, se ele/ela ouve um barulho estranho ou vê algo engraçado, ou vê um brinquedo novo, será que ele/ela olharia para seu rosto?)',
  PERGUNTA_20: 'O seu filho gosta de atividades de movimento? (Por exemplo, ser balançado ou pular em seus joelhos).',
}

export enum MChatPerguntaEnum {
  PERGUNTA_1 = 'PERGUNTA_1',
  PERGUNTA_2 = 'PERGUNTA_2',
  PERGUNTA_3 = 'PERGUNTA_3',
  PERGUNTA_4 = 'PERGUNTA_4',
  PERGUNTA_5 = 'PERGUNTA_5',
  PERGUNTA_6 = 'PERGUNTA_6',
  PERGUNTA_7 = 'PERGUNTA_7',
  PERGUNTA_8 = 'PERGUNTA_8',
  PERGUNTA_9 = 'PERGUNTA_9',
  PERGUNTA_10 = 'PERGUNTA_10',
  PERGUNTA_11 = 'PERGUNTA_11',
  PERGUNTA_12 = 'PERGUNTA_12',
  PERGUNTA_13 = 'PERGUNTA_13',
  PERGUNTA_14 = 'PERGUNTA_14',
  PERGUNTA_15 = 'PERGUNTA_15',
  PERGUNTA_16 = 'PERGUNTA_16',
  PERGUNTA_17 = 'PERGUNTA_17',
  PERGUNTA_18 = 'PERGUNTA_18',
  PERGUNTA_19 = 'PERGUNTA_19',
  PERGUNTA_20 = 'PERGUNTA_20',
}

export enum MChatRiscoEnum {
  BAIXO = 'BAIXO',
  MEDIO = 'MEDIO',
  ELEVADO = 'ELEVADO',
}

export interface MChatConsultaSeguimentoModel {}

export interface MChatFieldModel {
  aplicacao: MChatAplicacaoModel
  resultado: number
  risco: MChatRiscoEnum
}

export interface MChatRFModel {
  mchatr: MChatFieldModel
  consultaSeguimento: MChatConsultaSeguimentoModel
}

interface MChatResultadoModel {
  textColor: string
  lineColor: string
  backgroundColor: string
  shortTitle: string
  encaminhamento: string
}

export const MCHAT_RESULTADOS: Record<MChatRiscoEnum, MChatResultadoModel> = {
  BAIXO: {
    textColor: green.c40,
    lineColor: green.c60,
    backgroundColor: green.c90,
    shortTitle: 'baixo',
    encaminhamento:
      '<strong>Se a criança tem menos de 24 meses, reavaliar após o segundo aniversário.</strong> Nenhuma outra avaliação será requerida a menos que a evolução clínica indique risco de TEA.',
  },
  MEDIO: {
    textColor: orange.c40,
    lineColor: orange.c60,
    backgroundColor: orange.c90,
    shortTitle: 'médio',
    encaminhamento:
      '<strong>Avaliar Consulta de seguimento para obter informações adicionais sobre as respostas de risco, evitando falso positivo.</strong>',
  },
  ELEVADO: {
    textColor: red.c40,
    lineColor: red.c60,
    backgroundColor: red.c90,
    shortTitle: 'elevado',
    encaminhamento:
      '<strong>A criança deve ser encaminhada imediatamente para avaliação diagnóstica e intervenção precoce,</strong> não é necessário fazer a consulta de seguimento.',
  },
}
