import { HFlow, Icon, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { lotacaoAgendamentoToString } from 'view/agenda/util-agenda'
import { formatNomeProfissional } from 'view/profissional/utils/utils-profissional'

import { AgendamentoCidadaoModel } from '../../model-cidadao'

interface AgendamentoCompartilhadoCidadaoProfissionaisAgendadosAgendadaProps
  extends Pick<AgendamentoCidadaoModel, 'agendamentoPrincipal' | 'lotacaoParticipante'> {
  nomeUnidadeSaude?: string
}

export function AgendamentoCompartilhadoCidadaoProfissionaisAgendados(
  props: AgendamentoCompartilhadoCidadaoProfissionaisAgendadosAgendadaProps
) {
  const {
    agendamentoPrincipal: { lotacaoAgendada },
    lotacaoParticipante,
    nomeUnidadeSaude,
  } = props

  return (
    <VFlow vSpacing={0.5}>
      <HFlow hSpacing={0.5}>
        <Icon icon='userFilled' size={1} fill='primary' />
        <VFlow vSpacing={0}>
          <HLabel title='Profissional anfitrião:'>
            {lotacaoAgendamentoToString({
              nomeProfissional: formatNomeProfissional(lotacaoAgendada.profissional),
              nomeCbo: lotacaoAgendada.cbo?.nome,
              ineEquipe: lotacaoAgendada.equipe?.ine,
              nomeEquipe: lotacaoAgendada.equipe?.nome,
            })}
          </HLabel>
          <HLabel title='Profissional convidado:'>
            {lotacaoAgendamentoToString({
              nomeProfissional: formatNomeProfissional(lotacaoParticipante?.profissional),
              nomeCbo: lotacaoParticipante.cbo?.nome,
              ineEquipe: lotacaoParticipante?.equipe?.ine,
              nomeEquipe: lotacaoParticipante?.equipe?.nome,
            })}
          </HLabel>
        </VFlow>
      </HFlow>
      {!!nomeUnidadeSaude && (
        <HFlow hSpacing={0.5}>
          <Icon icon='hospital' size={1} fill='primary' />
          <Text fontWeight='bold'> {nomeUnidadeSaude.titleCase()}</Text>
        </HFlow>
      )}
    </VFlow>
  )
}
