import {
  DataTableRenderProps,
  HFlow,
  Icon,
  Omit,
  PagedTable,
  PagedTableProps,
  Spinner,
  Table,
  TableFilledBody,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  useStyles,
  useTheme,
} from 'bold-ui'
import { DateTime } from 'components/date'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { ImportacoesCnesQuery, ImportacoesCnesQueryInput } from 'graphql/types.generated'
import React from 'react'
import { titleCase } from 'util/strConversor'
import { ImportacaoCnesTableFilter } from 'view/cnes/ImportacaoCnesTableFilter'
import { formatNomeProfissional } from 'view/profissional/utils/utils-profissional'

type ItemType = ImportacoesCnesQuery['importacoesCnes']['content'][0]
type ProcessoType = ImportacoesCnesQuery['importacoesCnes']['content'][0]['processo']

export interface ImportacaoCnesTableProps extends Omit<PagedTableProps<ItemType>, 'columns'> {
  wizardInstalacao?: boolean
  filter: ImportacoesCnesQueryInput
  onFilterChange(filter: ImportacoesCnesQueryInput): void
}

export function ImportacaoCnesTable(props: ImportacaoCnesTableProps) {
  const theme = useTheme()
  const { filter, onFilterChange, ...rest } = props

  const renderTable = (renderProps: DataTableRenderProps) => {
    const { getHeaderProps, columns, rows, loading } = renderProps
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader rowSpan={2} {...getHeaderProps(columns[0])}>
              Data
            </TableHeader>
            <TableHeader rowSpan={2} {...getHeaderProps(columns[1])}>
              Responsável
            </TableHeader>
            <TableHeader rowSpan={2} {...getHeaderProps(columns[2])}>
              Situação
            </TableHeader>
            <TableHeader
              colSpan={4}
              style={{
                textAlign: 'center',
                borderLeft: `1px solid ${theme.pallete.divider}`,
              }}
            >
              Novos/atualizados
            </TableHeader>
          </TableRow>
          <TableRow>
            <TableHeader {...getHeaderProps(columns[3])}>Equipes</TableHeader>
            <TableHeader {...getHeaderProps(columns[4])}>Profissionais</TableHeader>
            <TableHeader {...getHeaderProps(columns[5])}>Lotações</TableHeader>
            <TableHeader {...getHeaderProps(columns[6])}>{''}</TableHeader>
          </TableRow>
        </TableHead>
        <TableFilledBody columns={columns} rows={rows} loading={loading} />
      </Table>
    )
  }

  const renderData = (row: ItemType) => <DateTime value={row.dataImportacao} format='DD/MM/YYYY' />

  const renderResponsavel = (row: ItemType) => <Text>{titleCase(formatNomeProfissional(row.profissional))}</Text>

  const renderSituacao = (row: ItemType) => <SituacaoProcesso processo={row.processo} />

  const renderEquipes = (row: ItemType) => (
    <Text>
      {row.equipesNovas}/{row.equipesAtualizadas}
    </Text>
  )

  const renderProfissionais = (row: ItemType) => (
    <Text>
      {row.profissionaisNovos}/{row.profissionaisAtualizados}
    </Text>
  )

  const renderLotacoes = (row: ItemType) => (
    <Text>
      {row.lotacoesNovas}/{row.lotacoesAtualizadas}
    </Text>
  )

  const renderButtons = (row: ItemType) => (
    <>
      {!props.wizardInstalacao &&
        row.processo &&
        (row.processo.status === 'CONCLUIDO' || row.processo.status === 'ERRO') && (
          <Tooltip text='Visualizar'>
            <ButtonLink size='small' skin='ghost' to={`/importarCnes/${row.id}`}>
              <Icon icon='zoomOutline' />
            </ButtonLink>
          </Tooltip>
        )}
    </>
  )

  return (
    <TableBox header={<ImportacaoCnesTableFilter onChange={onFilterChange} initialValues={filter} />}>
      <PagedTable<ItemType>
        render={renderTable}
        columns={[
          { name: 'data', header: 'Data', render: renderData, sortable: true },
          { name: 'responsavel', header: 'Responsável', render: renderResponsavel, sortable: true },
          { name: 'situacao', header: 'Situação', render: renderSituacao },
          { name: 'equipes', header: 'Equipes', render: renderEquipes },
          { name: 'profissionais', header: 'Profissionais', render: renderProfissionais },
          { name: 'lotacoes', header: 'Lotações', render: renderLotacoes },
          { name: 'buttons', render: renderButtons, style: { textAlign: 'right', whiteSpace: 'nowrap' } },
        ]}
        {...rest}
      />
    </TableBox>
  )
}

ImportacaoCnesTable.defaultProps = {
  wizardInstalacao: false,
} as ImportacaoCnesTableProps

const SituacaoProcesso = (props: { processo: ProcessoType }) => {
  const { classes } = useStyles((theme) => ({
    success: {
      fontSize: '1rem',
      color: theme.pallete.status.success.main,
    },
    loading: {
      fontSize: '1rem',
      color: theme.pallete.primary.main,
    },
    warn: {
      fontSize: '1rem',
      color: theme.pallete.status.alert.main,
    },
    error: {
      fontSize: '1rem',
      color: theme.pallete.status.danger.main,
    },
  }))

  if (!props.processo || props.processo.status === 'CONCLUIDO') {
    return (
      <HFlow hSpacing={0.2}>
        <Icon style={classes.success} icon='checkCircleOutline' />
        <Text color='success'>Importado</Text>
      </HFlow>
    )
  } else if (props.processo && props.processo.status === 'EM_EXECUCAO') {
    return (
      <HFlow hSpacing={0.2}>
        <Spinner style={classes.loading} />
        <Text color='primary'>Iniciado</Text>
      </HFlow>
    )
  } else if (props.processo && props.processo.status === 'INTERROMPIDO') {
    return (
      <HFlow hSpacing={0.2}>
        <Icon style={classes.warn} icon='exclamationTriangleOutline' />
        <Text color='alert'>Interrompido</Text>
      </HFlow>
    )
  } else if (props.processo && props.processo.status === 'ERRO') {
    return (
      <HFlow hSpacing={0.2}>
        <Icon style={classes.error} icon='banOutline' />
        <Text color='danger'>Falha</Text>
      </HFlow>
    )
  }

  return null
}
