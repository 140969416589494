/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, DropdownItem, HFlow, Icon, Theme, Tooltip, useTheme } from 'bold-ui'
import { DropdownButton } from 'components/dropdown'

import { FieldStatusEnum, fieldStatusRecord } from './model-fieldStatus'

interface ListFieldStatusProps {
  status: FieldStatusEnum
  onReset: () => void
  onRemove: () => void
  children?: React.ReactNode
}

export const ListFieldStatus = (props: ListFieldStatusProps) => {
  const { status, onReset, onRemove, children } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  const isResetDisabled = status === FieldStatusEnum.NAO_MODIFICADO || status === FieldStatusEnum.ADICIONADO

  return (
    <HFlow hSpacing={0} justifyContent='flex-end'>
      <HFlow alignItems='center' hSpacing={0.5} justifyContent='flex-end'>
        <HFlow style={styles.status} hSpacing={0.5}>
          {status !== FieldStatusEnum.NAO_MODIFICADO && <Icon icon={fieldStatusRecord[status].icon} size={1} />}
        </HFlow>
        <Tooltip
          text={
            isResetDisabled
              ? 'Só é possível restaurar para o estado original um registro que foi modificado'
              : 'Reverter mudanças'
          }
        >
          <Button skin='ghost' size='small' kind='normal' onClick={onReset} disabled={isResetDisabled}>
            <Icon icon='undo' />
          </Button>
        </Tooltip>
      </HFlow>
      <Tooltip
        text={status === FieldStatusEnum.REMOVIDO ? 'Para alterar um registro removido, é preciso restaurá-lo' : ''}
      >
        <DropdownButton disabled={status === FieldStatusEnum.REMOVIDO}>
          {children}
          <DropdownItem type='danger' onClick={onRemove}>
            <HFlow alignItems='center' hSpacing={0.5}>
              <Icon icon='trashOutline' />
              Excluir
            </HFlow>
          </DropdownItem>
        </DropdownButton>
      </Tooltip>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  status: css`
    color: ${theme.pallete.primary.main};
    display: flex;
    align-items: center;
  `,
})
