import ApolloClient from 'apollo-client'
import { Calculation } from 'final-form-calculate'
import { SexoEnum, TipoAtendimentoProfissional, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { Meta, MetaArray, MetaRoot } from 'util/metaPath'
import { meta as atendMeta } from 'view/atendimentos/atendimento-individual/model'
import { createFinalizacaoIndividualCalculations } from 'view/atendimentos/detail/soap/finalizacao/calculator/calculator-individual'
import { ProcedimentoAutomatico } from 'view/atendimentos/model-atendimento'

import { createAgendarConsultasCalculations } from '../../../../agenda/calculator-agendarConsulta'
import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import { FinalizacaoAtendimentoFormModel } from '../FinalizacaoAtendimentoForm'
import { createFinalizacaoOdontoCalculations } from './calculator-odonto'
import { createNovoAtendimentoCalculations } from './calculatorNovoAtendimento'

export const createFinalizacaoAtendimentoCalculations = (
  meta: Meta<FinalizacaoAtendimentoFormModel> & MetaRoot<FinalizacaoAtendimentoFormModel>,
  metaProblemasCondicoes: MetaArray<ProblemaCondicaoModel>,
  apollo: ApolloClient<object>,
  cbo2002: string,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  tipoAtendimentoProfissional: TipoAtendimentoProfissional,
  hasPermissionPreNatal: boolean,
  idadeCidadaoEmAnos: number,
  desejaInformarIdentidadeGenero: boolean,
  sexo: SexoEnum,
  tipoEstabelecimento: TipoEstabelecimentoEnum,
  isObservacaoAndResponsavel: boolean,
  hasProblemaComCiapW78AtivoPersistido: boolean
): Calculation[] => {
  const calculatorByTipoAtendProf: Calculation[] =
    tipoAtendimentoProfissional === TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO
      ? createFinalizacaoOdontoCalculations(meta, procedimentosAutomaticos, tipoEstabelecimento, cbo2002)
      : createFinalizacaoIndividualCalculations(
          meta,
          metaProblemasCondicoes,
          procedimentosAutomaticos,
          cbo2002,
          hasPermissionPreNatal,
          idadeCidadaoEmAnos,
          desejaInformarIdentidadeGenero,
          sexo,
          tipoEstabelecimento,
          isObservacaoAndResponsavel,
          hasProblemaComCiapW78AtivoPersistido
        )
  return [
    ...createNovoAtendimentoCalculations(meta.desfechoAtendimento.atendimento, tipoEstabelecimento, apollo),
    ...createAgendarConsultasCalculations(atendMeta.finalizacao.agendamentoConsultas.avulsa),
    ...calculatorByTipoAtendProf,
  ]
}
