/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, focusBoxShadow, Icon as IconBold, merge, StepProps, Theme, useTheme } from 'bold-ui'
import { useMemo } from 'react'

interface VerticalStepProps extends StepProps {
  connectorStyle?: ExternalStyles
}

export function VerticalStep(props: VerticalStepProps) {
  const { status = 'incompleted', hasConnector = true, connectorStyle, overrides, children, style, ...rest } = props
  const { Root, Icon, IconContainer, Connector, Label } = getComponents(overrides, defaultComponents)

  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme, status), [status, theme])

  return (
    <Root css={css(classes.step, style)} {...rest}>
      {hasConnector && <Connector css={css(classes.connector, connectorStyle)} />}

      <IconContainer css={classes.stepContainer}>
        {Icon && <Icon className={classes.icon} />}
        {!Icon && (
          <span css={classes.iconContainer}>
            {status === 'completed' && <IconBold icon='checkDefault' css={classes.icon} />}
          </span>
        )}
      </IconContainer>

      <Label css={classes.stepLabel}>{children}</Label>
    </Root>
  )
}

const defaultComponents: StepProps['overrides'] = {
  Root: 'span',
  Icon: null,
  IconContainer: 'span',
  Connector: 'span',
  Label: 'span',
}

function getComponents<T>(overrides: T, defaultComponents: T) {
  return merge<T, T, T>({} as any, defaultComponents, overrides)
}

const createStyles = (theme: Theme, status: StepProps['status']) => ({
  step: css`
    height: 2.5rem;
    min-height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    margin-bottom: 4.5rem;
    text-align: left;
    position: relative;
  `,
  connector: css`
    height: 5.5rem;
    position: absolute;
    top: -5rem;
    left: 0.625rem;
    right: 0;
    border-left-width: 0.25rem;
    border-left-style: solid;
    border-left-color: ${status === 'incompleted' ? theme.pallete.gray.c80 : theme.pallete.primary.main};
    transition: all 0.4s ease;
  `,
  stepContainer: css`
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  iconContainer: css`
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${status === 'incompleted' ? theme.pallete.gray.c80 : theme.pallete.primary.main};
    text-align: center;
    transition: all 0.4s ease;
    box-shadow: ${(status === 'active' && focusBoxShadow(theme, 'primary')) ||
    (status === 'completed' && `0 0 0 4px ${theme.pallete.primary.main}`)};
  `,
  icon: css`
    fill: ${theme.pallete.primary.c100};
    width: 1rem;
    height: 1rem;
  `,
  stepLabel: css`
    color: ${status === 'active' && theme.pallete.primary.main};
    font-weight: bold;
    margin-left: 0.75rem;
    transition: all 0.4s ease;
  `,
})
