/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, TableFooter, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { AccordionControls } from 'components/accordion/useAccordionControl'
import { useAlert } from 'components/alert'
import { DateTime } from 'components/date'
import { HLabel } from 'components/HLabel'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { useCompartilhamentoCuidadoPlanoQuery } from 'graphql/hooks.generated'
import { CompartilhamentoCuidadoPlanoDto } from 'graphql/types.generated'
import { LotacaoContextModel } from 'hooks/atendimento-context/model'
import { useFilter } from 'hooks/filter/useFilter'
import { compact } from 'lodash'
import { useMemo, useState } from 'react'
import StatusSquare from 'view/atendimentos/detail/historico/components/StatusSquare'
import { formatNomeProfissional } from 'view/profissional/utils/utils-profissional'

import {
  cuidadoCompartilhadoClassificacaoPrioridadeRecord,
  CuidadoCompartilhadoPlanoModel,
  TipoCompartilhamentoCuidadoPlanoEnum,
} from '../../cuidado-compartilhado/model-cuidadocompartilhado'
import { downloadGuiaEncaminhamentoCuidado } from '../impressao/DownloadGuiaEncaminhamentoCuidado'
import {
  CompartilhamentoCuidadoPlanoModel,
  CompartilhamentoCuidadoTableFilterModel,
  FALHA_NA_IMPRESSAO_GUIA_ENCAMINHAMENTO,
  filterInitialValues,
} from '../model-compartilhamentocuidado'
import {
  compartilhamentoCuidadoFilterByCbo,
  compartilhamentoCuidadoFilterByExecutante,
  compartilhamentoCuidadoFilterByPeriodo,
  compartilhamentoCuidadoFilterByPrioridade,
  compartilhamentoCuidadoFilterBySolicitante,
  compartilhamentoCuidadoFilterByTiposCompartilhamento,
  compartilhamentoCuidadoFilterByUnidadeSaude,
  getProfissionaisCompartilhamentoCuidado,
  joinCompartilhamentosCuidado,
} from '../util-compartilhamentocuidado'
import { CompartilhamentoCuidadoTableActions } from './CompartilhamentoCuidadoTableActions'
import { CompartilhamentoCuidadoTableFilter } from './CompartilhamentoCuidadoTableFilter'
import { CompartilhamentoCuidadoTablePanel } from './CompartilhamentoCuidadoTablePanel'

interface CompartilhamentoCuidadoTableProps extends AccordionControls {
  compartilhamentosAtendimentoAtual?: CuidadoCompartilhadoPlanoModel[]
  prontuarioId: ID
  atendimentoId: ID
  atendimentoProfissionalId?: ID
  iniciadoEm?: Date
  lotacaoAtual?: LotacaoContextModel
  viaFolhaRosto?: boolean
  onEdit?: (id: ID) => void
  onDelete?: (id: ID) => void
}

export const CompartilhamentoCuidadoTable = (props: CompartilhamentoCuidadoTableProps) => {
  const {
    compartilhamentosAtendimentoAtual,
    prontuarioId,
    atendimentoId,
    atendimentoProfissionalId,
    iniciadoEm,
    viaFolhaRosto,
    onEdit,
    onDelete,
    resetExpandedItems,
    ...accordionProps
  } = props
  const theme = useTheme()
  const alert = useAlert()

  const [filter, setFilter] = useState<CompartilhamentoCuidadoTableFilterModel>(filterInitialValues)
  const {
    data: { compartilhamentosCuidadoPlano },
    loading,
  } = useCompartilhamentoCuidadoPlanoQuery({
    variables: {
      prontuarioId,
    },
    fetchPolicy: 'no-cache',
  })

  const { profissionaisSolicitantes, profissionaisExecutantes } = getProfissionaisCompartilhamentoCuidado(
    compartilhamentosAtendimentoAtual,
    compartilhamentosCuidadoPlano as CompartilhamentoCuidadoPlanoDto[]
  )

  const joinedItems = joinCompartilhamentosCuidado(
    compartilhamentosAtendimentoAtual,
    compartilhamentosCuidadoPlano as CompartilhamentoCuidadoPlanoDto[],
    iniciadoEm,
    atendimentoProfissionalId
  )

  const filteredItems = useFilter<CompartilhamentoCuidadoPlanoModel, CompartilhamentoCuidadoTableFilterModel>({
    items: joinedItems || [],
    filter,
    filtersType: [],
    customFilters: [
      compartilhamentoCuidadoFilterByPrioridade(filter?.prioridades),
      compartilhamentoCuidadoFilterBySolicitante(filter?.profissionalSolicitante),
      compartilhamentoCuidadoFilterByExecutante(filter?.profissionalExecutante),
      compartilhamentoCuidadoFilterByUnidadeSaude(filter?.unidadeSaude),
      compartilhamentoCuidadoFilterByPeriodo(filter?.periodo, iniciadoEm),
      compartilhamentoCuidadoFilterByCbo(filter?.cbo),
      compartilhamentoCuidadoFilterByTiposCompartilhamento(filter?.tiposCompartilhamento),
    ],
  })

  const { paginatedItems, tableProps } = usePagination<CompartilhamentoCuidadoPlanoModel>({
    items: filteredItems,
    onChange: resetExpandedItems,
  })

  const handleImpressao = (row: CompartilhamentoCuidadoPlanoModel) => {
    if (row.tipo === TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO) {
      if (row.isRegistradoAgora) {
        downloadGuiaEncaminhamentoCuidado({
          guiaEncaminhamentoAtual: {
            classificacaoPrioridade: row.prioridade,
            codigoCbo2002: row.cbo.cbo2002,
            nomeCbo: row.cbo.nome,
            codigoCid10: row.problemasECondicoes.cid10.codigo,
            descricaoCid10: row.problemasECondicoes.cid10.nome,
            discussao: row.discussao,
          },
          atendimentoProfissionalId,
          atendimentoId,
          prontuarioId,
        }).catch((_) => alert('danger', FALHA_NA_IMPRESSAO_GUIA_ENCAMINHAMENTO))
      } else {
        downloadGuiaEncaminhamentoCuidado({
          id: row.dbId,
          atendimentoProfissionalId: row.atendimentoProfissional.id,
          prontuarioId,
        }).catch((_) => alert('danger', FALHA_NA_IMPRESSAO_GUIA_ENCAMINHAMENTO))
      }
    }
  }
  const cbosToFilter = useMemo(
    () =>
      joinedItems
        .map((item) => item.cbo)
        .removeDuplicate((item) => item.id)
        .sort((a, b) => a.nome.localeCompare(b.nome)),
    [joinedItems]
  )
  const unidadesSaudeToFilter = useMemo(
    () =>
      compact(joinedItems.map((item) => item.unidadeSaude))
        .removeDuplicate((item) => item.id)
        .sort((a, b) => a.nome.localeCompare(b.nome)),
    [joinedItems]
  )
  return (
    <VFlow vSpacing={0}>
      <TableBox
        header={
          <CompartilhamentoCuidadoTableFilter
            onFilterChange={setFilter}
            filter={filter}
            profissionaisExecutantes={profissionaisExecutantes}
            profissionaisSolicitantes={profissionaisSolicitantes}
            cbosToFilter={cbosToFilter}
            unidadeSaudeToFilter={unidadesSaudeToFilter}
          />
        }
      >
        <AccordionDataTable
          {...accordionProps}
          rows={paginatedItems}
          columns={[
            {
              name: 'data',
              header: 'Data',
              render: (row: CompartilhamentoCuidadoPlanoModel) => renderData(row, theme),
              size: 2,
              style: styles.dataStyle,
            },
            {
              name: 'prioridade',
              header: 'Prioridade',
              render: renderPrioridade,
              size: 2,
              style: css`
                text-align: center;
              `,
            },
            {
              name: 'tipoCompartilhamento',
              header: 'Tipo de compartilhamento',
              render: renderTipoCompartilhamento,
              size: 6,
              style: css`
                flex-grow: 1;
              `,
            },
            {
              name: 'actions',
              size: 2,
              style: styles.actionsColumn,
              render: (row) => (
                <CompartilhamentoCuidadoTableActions
                  row={row}
                  viaFolhaRosto={viaFolhaRosto}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onImprimir={handleImpressao}
                />
              ),
            },
          ]}
          components={{ AccordionPanel: CompartilhamentoCuidadoTablePanel }}
          loading={loading}
        />
        <TableFooter {...tableProps} style={styles.tableFooter} />
      </TableBox>
    </VFlow>
  )
}

const renderData = (row: CompartilhamentoCuidadoPlanoModel, theme: Theme) => (
  <HFlow hSpacing={0.5} alignItems='center'>
    <DateTime format='DD/MM/YYYY' value={row.iniciadoEm} />

    {row.isRegistradoAgora && (
      <Tooltip text='Registrado agora'>
        <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
      </Tooltip>
    )}
  </HFlow>
)

const renderTipoCompartilhamento = (row: CompartilhamentoCuidadoPlanoModel) =>
  row.tipo === TipoCompartilhamentoCuidadoPlanoEnum.CUIDADO_COMPARTILHADO ? (
    <VFlow vSpacing={0}>
      <HLabel title='Cuidado compartilhado:'>
        {row.lotacaoExecutante?.profissional
          ? `${formatNomeProfissional(row.lotacaoExecutante.profissional)} - ${row.cbo.nome.capitalize()}`
          : row.cbo.nome.capitalize()}
      </HLabel>
      <HLabel title='Unidade de saúde:'>{row.unidadeSaude?.nome}</HLabel>
    </VFlow>
  ) : (
    <HLabel title='Guia de encaminhamento:'>{row.cbo.nome.capitalize()}</HLabel>
  )

const renderPrioridade = (row: CompartilhamentoCuidadoPlanoModel) => {
  const { label, mainColor } = cuidadoCompartilhadoClassificacaoPrioridadeRecord[row.prioridade]

  return (
    <HFlow justifyContent='center'>
      <StatusSquare color={mainColor} description={label} descriptionAsTooltip />
    </HFlow>
  )
}

const styles = {
  actionsColumn: css`
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: flex-end;
  `,
  tableFooter: css`
    border-top-width: 0;
  `,
  dataStyle: css`
    width: 8rem;
    max-width: 8rem;
    min-width: 8rem;
  `,
}
