import { useTheme } from 'bold-ui'
import { Chart, ChartBody, ChartContainer, ChartFooter, DateRange, ReferenceArea } from 'bold-ui/lib/components/Chart'
import { green, red } from 'bold-ui/lib/styles/colors'
import { SexoEnum } from 'graphql/types.generated'
import moment, { Duration, Moment } from 'moment'
import React from 'react'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { reduceMedicoesIguais } from '../../util-medicoes'
import { LinhaTooltipGrafico } from '../components/LinhaTooltipGrafico'
import { getReferenceAreasCalculators, ReferenceAreasCalculator } from './referenceAreaCalculators'
import { TickIdade } from './TickIdade'

export interface GraficoPerimetroCefalicoProps {
  medicoes: HistoricoMedicaoModel[]
  dataNascimento: Moment
  dataRange: DateRange
  sexo: SexoEnum
}

// TODO@CODEBENDERS - Adicionar funcionalidade de outliers #23674
export function GraficoPerimetroCefalicoView(props: GraficoPerimetroCefalicoProps) {
  const { medicoes, dataNascimento, dataRange, sexo } = props
  const theme = useTheme()
  const idadeNoRange = moment.duration(moment(dataRange.end).diff(dataNascimento))

  const medicoesComPerimetroCefalico = reduceMedicoesIguais(medicoes, 'valorPerimetroCefalico') ?? []
  const seriesData = medicoesComPerimetroCefalico.map((m) => ({
    x: m.dataMedicao,
    y: m.valorPerimetroCefalico,
  }))
  const yRange = { init: 30, end: 53, step: 1 }

  return (
    <ChartContainer>
      <ChartBody height={500}>
        <Chart<Moment>
          series={[{ name: 'Perímetro cefálico', data: seriesData, color: theme.pallete.gray.c20 }]}
          referenceAreas={getReferenceAreas(sexo, dataRange, idadeNoRange, dataNascimento)}
          xAxis={{
            title: 'Idade (meses completos e anos)',
            domain: dataRange,
            tickRenderer: (props) => <TickIdade {...props} dataNascimento={dataNascimento} />,
          }}
          yAxis={{ title: 'Perímetro cefálico', unit: 'cm', domain: yRange }}
          showLegend={false}
          tooltip={{
            type: 'point',
            render: (points) => (
              <>
                {points?.map((p) => (
                  <LinhaTooltipGrafico key={p.y}>{`Perímetro cefálico: ${p.y} cm`}</LinhaTooltipGrafico>
                ))}
                <LinhaTooltipGrafico>{`Data: ${moment(points[0].x).format('DD/MM/YYYY')}`}</LinhaTooltipGrafico>
              </>
            ),
          }}
        />
      </ChartBody>
      <ChartFooter>{getFooterText(sexo, idadeNoRange)}</ChartFooter>
    </ChartContainer>
  )
}

function getFooterText(sexo: SexoEnum, idadeNoRange: Duration): string {
  if (idadeNoRange.years() <= 2) {
    if (sexo === SexoEnum.MASCULINO) return 'Fonte: Caderneta de Saúde da Criança Menino - 2020.'
    else if (sexo === SexoEnum.FEMININO) return 'Fonte: Caderneta de Saúde da Criança Menina - 2020.'
  }
  return ''
}

function getReferenceAreas(
  sexo: SexoEnum,
  xRange: DateRange,
  idadeNoRange: Duration,
  dataNascimento: Moment
): ReferenceArea<Moment>[] {
  if (idadeNoRange.years() <= 2) {
    const refAreaCalculators = getReferenceAreasCalculators(sexo, dataNascimento, xRange, 'perimetroCefalico')
    return [
      getReferenceAreaAbaixoEsperado(refAreaCalculators),
      getReferenceAreaAdequado(refAreaCalculators),
      getReferenceAreaAcimaEsperado(refAreaCalculators),
    ]
  } else return undefined
}

function getReferenceAreaAbaixoEsperado(getsPercentis: ReferenceAreasCalculator[]): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit: referenceAreaCalculator(-2),
    })),
    label: {
      name: 'Abaixo do esperado',
    },
    color: red.c90,
    stroke: {
      color: red.c60,
    },
    tick: {
      color: red.c60,
    },
  }
}

function getReferenceAreaAdequado(getsPercentis: ReferenceAreasCalculator[]): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit: referenceAreaCalculator(2),
    })),
    label: {
      name: 'Adequado',
    },
    color: green.c90,
    stroke: {
      color: red.c60,
    },
    tick: {
      color: green.c60,
    },
  }
}

function getReferenceAreaAcimaEsperado(getsPercentis: ReferenceAreasCalculator[]): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date }) => ({
      x: date,
    })),
    label: {
      name: 'Acima do esperado',
    },
    color: red.c90,
    stroke: {
      show: false,
    },
    tick: {
      color: red.c60,
    },
  }
}
