import { isPromise } from 'bold-ui'
import { FormApi, FormState } from 'final-form'
import createFocusOnErrorDecorator from 'final-form-focus'
import React, { useCallback, useEffect, useRef } from 'react'
import {
  AnyObject,
  Form as FinalForm,
  FormProps as FinalFormProps,
  FormRenderProps as FinalFormRenderProps,
  useForm,
} from 'react-final-form'
import { flatten, isObjectDeepEmpty, isObjectDeepEqualById } from 'util/object'
import { isEmpty } from 'util/validation/Util'

export type FormRenderProps<FormValues = AnyObject> = FinalFormRenderProps<FormValues>

export type ResultType = object | Promise<object | undefined> | undefined | void

export interface FormProps<FormValues> extends FinalFormProps<FormValues> {
  focusOnError?: boolean
  dirtyInitialValues?: FormValues
  transformResult?(
    result: ResultType,
    supressNotificationError?: boolean,
    suppressValidationError?: boolean,
    supressFieldValidationNotificationError?: boolean
  ): ResultType
  onSubmitSucceeded?(formState: FormState<FormValues>): void
  onSubmitFailed?(formState: FormState<FormValues>, supressNotificationError?: boolean): void
  resetFormToInitialValues?: boolean
  suppressNotificationError?: boolean
  suppressValidationError?: boolean
  supressFieldValidationNotificationError?: boolean
}

const focusOnErrorDecorator = createFocusOnErrorDecorator()

export function Form<FormValues extends object = any>(props: FormProps<FormValues>) {
  const {
    focusOnError,
    dirtyInitialValues,
    transformResult,
    onSubmitFailed,
    onSubmitSucceeded,
    onSubmit,
    render,
    resetFormToInitialValues = false,
    suppressNotificationError = false,
    suppressValidationError = false,
    supressFieldValidationNotificationError = true,
    ...rest
  } = props

  const resetForm = useRef(false)
  resetForm.current = resetFormToInitialValues

  const renderForm = useCallback(
    (formRenderProps: FormRenderProps<FormValues>) => {
      const { form, handleSubmit } = formRenderProps

      if (resetForm.current) {
        setTimeout(() => form.getRegisteredFields().forEach((field) => form.resetFieldState(field)))
        setTimeout(form.reset)
        resetForm.current = false
      }

      const handleSubmitWrapper = (event) => {
        if (onSubmitFailed && !isObjectDeepEmpty(form.getState().errors)) {
          setTimeout(() => onSubmitFailed(form.getState(), suppressNotificationError))
        }
        return handleSubmit(event)
      }

      return (
        <>
          {dirtyInitialValues && <FormDirtyValuesSetter dirtyInitialValues={dirtyInitialValues} />}
          {render({
            ...formRenderProps,
            handleSubmit: handleSubmitWrapper,
          })}
        </>
      )
    },
    [dirtyInitialValues, onSubmitFailed, render, suppressNotificationError]
  )

  const formOnSubmit = useCallback(
    (values: FormValues, form: FormApi<FormValues>) => {
      const emitSubmitEvents = (submitResult: ResultType, form: FormApi<FormValues>) => {
        if (!submitResult && onSubmitSucceeded) {
          setTimeout(() => onSubmitSucceeded(form.getState()))
        }

        if (submitResult && onSubmitFailed) {
          setTimeout(() => onSubmitFailed(form.getState(), suppressNotificationError))
        }
      }

      const result = onSubmit(values, form)
      let ret = transformResult(
        result,
        suppressNotificationError,
        suppressValidationError,
        supressFieldValidationNotificationError
      )

      if (isPromise(ret)) {
        ret = ret.then((res) => {
          emitSubmitEvents(res, form)
          return res
        })
      } else {
        emitSubmitEvents(ret, form)
      }

      return ret
    },
    [
      onSubmit,
      onSubmitFailed,
      onSubmitSucceeded,
      suppressNotificationError,
      suppressValidationError,
      transformResult,
      supressFieldValidationNotificationError,
    ]
  )

  const decorators = props.decorators ?? []
  if (focusOnError) {
    decorators.push(focusOnErrorDecorator)
  }

  return <FinalForm<FormValues> {...rest} onSubmit={formOnSubmit} render={renderForm} decorators={decorators} />
}

Form.defaultProps = {
  focusOnError: true,
  decorators: [],
  transformResult: (result) => result,
} as Partial<FormProps<any>>

function FormDirtyValuesSetter<T>(props: { dirtyInitialValues: T }) {
  const { dirtyInitialValues } = props

  const { batch, change, getRegisteredFields, getFieldState } = useForm()

  const fieldsRegistered = !!getRegisteredFields().length

  useEffect(() => {
    if (dirtyInitialValues && fieldsRegistered) {
      batch(() => {
        const flattenedDirtyInitialValues = flatten(dirtyInitialValues)

        Object.entries(flattenedDirtyInitialValues).forEach(([dirtyInitialValuePath, dirtyInitialValue]) => {
          const field = getFieldState(dirtyInitialValuePath)

          if (!field?.value) {
            change(dirtyInitialValuePath, dirtyInitialValue)
          } else if (!field.dirty && !areFormValuesEqual(field.initial, dirtyInitialValue)) {
            field.change(dirtyInitialValue)
            field.blur()
          }
        })
      })
    }
  }, [batch, change, dirtyInitialValues, fieldsRegistered, getFieldState])

  return null
}

const normalizeEmptyValue = (value: any) => (!isEmpty(value) ? value : undefined)
const areFormValuesEqual = (oldValue: any, newValue: any) => {
  const a = normalizeEmptyValue(oldValue)
  const b = normalizeEmptyValue(newValue)
  return typeof b === 'object' ? isObjectDeepEqualById(a, b) : '' + a === '' + b
}
