/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Theme, useTheme, VFlow } from 'bold-ui'
import { useMemo } from 'react'

import { MChatResultadoView } from './mchat-resultado/MChatResultadoView'
import { MChatFieldModel } from './model-mchat'

interface MChatModalSectionResultadoProps {
  values: MChatFieldModel
  onClickVoltar: () => void
}

export function MChatModalSectionResultado(props: MChatModalSectionResultadoProps) {
  const { values, onClickVoltar } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  return (
    <VFlow>
      <Button kind='normal' size='small' style={styles.voltarButton} onClick={onClickVoltar}>
        <Icon icon='arrowLeft' style={styles.icons} />
        Voltar à avaliação
      </Button>
      <HFlow alignItems='center' justifyContent='space-between'>
        <Heading level={1}>Resultado M-CHAT-R™</Heading>
        <Button kind='normal' size='small' style={styles.imprimirButton} disabled>
          <Icon icon='printerOutline' style={styles.icons} />
          Imprimir resultado
        </Button>
      </HFlow>
      <MChatResultadoView isInsideModal values={values} />
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  icons: css`
    margin-right: 0.5rem;
  `,
  voltarButton: css`
    margin-bottom: 1rem;
  `,
  imprimirButton: css`
    color: ${theme.pallete.primary.main};
    border-color: ${theme.pallete.primary.main};
  `,
})
