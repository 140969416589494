import { Cell, Grid, HeadingSection, InfoLabel } from 'bold-ui'
import { DateTime } from 'components/date'
import { Cns, Cpf } from 'components/label'
import { CidadaoFciVisualizacaoQuery, NacionalidadeEnum, RacaCorDbEnum } from 'graphql/types.generated'
import React from 'react'
import { identidadeGenero, nacionalidade, orientacaoSexual, sexo, tipoSanguineo } from 'types/enums'

interface CidadaoFciDadosPessoaisSectionProps {
  cidadao: CidadaoFciVisualizacaoQuery['cidadao']
}

export const CidadaoFciDadosPessoaisSection = (props: CidadaoFciDadosPessoaisSectionProps) => {
  const { cidadao } = props
  const isIndigena = cidadao?.racaCor?.racaCorDbEnum === RacaCorDbEnum.INDIGENA

  return (
    <HeadingSection level={2} title='Dados pessoais'>
      <Grid wrap>
        <Cell size={4}>
          <InfoLabel title='CPF'>
            <Cpf value={cidadao?.cpf} />
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='CNS'>
            <Cns value={cidadao?.cns} />
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Nº NIS (PIS/PASEP)'>{cidadao?.nisPisPasep}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Data de nascimento'>
            <DateTime format='DD/MM/YYYY' value={cidadao?.dataNascimento} />
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Sexo'>{sexo[cidadao?.sexo]}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Estado civil'>{cidadao?.estadoCivil?.nome.titleCase()}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Raça/cor'>{cidadao?.racaCor?.nome?.titleCase()}</InfoLabel>
        </Cell>
        {isIndigena && (
          <>
            <Cell size={4}>
              <InfoLabel placeholder='-' title='Etnia'>
                {cidadao?.etnia?.nome?.titleCase()}
              </InfoLabel>
            </Cell>
          </>
        )}
        <Cell size={isIndigena ? 4 : 8}>
          <InfoLabel title='Tipo sanguíneo'>{tipoSanguineo[cidadao?.tipoSanguineo]}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Nome da mãe'>{cidadao?.nomeMae?.titleCase()}</InfoLabel>
        </Cell>
        <Cell size={8}>
          <InfoLabel title='Nome do pai'>{cidadao?.nomePai?.titleCase()}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Orientação sexual'>{orientacaoSexual[cidadao?.orientacaoSexualDbEnum]}</InfoLabel>
        </Cell>
        <Cell size={8}>
          <InfoLabel title='Identidade de gênero'>{identidadeGenero[cidadao?.identidadeGeneroDbEnum]}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Nacionalidade'>{nacionalidade[cidadao?.nacionalidade?.nacionalidadeDbEnum]}</InfoLabel>
        </Cell>
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.BRASILEIRA && (
          <Cell size={8}>
            <InfoLabel title='Município de nascimento'>
              {[cidadao?.localidadeNascimento?.nome?.titleCase(), cidadao?.localidadeNascimento?.uf?.sigla]
                .filter(Boolean)
                .join(' - ')}
            </InfoLabel>
          </Cell>
        )}
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.NATURALIZADA && (
          <>
            <Cell size={4}>
              <InfoLabel title='Portaria de naturalização'>{cidadao?.portariaNaturalizacao?.titleCase()}</InfoLabel>
            </Cell>

            <Cell size={4}>
              <InfoLabel title='Data de naturalização'>
                <DateTime format='DD/MM/YYYY' value={cidadao?.dataNaturalizacao} />
              </InfoLabel>
            </Cell>
          </>
        )}
        {cidadao?.nacionalidade?.nacionalidadeDbEnum === NacionalidadeEnum.ESTRANGEIRA && (
          <>
            <Cell size={4}>
              <InfoLabel title='País de nascimento'>{cidadao?.paisNascimento?.nome?.titleCase()}</InfoLabel>
            </Cell>

            <Cell size={4}>
              <InfoLabel title='Data de entrada no Brasil'>
                <DateTime format='DD/MM/YYYY' value={cidadao?.dataEntradaBrasil} />
              </InfoLabel>
            </Cell>
          </>
        )}
      </Grid>
    </HeadingSection>
  )
}
