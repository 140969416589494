import { Scalars, StatusRelatorioEnvioRndsEnum, TipoRegistroRndsEnum } from 'graphql/types.generated'

export interface RndsRelatorioImpressaoModel {
  statusEnvioRegistro: StatusRelatorioEnvioRndsEnum
  periodo: {
    startDate: Scalars['Instant']
    endDate: Scalars['Instant']
  }
  tipoRegistro: TipoRegistroRndsEnum[]
}

export const RELATORIO_RNDS_FILENAME = 'relatorio-rnds'
export const RELATORIO_RNDS_ATMOSPHERE_PATH = 'relatorio-rnds'
