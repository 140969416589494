import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import React, { useContext, useMemo } from 'react'
import { isCboPodeRegistrarCid } from 'util/atendimento/cboHelper'
import { toDate } from 'util/date/formatDate'
import { grupoCboAcompanhamentoPreNatal, grupoCboPreNatal } from 'view/atendimentos/detail/soap/pre-natal/acessos'

import { AtendimentoContextInputModel, AtendimentoContextModel } from './model'

interface AtendimentoContextProps {
  input: AtendimentoContextInputModel
  children: React.ReactNode
}

const convertAtendimentoModelToContext = (
  atendimento: AtendimentoContextInputModel
): Omit<AtendimentoContextModel, 'permissoes'> => ({
  ...atendimento,
  iniciadoEm: toDate(atendimento.iniciadoEm),
  atendimentoProfissional: {
    ...atendimento.atendimentoProfissional,
    iniciadoEm: toDate(atendimento.atendimentoProfissional?.iniciadoEm),
  },
  cidadao: {
    ...atendimento.cidadao,
    desejaInformarIdentidadeGenero: atendimento.cidadao.desejaInformarIdentidadeGenero === true,
  },
})

export const AtendimentoContext = React.createContext<AtendimentoContextModel>(undefined)

export function AtendimentoProvider(props: AtendimentoContextProps) {
  const { input, children } = props

  const { hasCboAuth } = useSession()
  const { acesso } = useAcessoLotacaoOrEstagio()

  const cbo2002 = acesso?.cbo.cbo2002

  const value: AtendimentoContextModel = useMemo(
    () => ({
      ...convertAtendimentoModelToContext(input),
      permissoes: {
        hasPermissionPreNatal: hasCboAuth(grupoCboPreNatal),
        hasPermissionAcompanhamentoPreNatal: hasCboAuth(grupoCboAcompanhamentoPreNatal),
        somenteCiap: !isCboPodeRegistrarCid(cbo2002),
      },
    }),
    [cbo2002, hasCboAuth, input]
  )

  return <AtendimentoContext.Provider value={value}>{children}</AtendimentoContext.Provider>
}

export const useAtendimentoContext = () => useContext(AtendimentoContext)
