import { DateRange, getDomainPoints, ReferenceArea } from 'bold-ui/lib/components/Chart'
import { green, red } from 'bold-ui/lib/styles/colors'
import { parseISO } from 'date-fns'
import moment, { Moment } from 'moment'
import { calculatePercentilAlturaUterina } from 'util/atendimento/gestante/calculatePercentilAlturaUterina'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { MedicoesPreNatal } from '../../../../types/model-historicoPreNatal'

export function convertToGrafico(medicoes: MedicoesPreNatal[]): HistoricoMedicaoModel[] {
  return medicoes.map((it) => ({
    dataMedicao: parseISO(it.dataDaMedicao),
    valorAlturaUterina: parseInt(it.alturaUterina, 10),
  }))
}

export function getReferenceAreas(xRange: DateRange, dataInicioGestacao: string): ReferenceArea<Moment>[] {
  return [
    getReferenceAreaAbaixoEsperado(xRange, dataInicioGestacao),
    getReferenceAreaDentroEsperado(xRange, dataInicioGestacao),
    getReferenceAreaAcimaEsperado(xRange, dataInicioGestacao),
  ]
}

function getReferenceAreaAbaixoEsperado(xRange: DateRange, dataInicioGestacao: string): ReferenceArea<Moment> {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit: getReferenceAreaUpperLimit('baixo', date, moment(dataInicioGestacao)),
    })),
    label: {
      name: 'Abaixo do esperado',
    },
    color: red.c90,
    stroke: {
      color: red.c60,
    },
    tick: {
      color: red.c60,
    },
  }
}

function getReferenceAreaDentroEsperado(xRange: DateRange, dataInicioGestacao: string): ReferenceArea<Moment> {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit: getReferenceAreaUpperLimit('adequado', date, moment(dataInicioGestacao)),
    })),
    label: {
      name: 'Dentro do esperado',
    },
    color: green.c90,
    stroke: {
      color: red.c60,
    },
    tick: {
      color: green.c60,
    },
  }
}

function getReferenceAreaAcimaEsperado(xRange: DateRange, dataInicioGestacao: string): ReferenceArea<Moment> {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit: getReferenceAreaUpperLimit('alto', date, moment(dataInicioGestacao)),
    })),
    label: {
      name: 'Acima do esperado',
    },
    color: red.c90,
    stroke: {
      show: false,
    },
    tick: {
      color: red.c60,
    },
  }
}

function getReferenceAreaUpperLimit(
  tipo: 'baixo' | 'adequado' | 'alto',
  date: Moment,
  dataInicioGestacao: Moment
): number {
  const semanasGestacao = Math.round(moment(date).diff(moment(dataInicioGestacao), 'week'))
  if (tipo === 'baixo') return calculatePercentilAlturaUterina(semanasGestacao, 10)
  else if (tipo === 'adequado') return calculatePercentilAlturaUterina(semanasGestacao, 90)
}
