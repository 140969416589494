/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Theme, Tooltip, useTheme } from 'bold-ui'
import { ColorScale } from 'bold-ui/lib/styles/colors'
import { resolveName } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { useMemo } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

export interface SwitchButtonOption<T> {
  value: T
  label: string
  colorScale?: ColorScale
  tooltipText?: string
  disabled?: boolean
}

interface SwitchButtonFieldProps<T> {
  name: MetaPath<T>
  options: SwitchButtonOption<T>[]
  description?: string
  tooltipText?: string
  clearable?: boolean
  disabled?: boolean
}

export function SwitchButtonField<T>(props: SwitchButtonFieldProps<T>) {
  const { name, options, description, tooltipText, clearable, disabled } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  const {
    input: { value: currentValue, onChange, onBlur },
  } = useField<T>(resolveName(name))

  const handleClick = (buttonValue: T) => {
    const isClearing = currentValue === buttonValue && clearable
    onChange(isClearing ? null : buttonValue)
    onBlur()
  }

  return (
    <HFlow hSpacing={0.5} justifyContent='flex-end' alignItems='center'>
      {description && <InfoIcon icon='infoCircleFilled' text={description} />}
      {options.map((option) => (
        <Tooltip key={`${option.value}`} text={option.tooltipText ?? tooltipText}>
          <Button
            size='small'
            style={[styles.button, currentValue === option.value && styles.checkedButton(option.colorScale)]}
            onClick={() => handleClick(option.value)}
            disabled={option.disabled ?? disabled}
          >
            {option.label}
          </Button>
        </Tooltip>
      ))}
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  button: css`
    min-width: 4.5rem;
  `,
  checkedButton: (colorScale: ColorScale = theme.pallete.primary) => css`
    color: ${colorScale.c40};
    background-color: ${colorScale.c90};
    border: 1px solid ${colorScale.c40};
  `,
})
