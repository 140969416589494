import { ModalProps } from 'bold-ui'
import { useRetificacaoAtendimentoModalQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { Fragment, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { useJustificativaVerProntuarioId } from 'view/prontuario/justificativa/useJustificativaVerProntuarioId'

import { JustificativaRetificacaoAtendimentoModal } from './components/JustificativaRetificacaoAtendimentoModal'
import { RemoveRegistroConfirmModal } from './components/RemoveRegistroConfirmModal'
import { RetificacaoAtendimentoModel, RetificacaoAtendimentoOpcaoEnum } from './model-retificacaoAtendimento'
import { useRetificacaoStorage } from './useJustificativaRetificacaoStorage'

interface RetificacaoAtendimentoModalProps extends Omit<ModalProps, 'onSubmit'> {
  cidadaoId: ID
  dataAtendimento: Instant
  atendProfId: ID
  open: boolean
  onClose(): void
  onRemoveSuccess?(): void
  nomeCidadao: string
}

export function RetificacaoAtendimentoModal(props: RetificacaoAtendimentoModalProps) {
  const { cidadaoId, dataAtendimento, atendProfId, open, onClose, onRemoveSuccess } = props
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const { analytics } = useFirebase()

  const [modalRemoveRegistroOpen, setModalRemoveRegistroOpen] = useState(false)
  const {
    data: { atendimentoIndividual },
    loading: loadingAtendimentoId,
  } = useRetificacaoAtendimentoModalQuery({
    skip: !atendProfId,
    variables: { atendimentoProfissionalId: atendProfId },
  })
  const { id: atendimentoId, isRegistroTardio } = atendimentoIndividual?.atendimento || {}

  const { getRetificacaoStorageValue, setRetificacaoStorageValue } = useRetificacaoStorage(atendimentoId)

  const handleModalRemoveRegistroOpen = () => setModalRemoveRegistroOpen(true)

  const handleModalRemoveRegistroClose = () => setModalRemoveRegistroOpen(false)

  const handleModalRemoveRegistroSubmit = () => {
    handleModalRemoveRegistroClose()
    onClose()
    onRemoveSuccess?.()
  }

  const [justificativaVerProntuarioId] = useJustificativaVerProntuarioId(`/cidadao/${cidadaoId}`, cidadaoId)

  const handleRetificacaoAtendimentoModalSubmit = (values: RetificacaoAtendimentoModel) => {
    if (values.tipo === RetificacaoAtendimentoOpcaoEnum.REMOVER_REGISTRO) {
      setRetificacaoStorageValue({
        justificativa: values.justificativa,
      })
      analytics.logEvent('remover_registro_proced_RET')
      handleModalRemoveRegistroOpen()
    } else {
      analytics.logEvent('iniciar_atnd_proced_RET')
      const callbackUrl = `${match.url}${location.search}`

      setRetificacaoStorageValue({
        justificativa: values.justificativa,
        callbackUrl,
        justificativaVerProntuarioId,
      })
      const baseRoute = isRegistroTardio ? '/registroTardio' : '/lista-atendimento/atendimento-individual'

      history.push(`${baseRoute}/${atendProfId}/retificar`)
    }
  }

  return (
    <Fragment>
      <RemoveRegistroConfirmModal
        open={modalRemoveRegistroOpen}
        atendimentoProfissionalId={atendProfId}
        justificativa={getRetificacaoStorageValue().justificativa}
        onCancelar={handleModalRemoveRegistroClose}
        onConfirmar={handleModalRemoveRegistroSubmit}
        nomeCidadao={props.nomeCidadao.titleCase()}
      />
      <JustificativaRetificacaoAtendimentoModal
        cidadaoId={cidadaoId}
        dataAtendimento={dataAtendimento}
        open={open}
        onCancelar={onClose}
        onSubmit={handleRetificacaoAtendimentoModalSubmit}
        loading={loadingAtendimentoId}
      />
    </Fragment>
  )
}
