import { round } from 'lodash'
import { parseNumber } from 'util/number'

export function calcularIMC(peso: number | string, altura: number | string) {
  peso = typeof peso === 'string' ? parseNumber(peso) : peso
  altura = typeof altura === 'string' ? parseNumber(altura) : altura

  const imc = round(peso / (altura / 100) ** 2, 2)
  return altura !== 0 && imc >= 0 ? imc : undefined
}
