import { css } from '@emotion/core'
import { Cell, Checkbox, Grid } from 'bold-ui'
import { CategoriaArquivoAtendProfEnum } from 'graphql/types.generated'
import React from 'react'

import { AnexoArquivoFilterModel } from '../../model-anexoArquivos'
import { CategoriaArquivoFilterSelect } from './CategoriaArquivoFilterSelect'

export interface AnexoArquivoTableHeaderProps {
  onFilterChange: (value: AnexoArquivoFilterModel) => void
  currentFilter: AnexoArquivoFilterModel
}

export function AnexoArquivosTableHeader(props: AnexoArquivoTableHeaderProps) {
  const { onFilterChange, currentFilter } = props

  const handleChangeCategoria = (value: CategoriaArquivoAtendProfEnum) => {
    onFilterChange({ categoria: value, somenteMeus: currentFilter.somenteMeus })
  }

  const handleToggleSomenteMeus = () => {
    onFilterChange({ categoria: currentFilter.categoria, somenteMeus: !currentFilter.somenteMeus })
  }

  return (
    <div css={styles.gridContainer}>
      <Grid alignItems='center'>
        <Cell size={5}>
          <CategoriaArquivoFilterSelect
            placeholder='Filtrar por categoria de arquivo'
            onChange={handleChangeCategoria}
            onClear={() => handleChangeCategoria(null)}
          />
        </Cell>
        <Cell size={7}>
          <Checkbox label='Ver somente meus anexos' onChange={handleToggleSomenteMeus} />
        </Cell>
      </Grid>
    </div>
  )
}

const styles = {
  gridContainer: css`
    padding: 0.25rem;
  `,
}
