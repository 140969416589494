import { useTheme } from 'bold-ui'
import { TickProps } from 'bold-ui/lib/components/Chart/Tick'
import moment, { Moment } from 'moment'
import React from 'react'

export function TickIdade(props: TickProps & { dataNascimento: Moment }) {
  const {
    payload: { value: dataMedicao },
    dataNascimento,
    ...rest
  } = props
  const theme = useTheme()

  const precision = 'months'
  const idade = moment.duration(moment(dataMedicao).diff(dataNascimento, precision), precision)
  const isAno = !idade.months()
  return (
    <>
      <text
        {...rest}
        dy={15}
        fill={isAno ? theme.pallete.text.main : undefined}
        style={isAno ? { fontWeight: 'bold', fontSize: '1rem' } : {}}
      >
        {isAno ? idade.years() : idade.months()}
      </text>
    </>
  )
}
