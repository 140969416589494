/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Box } from 'components/Box'
import { CheckboxField, EquipeSelectField, Form, FormRenderProps, SubmitButton } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { RecordStringSelectField } from 'components/form/final-form/RecordStringSelectField/RecordStringSelectField'
import { useServerTime } from 'hooks/useServerTime'
import { metaPath } from 'util/metaPath'
import {
  AtividadeColetivaFilterFormModel,
  AtividadeColetivaFilterPopperFormModel,
  tipoAtividadeRecord,
  TIPOS_ATIVIDADE_EM_SAUDE,
  TIPOS_ATIVIDADE_REUNIAO,
} from 'view/atividade-coletiva/model-atividadeColetiva'

const isCleanFilter = (filter: AtividadeColetivaFilterFormModel) => {
  return (
    !filter.equipe &&
    !filter.isProcessado &&
    !filter.tipoAtividade &&
    !filter.periodo?.startDate &&
    !filter.periodo?.endDate &&
    !filter.isSomenteRegistrosProprios &&
    !filter.nomeCnsCpfProfissionalResponsavel
  )
}

const path = metaPath<AtividadeColetivaFilterPopperFormModel>()

interface AtividadeColetivaFilterPopperFormProps {
  filter: AtividadeColetivaFilterFormModel
  onChangeFilter: (values: AtividadeColetivaFilterFormModel) => void
  onClose: () => void
}

export function AtividadeColetivaFilterPopperForm(props: AtividadeColetivaFilterPopperFormProps) {
  const { filter, onChangeFilter, onClose } = props
  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()
  const {
    acesso: { municipio },
  } = useAcessoLotacaoOrEstagio()

  const handleClear = () =>
    onChangeFilter({
      isReuniao: filter.isReuniao,
      pageParams: filter.pageParams,
    })

  const handleSubmit = (values: AtividadeColetivaFilterPopperFormModel) => {
    onChangeFilter({ ...filter, ...values })
    onClose()
  }

  const renderForm = (formProps: FormRenderProps<AtividadeColetivaFilterPopperFormModel>) => {
    return (
      <Box style={styles.box}>
        <Grid gap={1}>
          <Cell size={12}>
            <RecordStringSelectField
              name={path.tipoAtividade}
              label='Tipo de atividade'
              record={tipoAtividadeRecord}
              subset={!!filter.isReuniao ? TIPOS_ATIVIDADE_REUNIAO : TIPOS_ATIVIDADE_EM_SAUDE}
            />
          </Cell>

          <Cell size={12}>
            <HFlow alignItems='flex-end'>
              <DateRangeField name={path.periodo} label='Período do registro' maxDate={serverTime} />
            </HFlow>
          </Cell>

          <Cell size={12}>
            <EquipeSelectField name={path.equipe} label='Equipe' localidadeId={municipio.id} />
          </Cell>

          <Cell size={12}>
            <CheckboxField name={path.isProcessado} label='Ver somente registros já processados' />
          </Cell>

          <Cell size={12}>
            <HFlow justifyContent='center'>
              <Button onClick={onClose} size='small' skin='outline' kind='normal'>
                Fechar
              </Button>

              <Tooltip text='Redefinir filtros para o padrão'>
                <Button kind='normal' size='small' onClick={handleClear} disabled={isCleanFilter(filter)}>
                  <Icon style={styles.redoIcon} icon='redo' />
                  Voltar para padrão
                </Button>
              </Tooltip>

              <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
                Filtrar
              </SubmitButton>
            </HFlow>
          </Cell>
        </Grid>
      </Box>
    )
  }

  return (
    <Form<AtividadeColetivaFilterPopperFormModel> initialValues={filter} render={renderForm} onSubmit={handleSubmit} />
  )
}

const styles = {
  box: css`
    width: 21.75rem;
    margin-top: 1rem;
  `,
  redoIcon: css`
    padding-right: 0.5rem;
  `,
}
