import {
  FaixaEtariaConsumoAlimentar,
  MarcadorConsumoAlimentar,
  MarcadoresConsumoAlimentarInput,
  PerguntasQuestionarioEnum as PerguntaEnum,
  PerguntasQuestionarioEnum,
  RespostaMarcadoresConsumoAlimentar,
} from 'graphql/types.generated'
import { Dictionary, keyBy } from 'lodash'

import { ObjetivoFormModel } from '../ObjetivoForm'
import {
  MarcadoresConsumoPanelModel,
  MCADoisAnosOuMaisPanelModel,
  MCAMenoresDeSeisMesesPanelModel,
  MCASeisAVinteETresMesesPanelModel,
} from './model'
import {
  anyFilledValue,
  calculateFaixaEtariaMarcadoresConsumoAlimentar,
  getPrimeiraOpcaoSelecionada,
  getQuestionsByFaixaEtariaConsumoAlimentar,
} from './utils'

export const convertMCAToInput = (
  form: ObjetivoFormModel,
  dataNascimentoCidadao: number,
  dataReferencia: number
): MarcadoresConsumoAlimentarInput => {
  const dataNascimentoCidadaoDate = new Date(dataNascimentoCidadao)
  const dataReferenciaDate = new Date(dataReferencia)
  const faixaEtaria = calculateFaixaEtariaMarcadoresConsumoAlimentar(dataNascimentoCidadaoDate, dataReferenciaDate)
  const questions = getQuestionsByFaixaEtariaConsumoAlimentar(faixaEtaria)
  const mca = form.marcadoresConsumoAlimentar

  if (anyFilledValue(Object.keys(questions), mca)) {
    switch (faixaEtaria) {
      case FaixaEtariaConsumoAlimentar.MENOR_SEIS_MESES:
        return convertMCAMenoresDeSeisMeses(form.marcadoresConsumoAlimentar, faixaEtaria)
      case FaixaEtariaConsumoAlimentar.MAIOR_SEIS_MESES_MENOR_DOIS_ANOS:
        return convertMCASeisAVinteETresMeses(form.marcadoresConsumoAlimentar, faixaEtaria)
      case FaixaEtariaConsumoAlimentar.MAIOR_DOIS_ANOS:
        return convertMCADoisAnosOuMais(form.marcadoresConsumoAlimentar, faixaEtaria)
    }
  } else return undefined
}

const convertMCAMenoresDeSeisMeses = (
  marcadores: MCAMenoresDeSeisMesesPanelModel,
  faixaEtaria: FaixaEtariaConsumoAlimentar
): MarcadoresConsumoAlimentarInput => ({
  id: marcadores.editingId,
  faixaEtaria: faixaEtaria,
  respostas: [
    { pergunta: PerguntaEnum.CRIANCA_ONTEM_TOMOU_LEITE_PEITO, opcoesSelecionadas: [marcadores.leiteMaterno] },
    { pergunta: PerguntaEnum.MINGAU, opcoesSelecionadas: [marcadores.mingau] },
    { pergunta: PerguntaEnum.AGUA_CHA, opcoesSelecionadas: [marcadores.aguaOuCha] },
    { pergunta: PerguntaEnum.LEITE_VACA, opcoesSelecionadas: [marcadores.leiteDeVaca] },
    { pergunta: PerguntaEnum.FORMULA_INFANTIL, opcoesSelecionadas: [marcadores.formulaInfantil] },
    { pergunta: PerguntaEnum.SUCO_FRUTA, opcoesSelecionadas: [marcadores.sucoDeFruta] },
    { pergunta: PerguntaEnum.FRUTA, opcoesSelecionadas: [marcadores.fruta] },
    { pergunta: PerguntaEnum.COMIDA_SAL, opcoesSelecionadas: [marcadores.comidaDeSal] },
    { pergunta: PerguntaEnum.OUTROS_ALIMENTOS_BEBIDAS, opcoesSelecionadas: [marcadores.outros] },
  ],
})

const convertMCASeisAVinteETresMeses = (
  marcadores: MCASeisAVinteETresMesesPanelModel,
  faixaEtaria: FaixaEtariaConsumoAlimentar
): MarcadoresConsumoAlimentarInput => ({
  id: marcadores.editingId,
  faixaEtaria: faixaEtaria,
  respostas: [
    { pergunta: PerguntaEnum.CRIANCA_ONTEM_TOMOU_LEITE_PEITO_2, opcoesSelecionadas: [marcadores.leiteMaterno] },
    {
      pergunta: PerguntaEnum.ONTEM_CRIANCA_COMEU_FRUTA_INTEIRA_PEDACO_AMASSADA,
      opcoesSelecionadas: [marcadores.fruta],
    },
    { pergunta: PerguntaEnum.SE_SIM_QUANTAS_VEZES_FRUTA, opcoesSelecionadas: [marcadores.quantidadeRefeicoesFruta] },
    { pergunta: PerguntaEnum.ONTEM_CRIANCA_COMEU_COMIDA_SAL, opcoesSelecionadas: [marcadores.comidaDeSal] },
    {
      pergunta: PerguntaEnum.SE_SIM_QUANTAS_VEZES_COMIDA_SAL,
      opcoesSelecionadas: [marcadores.quantidadeRefeicoesComidaDeSal],
    },
    {
      pergunta: PerguntaEnum.SE_SIM_ESSA_COMIDA_FOI_OFERECIDA,
      opcoesSelecionadas: [marcadores.consistenciaAlimentoComidaDeSal],
    },
    { pergunta: PerguntaEnum.OUTRO_LEITE_NAO_LEITE_PEITO, opcoesSelecionadas: [marcadores.leiteDeVaca] },
    { pergunta: PerguntaEnum.MINGAU_COM_LEITE, opcoesSelecionadas: [marcadores.mingau] },
    { pergunta: PerguntaEnum.IOGURTE, opcoesSelecionadas: [marcadores.iogurte] },
    { pergunta: PerguntaEnum.LEGUMES, opcoesSelecionadas: [marcadores.legumes] },
    { pergunta: PerguntaEnum.VEGETAL_OU_FRUTA_ALARANJADA, opcoesSelecionadas: [marcadores.vegetais] },
    { pergunta: PerguntaEnum.VERDURA_FOLHA, opcoesSelecionadas: [marcadores.verduraDeFolha] },
    { pergunta: PerguntaEnum.CARNE, opcoesSelecionadas: [marcadores.carne] },
    { pergunta: PerguntaEnum.FIGADO, opcoesSelecionadas: [marcadores.figado] },
    { pergunta: PerguntaEnum.FEIJAO_6MESES_2ANOS, opcoesSelecionadas: [marcadores.feijao] },
    { pergunta: PerguntaEnum.ARROZ_BATATA_INHAME_AIPIM, opcoesSelecionadas: [marcadores.amidos] },
    { pergunta: PerguntaEnum.HAMBURGUER_E_OU_EMBUTIDOS_6MESES_2ANOS, opcoesSelecionadas: [marcadores.embutidos] },
    { pergunta: PerguntaEnum.BEBIDAS_ADOCADAS_6MESES_2ANOS, opcoesSelecionadas: [marcadores.bebidasComAcucar] },
    {
      pergunta: PerguntaEnum.MACARRAO_INSTANTANEO_SALGADINHOS_PACOTES_6MESES_2ANOS,
      opcoesSelecionadas: [marcadores.macarraoInstantaneo],
    },
    {
      pergunta: PerguntaEnum.BISCOITO_RECHEADO_DOCES_GULOSEIMAS_6MESES_2ANOS,
      opcoesSelecionadas: [marcadores.guloseimas],
    },
  ].filter((resposta) => resposta.opcoesSelecionadas.every((op) => !!op)),
})

const convertMCADoisAnosOuMais = (
  marcadores: MCADoisAnosOuMaisPanelModel,
  faixaEtaria: FaixaEtariaConsumoAlimentar
): MarcadoresConsumoAlimentarInput => ({
  id: marcadores.editingId,
  faixaEtaria: faixaEtaria,
  respostas: [
    {
      pergunta: PerguntaEnum.VOCE_COSTUME_REALIZAR_REFEICOES_ASSISTINDO_TV,
      opcoesSelecionadas: [marcadores.refeicoesAssistindoTV],
    },
    { pergunta: PerguntaEnum.QUAIS_REFEICOES_VOCE_FEZ_LONGO_DIA, opcoesSelecionadas: marcadores.refeicoesPorDia },
    { pergunta: PerguntaEnum.FEIJAO_MAIOR_2ANOS, opcoesSelecionadas: [marcadores.feijao] },
    { pergunta: PerguntaEnum.FRUTAS_FRESCAS, opcoesSelecionadas: [marcadores.frutas] },
    { pergunta: PerguntaEnum.VERDURAS_LEGUMES, opcoesSelecionadas: [marcadores.legumes] },
    { pergunta: PerguntaEnum.HAMBURGUER_E_OU_EMBUTIDOS_MAIOR_2ANOS, opcoesSelecionadas: [marcadores.embutidos] },
    { pergunta: PerguntaEnum.BEBIDAS_ADOCADAS_MAIOR_2ANOS, opcoesSelecionadas: [marcadores.bebidasComAcucar] },
    {
      pergunta: PerguntaEnum.MACARRAO_INSTANTANEO_SALGADINHO_BISCOITO_MAIOR_2ANOS,
      opcoesSelecionadas: [marcadores.macarraoInstantaneo],
    },
    {
      pergunta: PerguntaEnum.BISCOITO_RECHEADO_DOCES_GULOSEIMAS_MAIOR_2ANOS,
      opcoesSelecionadas: [marcadores.guloseimas],
    },
  ],
})

const convertMCAMenoresDeSeisMesesToForm = (
  input: Dictionary<RespostaMarcadoresConsumoAlimentar>,
  editingId: ID,
  isRetificacao: boolean
): MCAMenoresDeSeisMesesPanelModel => {
  return {
    ...(!isRetificacao && { editingId: editingId }),
    leiteMaterno: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.CRIANCA_ONTEM_TOMOU_LEITE_PEITO]),
    mingau: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.MINGAU]),
    aguaOuCha: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.AGUA_CHA]),
    leiteDeVaca: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.LEITE_VACA]),
    formulaInfantil: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.FORMULA_INFANTIL]),
    sucoDeFruta: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.SUCO_FRUTA]),
    fruta: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.FRUTA]),
    comidaDeSal: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.COMIDA_SAL]),
    outros: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.OUTROS_ALIMENTOS_BEBIDAS]),
  }
}

const convertMCASeisAVinteETresMesesToForm = (
  input: Dictionary<RespostaMarcadoresConsumoAlimentar>,
  editingId: ID,
  isRetificacao: boolean
): MCASeisAVinteETresMesesPanelModel => {
  return {
    ...(!isRetificacao && { editingId: editingId }),
    leiteMaterno: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.CRIANCA_ONTEM_TOMOU_LEITE_PEITO_2]),
    fruta: getPrimeiraOpcaoSelecionada(
      input[PerguntasQuestionarioEnum.ONTEM_CRIANCA_COMEU_FRUTA_INTEIRA_PEDACO_AMASSADA]
    ),
    quantidadeRefeicoesFruta: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.SE_SIM_QUANTAS_VEZES_FRUTA]),
    comidaDeSal: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.ONTEM_CRIANCA_COMEU_COMIDA_SAL]),
    quantidadeRefeicoesComidaDeSal: getPrimeiraOpcaoSelecionada(
      input[PerguntasQuestionarioEnum.SE_SIM_QUANTAS_VEZES_COMIDA_SAL]
    ),
    consistenciaAlimentoComidaDeSal: getPrimeiraOpcaoSelecionada(
      input[PerguntasQuestionarioEnum.SE_SIM_ESSA_COMIDA_FOI_OFERECIDA]
    ),
    leiteDeVaca: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.OUTRO_LEITE_NAO_LEITE_PEITO]),
    mingau: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.MINGAU_COM_LEITE]),
    iogurte: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.IOGURTE]),
    legumes: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.LEGUMES]),
    vegetais: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.VEGETAL_OU_FRUTA_ALARANJADA]),
    verduraDeFolha: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.VERDURA_FOLHA]),
    carne: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.CARNE]),
    figado: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.FIGADO]),
    feijao: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.FEIJAO_6MESES_2ANOS]),
    amidos: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.ARROZ_BATATA_INHAME_AIPIM]),
    embutidos: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.HAMBURGUER_E_OU_EMBUTIDOS_6MESES_2ANOS]),
    bebidasComAcucar: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.BEBIDAS_ADOCADAS_6MESES_2ANOS]),
    macarraoInstantaneo: getPrimeiraOpcaoSelecionada(
      input[PerguntasQuestionarioEnum.MACARRAO_INSTANTANEO_SALGADINHOS_PACOTES_6MESES_2ANOS]
    ),
    guloseimas: getPrimeiraOpcaoSelecionada(
      input[PerguntasQuestionarioEnum.BISCOITO_RECHEADO_DOCES_GULOSEIMAS_6MESES_2ANOS]
    ),
  }
}

const convertMCADoisAnosOuMaisToForm = (
  input: Dictionary<RespostaMarcadoresConsumoAlimentar>,
  editingId: ID,
  isRetificacao: boolean
): MCADoisAnosOuMaisPanelModel => {
  return {
    ...(!isRetificacao && { editingId: editingId }),
    refeicoesAssistindoTV: getPrimeiraOpcaoSelecionada(
      input[PerguntasQuestionarioEnum.VOCE_COSTUME_REALIZAR_REFEICOES_ASSISTINDO_TV]
    ),
    refeicoesPorDia: input[PerguntasQuestionarioEnum.QUAIS_REFEICOES_VOCE_FEZ_LONGO_DIA].opcoesSelecionadas,
    feijao: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.FEIJAO_MAIOR_2ANOS]),
    frutas: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.FRUTAS_FRESCAS]),
    legumes: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.VERDURAS_LEGUMES]),
    embutidos: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.HAMBURGUER_E_OU_EMBUTIDOS_MAIOR_2ANOS]),
    bebidasComAcucar: getPrimeiraOpcaoSelecionada(input[PerguntasQuestionarioEnum.BEBIDAS_ADOCADAS_MAIOR_2ANOS]),
    macarraoInstantaneo: getPrimeiraOpcaoSelecionada(
      input[PerguntasQuestionarioEnum.MACARRAO_INSTANTANEO_SALGADINHO_BISCOITO_MAIOR_2ANOS]
    ),
    guloseimas: getPrimeiraOpcaoSelecionada(
      input[PerguntasQuestionarioEnum.BISCOITO_RECHEADO_DOCES_GULOSEIMAS_MAIOR_2ANOS]
    ),
  }
}

export const convertMCAQueryToForm = (
  input: MarcadorConsumoAlimentar,
  isRetificacao: boolean = false
): MarcadoresConsumoPanelModel => {
  const respostasMCA = keyBy(input?.respostas, (resposta) => resposta.pergunta)

  switch (input?.categoria) {
    case FaixaEtariaConsumoAlimentar.MENOR_SEIS_MESES:
      return convertMCAMenoresDeSeisMesesToForm(respostasMCA, input.id, isRetificacao)
    case FaixaEtariaConsumoAlimentar.MAIOR_SEIS_MESES_MENOR_DOIS_ANOS:
      return convertMCASeisAVinteETresMesesToForm(respostasMCA, input.id, isRetificacao)
    case FaixaEtariaConsumoAlimentar.MAIOR_DOIS_ANOS:
      return convertMCADoisAnosOuMaisToForm(respostasMCA, input.id, isRetificacao)
  }
}
