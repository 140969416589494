import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { Calculation } from 'final-form-calculate'
import { TipoAtendimentoEnum, TipoAtendimentoProfissional, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { ProcedimentosAutomaticosAtendimentoIndividual } from 'types/enums'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'
import { v4 as uuidv4 } from 'uuid'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { ProcedimentoAutomatico } from 'view/atendimentos/model-atendimento'
import { calculateProcedimentoAutomaticoToAdd } from 'view/atendimentos/utils/util-procedimentoAutomatico'

import { FinalizacaoAtendimentoFormModel } from '../finalizacao'
import {
  findProcedimentosAutomaticosByCodigo,
  getProcedimentosWithNewProced,
} from '../finalizacao/calculator/calculator-individual'
import { ManterCidadaoLista } from '../finalizacao/components/DesfechoAtendimentoPanel'
import { ObjetivoFormModel } from '../objetivo'
import { FormAtivoObjetivoEnum } from '../objetivo/components/SwitchButtonObjetivoForm'
import { AvaliacaoDesenvolvimentoModel, PuericulturaModel } from '../objetivo/puericultura/model'
import { createPlanoIvcfCalculations } from './calculator-plano-ivcf'
import { createPlanoMedicoesCalculations, HasAccessProcedimentos } from './calculator-plano-medicoes'
import { createPlanoOdontoCalculations } from './calculator-plano-odonto'
import { ProcedimentoPlanoModel } from './components/ProcedimentoPlanoField'
import { PlanoFormModel } from './PlanoForm'

export interface CreatePlanoCalculationsProps {
  plano: MetaPath<PlanoFormModel>
  objetivo: MetaPath<ObjetivoFormModel>
  formAtivoPath: MetaPath<FormAtivoObjetivoEnum>
  medicoes: MetaPath<MedicoesPanelModel>
  puericultura: MetaPath<PuericulturaModel>
  finalizacao: MetaPath<FinalizacaoAtendimentoFormModel>
  procedimentosAutomaticos: ProcedimentoAutomatico[]
  intervencoesProcedimentosAutomaticos: ProcedimentoSelectModel[]
  idadeCidadaoEmAnos: number
  hasAccessProcedimentos: HasAccessProcedimentos
  tipoAtendimentoProfissional: TipoAtendimentoProfissional
  tipoEstabelecimento: TipoEstabelecimentoEnum
  cbo2002: string
  isRegistroTardio: boolean
}

export const createPlanoCalculations = (props: CreatePlanoCalculationsProps): Calculation[] => {
  const {
    plano,
    objetivo,
    formAtivoPath,
    medicoes,
    puericultura,
    finalizacao,
    procedimentosAutomaticos,
    intervencoesProcedimentosAutomaticos,
    idadeCidadaoEmAnos,
    hasAccessProcedimentos,
    tipoAtendimentoProfissional,
    tipoEstabelecimento,
    cbo2002,
    isRegistroTardio,
  } = props
  return [
    createPlanoIvcfCalculations(plano, objetivo, intervencoesProcedimentosAutomaticos),
    ...createPlanoOdontoCalculations(plano.odontologia.periodontia),
    ...createPlanoMedicoesCalculations(plano, objetivo.medicoes, procedimentosAutomaticos, hasAccessProcedimentos),
    {
      field: [
        formAtivoPath.absolutePath(),
        medicoes.peso.absolutePath(),
        medicoes.altura.absolutePath(),
        medicoes.perimetroCefalico.absolutePath(),
      ],
      updates: {
        [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (
          _,
          allValues: SoapState,
          prevValues: SoapState
        ) => {
          const formAtivo = allValues.objetivo?.atendimentosEspecificos?.formAtivo
          let listaProcedimentos = allValues.plano?.intervencoesProcedimentos?.procedimentos ?? []

          const procedToAdd =
            formAtivo === FormAtivoObjetivoEnum.PUERICULTURA && hasAccessProcedimentos.crescimentoCrianca
              ? procedToAddAtendimentoPuericultura(allValues, idadeCidadaoEmAnos)
              : procedToAddAtendimentoIndividual(allValues)

          if (procedToAdd) {
            listaProcedimentos = addProcedimentoAutomatico(allValues, procedimentosAutomaticos, procedToAdd)
          }

          const wasIvcf = prevValues.objetivo?.atendimentosEspecificos?.formAtivo === FormAtivoObjetivoEnum.IVCF
          const isNotIvcfNow = formAtivo !== FormAtivoObjetivoEnum.IVCF
          if (wasIvcf && isNotIvcfNow) {
            listaProcedimentos = removeIntervencoesProcedimentoAutomatico(
              allValues.plano?.intervencoesProcedimentos?.procedimentos,
              ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_MULTIDIMENSIONAL_PESSOA_IDOSA
            )
          }

          procedimentosToRemove
            .filter((proced) => proced !== procedToAdd)
            .forEach((procedimento: ProcedimentosAutomaticosAtendimentoIndividual) => {
              listaProcedimentos = removeProcedimentoAutomatico(listaProcedimentos, procedimento)
            })

          return listaProcedimentos
        },
      },
    },
    {
      field: [puericultura.absolutePath()],
      updates: {
        [plano.intervencoesProcedimentos.procedimentos.absolutePath()]: (
          values: PuericulturaModel,
          allValues: SoapState
        ) => {
          const procedToAdd =
            hasAccessProcedimentos.desenvolvimentoCrianca && procedToAddDesenvolvimentoPuericultura(values)

          const listaProcedimentos = allValues.plano?.intervencoesProcedimentos?.procedimentos || []
          if (procedToAdd) {
            return addProcedimentoAutomatico(allValues, procedimentosAutomaticos, procedToAdd)
          } else {
            return removeProcedimentoAutomatico(
              listaProcedimentos,
              ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_DESENVOLVIMENTO_PUERICULTURA
            )
          }
        },
      },
    },
    {
      field: plano.startObservacao.absolutePath(),
      updates: {
        [finalizacao.procedimentosAdministrativos.absolutePath()]: (_, allValues: SoapState) => {
          const isUpa = tipoEstabelecimento === TipoEstabelecimentoEnum.UPA

          if (isUpa) {
            const procedimentoAutomatico = calculateProcedimentoAutomaticoToAdd({
              tipoAtendimentoProfissional,
              tipoEstabelecimento,
              tipoAtendimento: allValues.finalizacao.tipoAtendimento,
              cbo2002,
              isProfResponsavelObservacao: allValues.plano?.startObservacao,
            })

            return getProcedimentosWithNewProced(
              allValues.finalizacao.procedimentosAdministrativos,
              findProcedimentosAutomaticosByCodigo(procedimentosAutomaticos, [procedimentoAutomatico])
            )
          }
          return allValues.finalizacao.procedimentosAdministrativos
        },
        [finalizacao.tipoAtendimento.absolutePath()]: (startObservacao: boolean, _, prevValues: SoapState) => {
          const isUpa = tipoEstabelecimento === TipoEstabelecimentoEnum.UPA
          if (startObservacao && isUpa) {
            return TipoAtendimentoEnum.URGENCIA
          } else {
            return prevValues?.finalizacao?.tipoAtendimento
          }
        },
        [finalizacao.desfechoAtendimento.manterCidadaoLista.absolutePath()]: (
          startObservacao: boolean,
          _,
          prevValue: SoapState
        ) => {
          if (startObservacao && !isRegistroTardio) {
            return ManterCidadaoLista.SIM
          }
          return prevValue?.finalizacao?.desfechoAtendimento?.manterCidadaoLista
        },
      },
    },
  ]
}

const procedToAddAtendimentoIndividual = (allValues: SoapState) => {
  const pesoPreenchido = allValues.objetivo?.medicoes?.peso
  const alturaPreenchida = allValues.objetivo?.medicoes?.altura

  return pesoPreenchido && alturaPreenchida
    ? ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_ANTROPOMETRICA
    : pesoPreenchido
    ? ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_PESO
    : alturaPreenchida
    ? ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_ALTURA
    : null
}

const procedToAddAtendimentoPuericultura = (allValues: SoapState, idadeCidadao: number) => {
  const pesoPreenchido = allValues.objetivo?.medicoes?.peso
  const alturaPreenchida = allValues.objetivo?.medicoes?.altura
  const perimetroCefPreenchido = allValues.objetivo?.medicoes?.perimetroCefalico

  return pesoPreenchido && !alturaPreenchida
    ? ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_PESO
    : !pesoPreenchido && alturaPreenchida
    ? ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_ALTURA
    : pesoPreenchido && alturaPreenchida
    ? idadeCidadao >= 2 || (idadeCidadao < 2 && perimetroCefPreenchido)
      ? ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_CRESCIMENTO_PUERICULTURA
      : ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_ANTROPOMETRICA
    : null
}

const procedToAddDesenvolvimentoPuericultura = (puericultura: PuericulturaModel) => {
  const alteracoesFenotipicas = puericultura?.alteracoesFenotipicas?.alteracoesFenotipicas
  const fatoresRisco = puericultura?.fatoresRisco?.fatoresRisco

  const hasChangedMarcos = (): boolean => {
    const marcosDesenvolvimento = puericultura?.marcosDesenvolvimento

    return (
      marcosDesenvolvimento &&
      Object.values(marcosDesenvolvimento.marcosRecord)?.some((marcoGrupo) => hasChanged(marcoGrupo))
    )
  }

  const hasChanged = (avaliacao: AvaliacaoDesenvolvimentoModel[]) =>
    avaliacao?.some((item) => item.statusButton !== item.status)

  return hasChanged(alteracoesFenotipicas) || hasChanged(fatoresRisco) || hasChangedMarcos()
    ? ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_DESENVOLVIMENTO_PUERICULTURA
    : null
}

export const addProcedimentoAutomatico = (
  allValues: SoapState,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  codigoProcedimentoToAdd: ProcedimentosAutomaticosAtendimentoIndividual
) => {
  const procedimento: ProcedimentoPlanoModel = {
    _id: uuidv4(),
    procedimento: procedimentosAutomaticos.find((item) => item.codigo === codigoProcedimentoToAdd),
    automatico: true,
  }

  const values = allValues.plano?.intervencoesProcedimentos?.procedimentos || []

  if (Array.isArray(values) && procedimento.procedimento) {
    if (!values.find((p) => p.procedimento.id === procedimento.procedimento.id)) {
      values.push(procedimento)
    }
  }

  return values
}

export const removeProcedimentoAutomatico = (
  listaProcedimentos: ProcedimentoPlanoModel | ProcedimentoPlanoModel[],
  procedimentoAutomaticoEnum: ProcedimentosAutomaticosAtendimentoIndividual
) => {
  if (Array.isArray(listaProcedimentos)) {
    const newListaProcedimentos = listaProcedimentos.filter((p) =>
      p.automatico ? p.procedimento.codigo !== procedimentoAutomaticoEnum : p
    )

    return newListaProcedimentos
  }
}

const removeIntervencoesProcedimentoAutomatico = (
  intervencoesProcedimentos: ProcedimentoPlanoModel[],
  codigoProcedimento: String
): ProcedimentoPlanoModel[] => {
  if (isUndefinedOrNull(intervencoesProcedimentos)) return null
  return intervencoesProcedimentos.filter(
    (item) => !(item.automatico && item.procedimento.codigo === codigoProcedimento)
  )
}

const procedimentosToRemove = [
  ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_PESO,
  ProcedimentosAutomaticosAtendimentoIndividual.MEDICAO_DE_ALTURA,
  ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_ANTROPOMETRICA,
  ProcedimentosAutomaticosAtendimentoIndividual.AVALIACAO_CRESCIMENTO_PUERICULTURA,
]
