import { PageLoading } from 'components/loading'
import { useAtendimentoViewQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useParams } from 'react-router'

import { AtendimentoBaseView } from './AtendimentoBaseView'

export interface AtendimentoViewParams {
  atendimentoId: string
}

export function AtendimentoView() {
  const { atendimentoId } = useParams<AtendimentoViewParams>()

  const {
    data: { atendimento },
    loading,
  } = useAtendimentoViewQuery({
    variables: { id: atendimentoId },
  })

  if (loading) {
    return <PageLoading message='Carregando atendimento...' />
  } else {
    return <AtendimentoBaseView atendimento={atendimento} />
  }
}
