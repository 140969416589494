import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'

export class AnexoArquivos {
  salvarArquivo(formData: FormData, config: AxiosRequestConfig): AxiosPromise<number> {
    return axios.post(`/api/atendimento/anexarArquivo`, formData, config)
  }

  baixarArquivo(arquivoId: ID): AxiosPromise<Blob> {
    return axios.get(`/api/atendimento/baixarArquivo`, {
      params: { id: arquivoId },
      responseType: 'blob',
    })
  }
}
