import { SelectHelperMenuItem, Text, useLocale, VFlow } from 'bold-ui'
import { AsyncSelectFieldProps, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { SelectField } from 'components/form/final-form'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { LotacoesCuidadoCompartilhadoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  LotacoesCuidadoCompartilhadoSelectFieldQuery,
  LotacoesCuidadoCompartilhadoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'
import { formatNomeProfissional } from 'view/profissional/utils/utils-profissional'

export type LotacoesCuidadoCompartilhadoSelectModel = LotacoesCuidadoCompartilhadoSelectFieldQuery['lotacoesCuidadoCompartilhado']['content'][0]

export interface LotacoesCuidadoCompartilhadoSelectFieldProps
  extends AsyncSelectFieldProps<LotacoesCuidadoCompartilhadoSelectModel> {
  unidadeSaudeId: ID
  cboId?: ID
  excludeProfissionaisIds?: ID[]
  equipeSolicitanteId?: ID
  equipeExecutanteId?: ID
  isTrocaExecutante?: boolean
  loadItemsOnOpen?: boolean
  showUnidadeSaude?: boolean
}

export function LotacoesCuidadoCompartilhadoSelectField(props: LotacoesCuidadoCompartilhadoSelectFieldProps) {
  const {
    unidadeSaudeId,
    cboId,
    excludeProfissionaisIds,
    equipeSolicitanteId,
    equipeExecutanteId,
    isTrocaExecutante = false,
    loadItemsOnOpen = true,
    showUnidadeSaude = false,
    ...rest
  } = props

  const locale = useLocale()

  const { skipping, selectProps } = useAsyncQuerySelect<
    LotacoesCuidadoCompartilhadoSelectModel,
    LotacoesCuidadoCompartilhadoSelectFieldQuery,
    LotacoesCuidadoCompartilhadoSelectFieldQueryVariables
  >({
    query: LotacoesCuidadoCompartilhadoSelectFieldDocument,
    extractItems: (data) => data && data.lotacoesCuidadoCompartilhado && data.lotacoesCuidadoCompartilhado.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        estabelecimentoId: unidadeSaudeId,
        cboId,
        excludeProfissionaisIds,
        equipeSolicitanteId,
        equipeExecutanteId,
        isTrocaExecutante,
        pageParams: { unpaged: true },
      },
    }),
    skip: (inputString) => props.disabled || (!loadItemsOnOpen ? inputString?.trim()?.length < 2 : null),
    debounceTime: 500,
  })

  return (
    <SelectField<LotacoesCuidadoCompartilhadoSelectModel>
      itemToString={itemToString}
      renderItem={renderItem(showUnidadeSaude)}
      components={
        !loadItemsOnOpen && {
          EmptyItem: () => (
            <SelectHelperMenuItem>
              {skipping ? 'Digite para buscar um profissional.' : locale.select.emptyItem}
            </SelectHelperMenuItem>
          ),
        }
      }
      {...selectProps}
      {...rest}
    />
  )
}

const itemToString = (item: LotacoesCuidadoCompartilhadoSelectModel) => item && item.profissional.nome

const renderItem = (showUnidadeSaude: boolean) => (item: LotacoesCuidadoCompartilhadoSelectModel) => {
  return (
    <VFlow vSpacing={0}>
      <Text fontWeight='bold'>
        {formatNomeProfissional(item.profissional)} - <Cpf value={item.profissional.cpf} />
      </Text>
      <HLabel title='CBO '>{`${item.cbo.nome} - ${item.cbo.cbo2002}`}</HLabel>
      <HLabel title='INE '> {(item.equipe && `${item.equipe.nome} - ${item.equipe.ine}`) || 'Sem equipe'}</HLabel>
      {showUnidadeSaude && (
        <HLabel title='Unidade de saúde '>
          {item.unidadeSaude.nome} - {item.unidadeSaude.cnes}
        </HLabel>
      )}
    </VFlow>
  )
}
