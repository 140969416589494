/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { useMemo } from 'react'

export function MChatPendingIcon() {
  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme), [theme])

  return (
    <div css={classes.icon}>
      <div css={classes.innerLine}></div>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  icon: css`
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: ${theme.pallete.gray.c80};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  innerLine: css`
    width: 0.5rem;
    height: 0.125rem;
    background-color: ${theme.pallete.gray.c100};
  `,
})
