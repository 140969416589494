import { isBuffer, isEqualWith } from 'lodash'

import { isUndefinedOrNull } from './checks'

/**
 * Verifica se todos os valores do objeto estão nulos, vazios ou falsos em busca profunda
 * @param obj Objeto a ser verificado
 */
export function isObjectDeepFalsy(obj: Object): boolean {
  return !obj || (typeof obj === 'object' && !Object.values(obj).some((x) => !isObjectDeepFalsy(x)))
}

/**
 * Verifica se todos os valores do objeto estão nulos ou vazios
 * @param obj Objeto a ser verificado
 */
export function isObjectDeepEmpty(obj: Object): boolean {
  return isUndefinedOrNull(obj) || (typeof obj === 'object' && !Object.values(obj).some((x) => !isObjectDeepEmpty(x)))
}

function hasSameId(a: any, b: any) {
  if (!isUndefinedOrNull(a?.id) && a.id === b?.id) {
    return true
  }
}

/**
 * Verifica se ambos os objetos são iguais em busca profunda, considerando árvores de mesmo `id` como iguais
 * @param a Objeto a ser comparado
 * @param b Objeto a ser comparado
 */
export function isObjectDeepEqualById(a: Object, b: Object) {
  return hasSameId(a, b) || isEqualWith(a, b, hasSameId)
}

/**
 * Retorna um objeto com as keys "planificadas"
 * Adaptado de: https://github.com/hughsk/flat
 * @param target Objeto a ser "planificado"

 */
export function flatten(target: any) {
  const delimiter = '.'
  const output = {}

  function step(object: any, prev?: string, currentDepth: number = 1) {
    Object.keys(object).forEach(function (key) {
      const value = object[key]
      const type = Object.prototype.toString.call(value)
      const isObject = type === '[object Object]' || type === '[object Array]'

      const newKey = prev ? prev + delimiter + key : key

      if (!isBuffer(value) && isObject && Object.keys(value).length) {
        return step(value, newKey, currentDepth + 1)
      }

      output[newKey] = value
    })
  }

  step(target)

  return output
}
