import { Button, DataTable, Heading, Icon, TableFooter, TableFooterProps, Tooltip } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { usePagedTableProps } from 'components/table'
import { useArquivosTemporariosPagedQuery } from 'graphql/hooks.generated'
import {
  ArquivoTemporario,
  ArquivoTemporarioIdentificadorEnum,
  ArquivoTemporarioStatusEnum,
  PageParams,
} from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { useServerTime } from 'hooks/useServerTime'
import React, { useEffect, useState } from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { downloadArquivo, formatRelatorioFileName } from 'view/arquivo/downloadsArquivo'
import { ArquivoTemporarioModel, relatoriosRecentesRecord } from 'view/arquivo/model-arquivo'

import StatusArquivoLabel from '../../../arquivo/StatusArquivoTemporarioLabel'

const identificadorModuloArquivo = ArquivoTemporarioIdentificadorEnum.RELATORIO_RNDS

const getTooltipDownloadRndsButton = (status: ArquivoTemporarioStatusEnum) => {
  switch (status) {
    case ArquivoTemporarioStatusEnum.PRONTO:
      return 'Exportar CSV'
    case ArquivoTemporarioStatusEnum.EM_PROCESSAMENTO:
      return 'Não é possível baixar um relatório em processamento'
    case ArquivoTemporarioStatusEnum.ERRO_RELATORIO_SEM_DADOS:
      return 'Não existem registros para geração do relatório'
    case ArquivoTemporarioStatusEnum.ERRO:
    default:
      return 'Não é possível baixar um relatório com erros'
  }
}

export function RelatoriosRndsTable() {
  const {
    data: {
      profissional: { usuario },
    },
  } = useSession()
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()

  const { atmospherePath, fileName } = relatoriosRecentesRecord[identificadorModuloArquivo]

  const fileNameFormatted = formatRelatorioFileName(fileName, now)

  const handleDownload = (item: ArquivoTemporario) => {
    if (item.status === ArquivoTemporarioStatusEnum.PRONTO)
      downloadArquivo(item.id, fileNameFormatted, item.formato.toLocaleLowerCase())
  }

  const [pageParams, setPageParams] = useState<{ pageParams: PageParams }>({
    pageParams: {
      page: 0,
      size: 5,
    },
  })
  const [relatorios, setRelatorios] = useState<ArquivoTemporario[]>([])

  const {
    data: { arquivosTemporarios },
    loading,
    refetch,
  } = useArquivosTemporariosPagedQuery({
    variables: {
      input: {
        arquivoIdentificador: identificadorModuloArquivo,
        pageParams: pageParams.pageParams,
      },
    },
    fetchPolicy: 'cache-and-network',
  })

  useAtmosphere<boolean>({
    topic: `${atmospherePath}/${usuario.id}`,
    onMessage: () => {
      refetch()
    },
  })

  useEffect(() => {
    if (arquivosTemporarios) {
      setRelatorios(arquivosTemporarios.content)
    }
  }, [arquivosTemporarios])

  useEffect(() => {
    const interval = setInterval(() => {
      const arquivosLoading = relatorios.filter(
        (arquivo) => arquivo.status === ArquivoTemporarioStatusEnum.EM_PROCESSAMENTO
      )

      if (arquivosLoading.length > 0) {
        refetch()
      } else {
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval)
  }, [relatorios, refetch])

  const pagedTableProps = usePagedTableProps({ loading, result: arquivosTemporarios, onChange: setPageParams })
  const tableFooterProps: TableFooterProps = { pageSize: pagedTableProps.size, ...pagedTableProps }

  return (
    <>
      <Heading level={4}>Relatórios em processamento/processados (7 dias)</Heading>
      <DataTable<ArquivoTemporarioModel>
        loading={arquivosTemporarios?.content?.length > 0 ? false : loading}
        rows={relatorios ?? []}
        columns={[
          {
            name: 'dataRequisicao',
            header: 'Data da requisição',
            render: (item) => formatDateAndHoursMinutes(item.dataInicioGerado),
          },
          {
            name: 'tipo',
            header: 'Tipo',
            render: (item) => item.formato,
          },
          {
            name: 'statusProcessamento',
            header: 'Status de processamento',
            render: (item) => (
              <StatusArquivoLabel arquivoTemporarioStatus={item.status} isApenasUltimoRelatorio={false} />
            ),
          },
          {
            name: 'download',
            header: '',
            align: 'right',
            render: (item) => {
              return (
                <Tooltip text={getTooltipDownloadRndsButton(item.status)}>
                  <Button
                    type='button'
                    skin='ghost'
                    size='small'
                    disabled={item.status !== ArquivoTemporarioStatusEnum.PRONTO}
                    onClick={() => handleDownload(item)}
                  >
                    {item.status === ArquivoTemporarioStatusEnum.ERRO ? (
                      <Icon icon='exclamationTriangleOutline' />
                    ) : (
                      <Icon icon='download' />
                    )}
                  </Button>
                </Tooltip>
              )
            },
          },
        ]}
      />
      <TableFooter {...tableFooterProps} />
    </>
  )
}
