/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Stepper, StepperProps } from 'bold-ui'

export function VerticalStepper(props: StepperProps) {
  return (
    <Stepper
      {...props}
      style={css`
        flex-direction: column;
      `}
    />
  )
}
