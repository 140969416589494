import { createValidator, maxLength, required } from 'util/validation'

import { AnexoArquivoFormModel } from '../model-anexoArquivos'

export const validateAnexoArquivo = createValidator<AnexoArquivoFormModel>({
  arquivoId: [required],
  titulo: [required, maxLength(80)],
  categoria: [required],
  observacao: [maxLength(200)],
})
