/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormProps, FormRenderProps, SubmitButton, TextAreaField, TextField } from 'components/form'
import { metaPath } from 'util/metaPath'

import { ANEXO_ARQUIVOS_OBSERVACOES_PLACEHOLDER, AnexoArquivoFormModel } from '../../model-anexoArquivos'
import { CategoriaArquivoSelectField } from '../CategoriaArquivosSelectField'
import { validateAnexoArquivo } from '../validator-anexoArquivosForm'

const path = metaPath<AnexoArquivoFormModel>()

interface AnexoArquivosFormProps extends Pick<FormProps<AnexoArquivoFormModel>, 'initialValues'> {
  onSubmit(formValues: AnexoArquivoFormModel): void
  onCancel(): void
}

export function AnexoArquivosForm(props: AnexoArquivosFormProps) {
  const { onSubmit, onCancel, initialValues } = props

  const alert = useAlert()

  const handleSubmit = (values: AnexoArquivoFormModel) => {
    onSubmit(values)
    alert('success', 'Anexo de arquivo editado com sucesso')
  }

  const renderForm = (formProps: FormRenderProps<AnexoArquivoFormModel>) => {
    return (
      <Grid>
        <Cell size={6}>
          <TextField label='Título' name={path.titulo} required maxLength={80} />
        </Cell>
        <Cell size={6}>
          <CategoriaArquivoSelectField required name={path.categoria} label='Categoria do arquivo' />
        </Cell>
        <Cell size={12}>
          <TextAreaField
            label='Observações'
            name={path.observacao}
            maxLength={200}
            placeholder={ANEXO_ARQUIVOS_OBSERVACOES_PLACEHOLDER}
            resize='vertical'
            style={css`
              height: 5rem;
            `}
          />
        </Cell>
        <Cell size={12}>
          <HFlow justifyContent='flex-end'>
            <Button kind='normal' size='small' onClick={() => onCancel()}>
              Cancelar
            </Button>
            <SubmitButton type='button' kind='primary' size='small' handleSubmit={formProps.handleSubmit}>
              Salvar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return (
    <Form<AnexoArquivoFormModel>
      validate={validateAnexoArquivo}
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    />
  )
}
