/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, FormControl, Grid, HFlow, Icon, Tooltip } from 'bold-ui'
import { orange, pink } from 'bold-ui/lib/styles/colors'
import { CheckboxField, Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import { GarantiaAcessoSortEnum } from 'graphql/types.generated'
import { isEmpty, isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { filterCheckboxLabelRender } from 'view/atendimentos/list/filter/ListaAtendimentoFilterPopperForm'

import {
  GarantiaAcessoFilterModel,
  GarantiaAcessoOrigemRegistroEnum,
  GarantiaAcessoOrigemRegistroRecord,
} from '../model-garantiaAcesso'
import { GarantiaAcessoSortDropdown } from './GarantiaAcessoSortDropdown'
import { GarantiaAcessoTableFilterPopperForm } from './GarantiaAcessoTableFilterPopperForm'
import { GarantiaAcessoTableFilterTags } from './GarantiaAcessoTableFilterTags'

const path = metaPath<GarantiaAcessoFilterModel>()

interface GarantiaAcessoTableFilterViewProps {
  filter: GarantiaAcessoFilterModel
  onChange(values: GarantiaAcessoFilterModel): void
}

export default function GarantiaAcessoTableFilterView(props: GarantiaAcessoTableFilterViewProps) {
  const { filter, onChange } = props
  const [countAppliedFilter, setCountAppliedFilter] = useState(0)
  const [isDefaultFilter, setIsDefaultFilter] = useState(true)

  const renderPopper = (ctrl: PopperControls) => (
    <GarantiaAcessoTableFilterPopperForm
      isDefaultFilter={isDefaultFilter}
      initialValues={filter}
      onSubmit={onChange}
      onClear={handleClear}
      onClose={ctrl.close}
    />
  )

  useEffect(() => {
    const isFilterEqualDefaultFilter = (filter: GarantiaAcessoFilterModel): boolean => {
      return isEqual(
        {
          nomeCpfCns: filter.nomeCpfCns.trim(),
          lotacoes: filter.lotacoes,
          equipes: filter.equipes,
          tiposAtendimento: filter.tiposAtendimento,
          tiposServico: filter.tiposServico,
          origem: filter.origem.sort(),
          ordenacao: filter.ordenacao,
          periodo: filter.periodo,
        },
        defaultFilter
      )
    }

    setIsDefaultFilter(isFilterEqualDefaultFilter(filter))
  }, [filter])

  useEffect(() => {
    setCountAppliedFilter(
      (isEmpty(filter.lotacoes) ? 0 : 1) +
        (isEmpty(filter.equipes) ? 0 : 1) +
        (isEmpty(filter.tiposAtendimento) ? 0 : 1) +
        (filter.periodo?.endDate || filter.periodo?.startDate ? 1 : 0)
    )
  }, [filter])

  const handleChange = (formState: FormState<GarantiaAcessoFilterModel>) => {
    onChange({ ...formState.values })
  }

  const handleClear = () => onChange(defaultFilter)

  const renderForm = () => {
    return (
      <Grid gapVertical={0.1}>
        <FormDebouncedValueSpy onChange={handleChange} />
        <Cell size={3}>
          <TextField
            name={path.nomeCpfCns}
            label='Cidadão'
            placeholder='Pesquise por nome, CPF, CNS ou data de nascimento'
            style={styles.ellipsis}
          />
        </Cell>
        <Cell size={5}>
          <FormControl label='Origem'>
            <HFlow hSpacing={0.5} style={styles.checkOrigem}>
              <CheckboxField
                name={path.origem}
                label={filterCheckboxLabelRender(
                  GarantiaAcessoOrigemRegistroRecord[GarantiaAcessoOrigemRegistroEnum.GARANTIA_ACESSO],
                  styles.origemGarantiaAcessoLabel
                )}
                value={GarantiaAcessoOrigemRegistroEnum.GARANTIA_ACESSO}
              />

              <CheckboxField
                name={path.origem}
                label={filterCheckboxLabelRender(
                  GarantiaAcessoOrigemRegistroRecord.CUIDADO_COMPARTILHADO,
                  styles.origemCuidadoCompartilhadoLabel
                )}
                value={GarantiaAcessoOrigemRegistroEnum.CUIDADO_COMPARTILHADO}
              />
            </HFlow>
          </FormControl>
        </Cell>
        <Cell size={4} style={styles.cellOrdenacao}>
          <HFlow justifyContent='flex-end'>
            <GarantiaAcessoSortDropdown filter={filter} onChange={onChange} />
            <PopperButton
              kind='primary'
              size='small'
              skin='default'
              placement='bottom-end'
              renderPopper={renderPopper}
              focusOnOpen={false}
            >
              <Icon icon='filterOutline' />
              {'Filtros' + (countAppliedFilter > 0 ? ` (${countAppliedFilter})` : '')}
            </PopperButton>
          </HFlow>
        </Cell>
        <Cell size={12}>
          <HFlow alignItems='center' justifyContent='space-between' style={styles.tagsWrapper}>
            <GarantiaAcessoTableFilterTags filter={filter} />
            <Tooltip text='Redefinir filtros para o padrão'>
              <Button kind='primary' skin='outline' size='small' disabled={isDefaultFilter} onClick={handleClear}>
                <Icon style={styles.redoIcon} icon='redo' />
                Voltar para padrão
              </Button>
            </Tooltip>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return <Form<GarantiaAcessoFilterModel> initialValues={filter} render={renderForm} />
}

const defaultFilter: GarantiaAcessoFilterModel = {
  nomeCpfCns: '',
  lotacoes: [],
  equipes: [],
  tiposAtendimento: [],
  tiposServico: [],
  origem: [GarantiaAcessoOrigemRegistroEnum.CUIDADO_COMPARTILHADO, GarantiaAcessoOrigemRegistroEnum.GARANTIA_ACESSO],
  ordenacao: GarantiaAcessoSortEnum.CLASSIFICACAO_DE_PRIORIDADE,
  periodo: null,
}

const styles = {
  redoIcon: css`
    padding-right: 0.5rem;
  `,
  tagsWrapper: css`
    grid-auto-columns: minmax(0, auto);
    padding-top: 0.25rem;
  `,
  origemGarantiaAcessoLabel: css`
    background: ${orange.c40};
  `,
  origemCuidadoCompartilhadoLabel: css`
    background: ${pink.c40};
  `,
  checkOrigem: css`
    padding-top: 0.25rem;
  `,
  cellOrdenacao: css`
    padding-top: 1.5rem;
  `,
  ellipsis: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
}
