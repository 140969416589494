/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, Icon, Tag, Tooltip } from 'bold-ui'
import { FieldStatusEnum, ListFieldStatus } from 'components/form'
import { confirm } from 'components/modals/confirm'
import { TextStatus } from 'components/text/TextStatus'
import { noop } from 'lodash'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'
import { EditableRow } from 'view/atendimentos/detail/components/EditableList'

import { showCodigoProcedimento } from '../../objetivo/resultados-exames/util-resultadosExames'
import { ProcedimentoSigtapFieldModel } from './ProcedimentoSigtapField'

interface ProcedimentoSigtapRowProps {
  model: ProcedimentoSigtapFieldModel
  hideRemoveButton?: boolean
  onRemove?: (itemToRemove: ProcedimentoSigtapFieldModel) => void
  onReset?: (itemToReset: ProcedimentoSigtapFieldModel) => void
  status?: FieldStatusEnum
  showModifiedStatus?: boolean
}

export function ProcedimentoSigtapRow(props: ProcedimentoSigtapRowProps) {
  const { model, hideRemoveButton = false, onRemove = noop, onReset = noop, status, showModifiedStatus = false } = props

  const handleRemove = async () => {
    const confirmed =
      !showModifiedStatus ||
      (await confirm({
        title: `Deseja excluir o Procedimento administrativo (SIGTAP)?`,
        type: 'danger',
        confirmLabel: 'Excluir',
      })())
    if (confirmed) onRemove(model)
  }
  const handleReset = () => onReset(model)

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(model)

  return (
    <EditableRow editing={false}>
      <Grid style={styles.grid}>
        <Cell size={hideRemoveButton ? 12 : 10} alignSelf='center'>
          <TextStatus status={status}>{`${model.procedimento?.descricao} - ${showCodigoProcedimento(
            model.procedimento?.codigo
          )}`}</TextStatus>
          {model.isAutomatico && <Tag style={styles.tag}>Adicionado automaticamente</Tag>}
        </Cell>

        {!hideRemoveButton && (
          <Cell size={2} style={styles.cell}>
            {showModifiedStatus ? (
              <ListFieldStatus status={status} onReset={handleReset} onRemove={handleRemove} />
            ) : (
              <Tooltip text={tooltipText}>
                <Button size='small' skin='ghost' onClick={handleRemove} disabled={isEditable}>
                  <Icon icon='trashOutline' />
                </Button>
              </Tooltip>
            )}
          </Cell>
        )}
      </Grid>
    </EditableRow>
  )
}

const styles = {
  grid: css`
    margin: 0;
  `,
  cellText: css`
    align-items: center;
  `,
  tag: css`
    margin-left: 0.5rem;
  `,
  cell: css`
    align-self: center;
    padding: 0.25rem 0;
    text-align: right;
  `,
}
