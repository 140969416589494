import { QueryHookOptions, useApolloClient } from '@apollo/react-hooks'
import atmosphere from 'atmosphere.js'
import { useRecursosByAtorPapelIdQuery, useSessaoQuery } from 'graphql/hooks.generated'
import {
  AcessoCbo,
  Cbo,
  EstabelecimentoSaudeEnum,
  SessaoQuery,
  SessaoQueryVariables,
  TipoEstabelecimentoEnum,
  UnidadeSaude,
} from 'graphql/types.generated'
import { useCookieConfig } from 'hooks/useCookieConfig'
import { useCallback, useMemo, useState } from 'react'
import GruposCbo, { GrupoCbo } from 'types/GruposCbo'
import { grupoCboIncludesCbo } from 'types/NivelCbo'
import { Permission } from 'types/Permissions'
import { isUndefinedOrNull } from 'util/checks'
import { notify } from 'util/multitab'
import { KEY_RETIFICACAO_STORAGE } from 'view/retificacao-atendimento/useJustificativaRetificacaoStorage'

import { Authorization, EstabelecimentoCodigosMs, estabelecimentoRecord } from './model-authorization'
import { useLoginAnalytics } from './useLoginAnalytics'
import {
  isCboPermission,
  isEstabelecimentoPermission,
  isLotacaoOrEstagio,
  isPermission,
  isPermissionArray,
} from './useSessionUtils'

export type UseSessionOptions = QueryHookOptions<SessaoQuery, SessaoQueryVariables>

const removeJustificativasRetificacaoLocalStorage = () => {
  const { localStorage } = window
  Object.keys(localStorage).forEach((key) => {
    if (key.endsWith(KEY_RETIFICACAO_STORAGE)) {
      localStorage.removeItem(key)
    }
  })
}

const removeLocalStorageItems = () => {
  const { localStorage } = window
  const staticKeys = ['LOGIN', 'LOGOUT', 'PERFIL_SELECIONADO', 'RESET_SESSION', 'NEWS']

  staticKeys.forEach((key) => localStorage.removeItem(key))

  removeJustificativasRetificacaoLocalStorage()
}

export default (options: UseSessionOptions = {}) => {
  const client = useApolloClient()
  const { denyCookies } = useCookieConfig()

  const { data, refetch, loading: loadingSessao } = useSessaoQuery({
    fetchPolicy: 'cache-first',
    ...options,
  })

  const resetApolloCache = useCallback(async () => {
    await client.cache.reset()
  }, [client])

  const { remove: removeLoginAnalytics } = useLoginAnalytics()

  const afterLogout = useCallback(async () => {
    await resetApolloCache()
    atmosphere.unsubscribe()

    removeLocalStorageItems()

    removeLoginAnalytics()
    denyCookies()
  }, [resetApolloCache, removeLoginAnalytics, denyCookies])

  const logoutSuccess = useCallback(async () => {
    notify('LOGOUT')
    afterLogout()
  }, [afterLogout])

  const acesso = data.sessao?.acesso

  const [recursosSupervisor, setRecursosSupervisor] = useState<Set<string>>(new Set())

  useRecursosByAtorPapelIdQuery({
    variables: {
      atorPapelId: acesso?.__typename === 'Estagio' && acesso.lotacaoSupervisora.id,
    },
    skip: acesso?.__typename !== 'Estagio',
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setRecursosSupervisor(new Set(data?.recursosByAtorPapelId))
    },
  })

  const recursosSet = useMemo(() => new Set(data?.sessao?.recursos), [data])
  const hasAuthorization = useCallback((permission: Permission) => recursosSet.has(permission.uri), [recursosSet])
  const hasSupervisorAuthorization = useCallback((permission: Permission) => recursosSupervisor.has(permission.uri), [
    recursosSupervisor,
  ])

  const hasCboAuth = useCallback(
    (acessos: AcessoCbo[]) => {
      if (hasCbo(acesso)) {
        const sessionCbo: Cbo = acesso?.cbo
        const groups: GrupoCbo[] = GruposCbo.filter((grupoCbo: GrupoCbo) =>
          acessos.find((acesso) => acesso === grupoCbo.grupo)
        ).map((item) => item as GrupoCbo)

        return groups.some((item: GrupoCbo) => grupoCboIncludesCbo(item.cbos, sessionCbo.cbo2002))
      } else {
        return false
      }
    },
    [acesso]
  )

  const hasEstabelecimentoAuth = useCallback(
    (acessos: EstabelecimentoSaudeEnum[]) => {
      if (hasEstabelecimento(acesso)) {
        const sessionUnidadeSaude: UnidadeSaude = acesso?.unidadeSaude
        const groups = Object.entries(estabelecimentoRecord)
          .filter(([estabelecimento, _]) => acessos.find((acesso) => acesso === estabelecimento))
          .map(([_, codigos]) => codigos)

        return groups.some(
          (item: EstabelecimentoCodigosMs) =>
            item.tipoCoMs === sessionUnidadeSaude.tipo.codigoMs &&
            (isUndefinedOrNull(item.subtipoCoMs) === isUndefinedOrNull(sessionUnidadeSaude.subtipo?.codigoMs) ||
              item.subtipoCoMs === sessionUnidadeSaude.subtipo?.codigoMs)
        )
      } else {
        return false
      }
    },
    [acesso]
  )

  const tipoEstabelecimento = acesso && isLotacaoOrEstagio(acesso) && acesso.unidadeSaude.tipoEstabelecimento

  const isEstabelecimentoAtencaoPrimaria =
    acesso && isLotacaoOrEstagio(acesso) && acesso.unidadeSaude.isEstabelecimentoAtencaoPrimaria

  const hasTipoEstabelecimentoAuth = useCallback(
    (tiposEstabelecimento: TipoEstabelecimentoEnum[]) => {
      return tiposEstabelecimento.includes(tipoEstabelecimento)
    },
    [tipoEstabelecimento]
  )

  const hasPermissionAuth = useCallback((permissions: Permission[]) => permissions.some(hasAuthorization), [
    hasAuthorization,
  ])

  const hasSupervisorPermissionAuth = useCallback(
    (permissions: Permission[]) => permissions.some(hasSupervisorAuthorization),
    [hasSupervisorAuthorization]
  )

  const checkAuthorization = useCallback(
    (permission: Authorization) => {
      if (isPermission(permission)) {
        return hasAuthorization(permission)
      } else if (isCboPermission(permission)) {
        return hasCboAuth(permission)
      } else if (isPermissionArray(permission)) {
        return hasPermissionAuth(permission)
      } else if (isEstabelecimentoPermission(permission)) {
        return hasEstabelecimentoAuth(permission)
      }
    },
    [hasAuthorization, hasCboAuth, hasEstabelecimentoAuth, hasPermissionAuth]
  )

  const checkSupervisorAuthorization = useCallback(
    (permission: Authorization) => {
      if (isPermission(permission)) {
        return hasSupervisorAuthorization(permission)
      } else if (isPermissionArray(permission)) {
        return hasSupervisorPermissionAuth(permission)
      }
    },
    [hasSupervisorAuthorization, hasSupervisorPermissionAuth]
  )

  return {
    loading: loadingSessao,
    data: data && data.sessao,
    isEstagio: acesso?.__typename === 'Estagio',
    isGestorEstadual: acesso?.__typename === 'GestorEstadual',
    tipoEstabelecimento,
    isEstabelecimentoAtencaoPrimaria,
    resetApolloCache,
    logoutSuccess,
    afterLogout,
    refresh: () => refetch(),
    hasAuthorization,
    hasSupervisorAuthorization,
    hasCboAuth,
    hasEstabelecimentoAuth,
    hasTipoEstabelecimentoAuth,
    checkAuthorization,
    checkSupervisorAuthorization,
  }
}

const hasCbo = (acesso): acesso is { cbo: Cbo } => !!acesso?.cbo

const hasEstabelecimento = (acesso): acesso is { unidadeSaude: UnidadeSaude } => !!acesso?.unidadeSaude
