import { isEmpty, Text, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import {
  AtendimentoProfissionalPreNatal,
  AtendimentoProfissionalPuericultura,
  EvolucaoObjetivo,
  HistoricoObjetivoPecFragmentFragment,
  MarcadorConsumoAlimentar,
  ResultadoExame,
} from 'graphql/types.generated'
import React from 'react'
import { calculateAge } from 'util/date/calculateAge'
import { filterByRegistroAnterior } from 'view/atendimentos/detail/historico/utils/filterByRegistroAnterior'
import {
  convertMedicoesToHistoricoMedicaoModel,
  getMedicoesPreNatal,
  hasAllAttrsNull,
  hasMedicoes,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/utils-historico'
import { ArquivoAtendimentoProfissionalHistorico } from 'view/atendimentos/detail/soap/objetivo/anexo-arquivos/model-anexoArquivos'
import { IVCF_RESULTADOS } from 'view/atendimentos/detail/soap/objetivo/ivcf/model-ivcf'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { HistoricoMedicoesObservacaoPanel } from '../../atendimento-observacao/HistoricoMedicoesObservacaoPanel'
import HistoricoArquivosAnexadosTableBox from '../../HistoricoArquivosAnexadosTableBox'
import { HistoricoIvcfTableBox } from '../../HistoricoIvcfTableBox'
import HistoricoMCATableBox from '../../HistoricoMCATableBox'
import HistoricoMedicoesTableBox from '../../HistoricoMedicoesTableBox'
import HistoricoResultadoExamesTable from '../../HistoricoResultadoExamesTable'
import HistoricoMedicoesPreNatalTableBox from '../pre-natal/HistoricoMedicoesPreNatalTableBox'
import HistoricoObjetivoInformacoesPuericultura from '../puericultura/HistoricoObjetivoInformacoesPuericultura'

type ivcf = HistoricoObjetivoPecFragmentFragment['ivcf']

export interface AtendimentoProfissionalPreNatalModel
  extends Pick<AtendimentoProfissionalPreNatal, 'id' | 'gravidezPlanejada' | 'movimentacaoFetal' | 'tipoEdema'> {}

export interface HistoricoObjetivoPecPanelProps {
  evolucaoObjetivo: EvolucaoObjetivo
  medicoes: HistoricoMedicaoModel[]
  isAtendObservacao?: boolean
  examesResultados?: ResultadoExame[]
  atendProfPreNatal?: AtendimentoProfissionalPreNatalModel
  atendProfPuericultura?: AtendimentoProfissionalPuericultura
  marcadorConsumoAlimentar?: MarcadorConsumoAlimentar
  ivcf?: ivcf
  dataNascimentoCidadao?: LocalDate
  dataAtendimento?: LocalDate
  arquivosAnexados?: ArquivoAtendimentoProfissionalHistorico[]
}

export default function HistoricoObjetivoPecPanel(props: HistoricoObjetivoPecPanelProps) {
  const {
    evolucaoObjetivo,
    medicoes,
    examesResultados,
    atendProfPreNatal,
    atendProfPuericultura,
    marcadorConsumoAlimentar,
    isAtendObservacao,
    ivcf,
    dataNascimentoCidadao,
    dataAtendimento,
    arquivosAnexados,
  } = props

  const { recentes } = filterByRegistroAnterior<HistoricoMedicaoModel>(medicoes, 'medicaoAnterior')

  const descricao = evolucaoObjetivo?.descricao?.removeTags()

  const possuiInformacoesPuericultura: boolean = atendProfPuericultura && !hasAllAttrsNull(atendProfPuericultura)

  const hasAtendProfPreNatal: boolean =
    atendProfPreNatal?.gravidezPlanejada || atendProfPreNatal?.movimentacaoFetal || !!atendProfPreNatal?.tipoEdema

  const preNatalInfo = getMedicoesPreNatal(recentes)

  const hasPreNatalInfo = hasAtendProfPreNatal || !isEmpty(preNatalInfo)

  const shouldDisplayMCA = !!marcadorConsumoAlimentar

  const { ANEXO_ARQUIVOS } = useFlags()

  const shouldDisplayArquivosAnexados = ANEXO_ARQUIVOS && arquivosAnexados.isNotEmpty()

  const hasObjetivo: boolean =
    !!descricao ||
    !isEmpty(examesResultados) ||
    hasMedicoes(recentes) ||
    hasPreNatalInfo ||
    possuiInformacoesPuericultura ||
    shouldDisplayMCA ||
    !!ivcf ||
    shouldDisplayArquivosAnexados

  return (
    <>
      {hasObjetivo ? (
        <VFlow>
          {descricao && <Text dangerouslySetInnerHTML={{ __html: evolucaoObjetivo.descricao }} />}

          {shouldDisplayArquivosAnexados && <HistoricoArquivosAnexadosTableBox arquivosAnexados={arquivosAnexados} />}

          {hasMedicoes(recentes) &&
            (isAtendObservacao ? (
              <HistoricoMedicoesObservacaoPanel medicoes={recentes} />
            ) : (
              <HistoricoMedicoesTableBox sectionStyle='O' medicoes={recentes} />
            ))}

          {shouldDisplayMCA && <HistoricoMCATableBox marcadorConsumoAlimentar={marcadorConsumoAlimentar} />}

          {dataNascimentoCidadao &&
            dataAtendimento &&
            calculateAge(dataNascimentoCidadao, dataAtendimento).years >= 60 &&
            ivcf && (
              <HistoricoIvcfTableBox
                ivcf={ivcf}
                riscoVulnerabilidadeText={IVCF_RESULTADOS[ivcf.riscoVulnerabilidade].longTitle}
              />
            )}

          {hasPreNatalInfo && (
            <HistoricoMedicoesPreNatalTableBox medicoes={preNatalInfo} atendProfPreNatal={atendProfPreNatal} />
          )}
          {possuiInformacoesPuericultura && (
            <HistoricoObjetivoInformacoesPuericultura
              medicoes={convertMedicoesToHistoricoMedicaoModel(recentes)}
              atendProfPuericultura={atendProfPuericultura}
            />
          )}
          {!isEmpty(examesResultados) && <HistoricoResultadoExamesTable examesResultados={examesResultados} />}
        </VFlow>
      ) : (
        <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
      )}
    </>
  )
}
