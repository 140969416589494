import { Cell, Grid } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { Form, FormRenderProps } from 'components/form'
import { ProcedimentoSelectField, ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { useListFieldStatus } from 'components/form/final-form/hooks'
import { getFieldError } from 'components/form/final-form/util'
import { GrupoProcedimentoEnum, TipoProcedimentoEnum } from 'graphql/types.generated'
import React, { useCallback } from 'react'
import { MetaArray, metaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'
import { EditableList, RowType, useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'
import { getSexoCidadao } from 'view/atendimentos/utils/util-sexoCidadao'

import { getCbosIdsProcedimentos } from '../../plano/utils/planoUtils'
import { ProcedimentoSigtapRow } from './ProcedimentoSigtapRow'

export interface ProcedimentoSigtapFieldModel extends SoapEditableItem, RowType {
  procedimento: ProcedimentoSelectModel
  isAutomatico?: boolean
}

export interface ProcedimentoSigtapFieldProps {
  name: MetaArray<ProcedimentoSigtapFieldModel>
  cidadao?: CidadaoAtendimento
  tipoProcedimento?: TipoProcedimentoEnum
  label?: string
  dataAtendimento: LocalDate
  showModifiedStatus?: boolean
}

ProcedimentoSigtapField.defaultProps = {
  tipoProcedimento: TipoProcedimentoEnum.ADMINISTRATIVO,
  label: 'Procedimentos administrativos (SIGTAP)',
}

const path = metaPath<ProcedimentoSigtapFieldModel>()

export function ProcedimentoSigtapField(props: ProcedimentoSigtapFieldProps) {
  const { name, cidadao, tipoProcedimento, label, dataAtendimento, showModifiedStatus = false } = props
  const { acesso } = useAcessoLotacaoOrEstagio()

  const {
    handleSubmit,
    removeItem,
    resetItemToInitialValue,
    meta,
    input: { value },
  } = useEditableListField({
    name,
  })

  const { fieldStatus, disabledValues: procedimentosDisabled } = useListFieldStatus({
    name,
    showModifiedStatus,
    isSameRow,
  })

  const procedimentoIsEqual = (procedimento: ProcedimentoSelectModel) => {
    return (value || []).find((o2) => procedimento.id === o2.procedimento.id)
  }

  const itemIsDisabled = useCallback(
    (item: ProcedimentoSelectModel) =>
      procedimentosDisabled?.map((itemDisabled) => itemDisabled?.procedimento.id).includes(item.id),
    [procedimentosDisabled]
  )

  const renderForm = (props: FormRenderProps<ProcedimentoSigtapFieldModel>) => {
    const handleChange = (newValue: ProcedimentoSelectModel) => {
      newValue && !procedimentoIsEqual(newValue) && props.form.submit()
    }

    return (
      <>
        <ProcedimentoSelectField
          name={path.procedimento}
          label={label}
          filtroPadrao={true}
          tipoProcedimento={tipoProcedimento}
          gruposProcedimento={[
            GrupoProcedimentoEnum.ACOES_PROMOCAO_PREVENCAO_SAUDE,
            GrupoProcedimentoEnum.FINALIDADE_DIAGNOSTICA,
            GrupoProcedimentoEnum.CLINICOS,
            GrupoProcedimentoEnum.CIRURGICO,
          ]}
          sexo={getSexoCidadao(cidadao)}
          dataNascimento={cidadao?.dataNascimento}
          dataAtendimento={dataAtendimento}
          cboIds={getCbosIdsProcedimentos(acesso)}
          itemIsEqual={procedimentoIsEqual}
          onChange={handleChange}
          loadItemsOnOpen={false}
          error={getFieldError(meta)}
          itemIsDisabled={!isEmpty(procedimentosDisabled) ? itemIsDisabled : undefined}
        />
      </>
    )
  }

  return (
    <Grid gapVertical={0.5}>
      <Cell size={8}>
        <Form<ProcedimentoSigtapFieldModel> render={renderForm} onSubmit={handleSubmit} {...props} />
      </Cell>

      {!showModifiedStatus && value?.length > 0 && (
        <Cell size={12}>
          <EditableList>
            {value?.map((item) => (
              <ProcedimentoSigtapRow key={item._id} model={item} onRemove={removeItem} />
            ))}
          </EditableList>
        </Cell>
      )}

      {showModifiedStatus && fieldStatus?.size > 0 && (
        <Cell size={12}>
          <EditableList>
            {Array.from(fieldStatus, ([item, status]) => (
              <ProcedimentoSigtapRow
                key={item._id}
                model={item}
                onRemove={removeItem}
                onReset={resetItemToInitialValue}
                status={status}
                showModifiedStatus
              />
            ))}
          </EditableList>
        </Cell>
      )}
    </Grid>
  )
}

const isSameRow = (itemA: ProcedimentoSigtapFieldModel, itemB: ProcedimentoSigtapFieldModel) =>
  itemA.procedimento.id === itemB.procedimento.id
