import useSession from 'components/auth/useSession'
import { FilterTag } from 'components/filtertags'
import { useMemoWithDeepCompare } from 'hooks/useMemoWithDeepCompare'
import { useServerTime } from 'hooks/useServerTime'
import { useState } from 'react'
import { humanizeDateRange } from 'util/date/humanize-date'

import { convertAtividadeColetivaFilterFormModelToInput } from '../converter-atividadeColetiva'
import { AtividadeColetivaFilterFormModel, tipoAtividadeRecord } from '../model-atividadeColetiva'

export const useAtividadeColetivaFilter = (isReuniao: boolean) => {
  const [filter, setFilter] = useState<AtividadeColetivaFilterFormModel>({
    isReuniao,
    pageParams: {
      page: 0,
      size: 10,
      sort: ['-dataAtividade'],
    },
  })

  const {
    data: {
      profissional: { cpf: profissionalCpf },
    },
  } = useSession()

  const convertedFilter = useMemoWithDeepCompare(
    () => convertAtividadeColetivaFilterFormModelToInput(filter, profissionalCpf),
    [profissionalCpf, filter]
  )

  const { getServerTimeNow } = useServerTime()
  const filterTags: FilterTag[] = [
    ['Tipo de atividade', tipoAtividadeRecord[convertedFilter.tipoAtividade]],
    ['Período da atividade', humanizeDateRange(convertedFilter.periodo, getServerTimeNow())],
    ['Equipe', convertedFilter?.equipeIne && filter.equipe?.nome],
    ['Processado', convertedFilter.isProcessado && 'Sim'],
  ]

  return { filter, setFilter, convertedFilter, filterTags }
}
