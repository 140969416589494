import { AlertItem } from 'components/alert/AlertStore'
import { ArquivoAtendimentoProfissional, CategoriaArquivoAtendProfEnum } from 'graphql/types.generated'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'

export const ANEXO_ARQUIVOS_OBSERVACOES_PLACEHOLDER = 'Insira informações adicionais sobre o arquivo anexado'

export const ANEXO_ARQUIVOS_DEFAULT_ERROR_MESSAGE =
  'Não é possível importar esse arquivo. Verifique se o arquivo selecionado está correto.'

export const ANEXO_ARQUIVOS_ACCEPTED_MEDIA_TYPES = 'image/png, image/jpeg, application/pdf'

export const ANEXO_ARQUIVOS_MAX_FILE_SIZE = 5 * 1024 * 1024 // 5 MiB

export const categoriaAnexoLabelRecord: Record<CategoriaArquivoAtendProfEnum, string> = {
  IMAGEM_DE_LESAO: 'Imagem de lesão',
  EXAME_DE_IMAGEM: 'Exame de imagem',
  EXAME_DE_IMAGEM_ODONTOLOGICO: 'Exame de imagem odontológico',
  PRONTUARIO_EXTERNO: 'Prontuário externo',
  PRESCRICAO_DE_MEDICAMENTOS: 'Prescrição de medicamentos',
  GENOGRAMA_ECOMAPA: 'Genograma/Ecomapa',
  RELATORIO: 'Relatório',
  ENCAMINHAMENTO_EXTERNO: 'Encaminhamento externo',
}

export type CategoriaArquivoSelectModel = CategoriaArquivoAtendProfEnum

export interface AnexoArquivoRowModel extends SoapEditableItem {
  id?: ID
  cacheId?: ID
  categoria: CategoriaArquivoAtendProfEnum
  titulo: string
  observacao?: string
  arquivo: {
    id: ID
  }
  atendimentoProfissional: {
    id: ID
    iniciadoEm: Instant
    lotacao: {
      id: ID
      cbo: {
        id: ID
        nome: string
      }
      profissional: {
        id: ID
        nome: string
        cpf?: string
        cns?: string
      }
    }
  }
}

export interface AnexoArquivoFormModel extends SoapEditableItem {
  arquivoId: ID
  titulo: string
  categoria: CategoriaArquivoAtendProfEnum
  observacao?: string
}

export interface AnexoArquivoFilterModel {
  categoria: CategoriaArquivoAtendProfEnum
  somenteMeus: boolean
}

export type ArquivoAtendimentoProfissionalHistorico = Omit<ArquivoAtendimentoProfissional, 'atendimentoProfissional'>

export type AnexoArquivosStatusMessagesModel = Pick<AlertItem, 'type' | 'message'>

export const fileErrorMessageRecord: Record<string, string> = {
  'file-too-large': 'Apenas arquivos com tamanho menor que 5mb são permitidos.',
  'file-invalid-type': 'Apenas arquivos com extensão PDF, JPEG, JPG ou PNG são permitidos.',
}
