/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { FooterButton } from 'components/footer-button'
import { Form, FormRenderProps, usePecField } from 'components/form'
import { IconLabel } from 'components/IconLabel'
import { confirm } from 'components/modals/confirm'
import { FormState } from 'final-form'
import { isEmpty } from 'lodash'
import { useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { MetaPath } from 'util/metaPath'

import { MChatModalStatus, MChatStepper } from './components/MChatStepper'
import { MChatModalSectionAdicionar } from './MChatModalSectionAdicionar'
import { MChatModalSectionResultado } from './MChatModalSectionResultado'
import { MCHAT_PERGUNTAS, MCHAT_URL_MODAL, MChatAplicacaoModel, MChatFieldModel, MChatRiscoEnum } from './model-mchat'
import { calcularResultadoMChat } from './util-mchat'

const questionsEntries = Object.entries(MCHAT_PERGUNTAS)
const numberOfQuestions = questionsEntries.length

enum MChatModalTab {
  APLICACAO = 'APLICACAO',
  RESULTADO = 'RESULTADO',
}

interface MChatModalProps {
  name: MetaPath<MChatFieldModel>
  onSave: (values: MChatFieldModel) => void
  onDelete: () => void
}

export function MChatModal(props: MChatModalProps) {
  const { name, onSave, onDelete } = props

  const theme = useTheme()
  const history = useHistory()
  const match = useRouteMatch()
  const {
    input: { value },
  } = usePecField({ name, subscription: { value: true } })

  const hasValues = !isEmpty(value)
  const shouldAvaliarConsultaSeguimento = hasValues && value.risco === MChatRiscoEnum.MEDIO
  const initialStepperStatus = !hasValues
    ? 'respondendo'
    : shouldAvaliarConsultaSeguimento
    ? 'finalizado-com-seguimento'
    : 'finalizado'

  const [currentTab, setCurrentTab] = useState<MChatModalTab>(
    hasValues ? MChatModalTab.RESULTADO : MChatModalTab.APLICACAO
  )
  const [numberOfAnswers, setNumberOfAnswers] = useState(hasValues ? Object.keys(value.aplicacao).length : 0)
  const [stepperStatus, setStepperStatus] = useState<MChatModalStatus>(initialStepperStatus)

  const isAllAnswered = numberOfAnswers === numberOfQuestions
  const styles = useMemo(() => createStyles(theme, currentTab === MChatModalTab.RESULTADO), [theme, currentTab])

  const handleSubmit = (values: MChatAplicacaoModel) => {
    const calculatedValues = calcularResultadoMChat(values)
    onSave(calculatedValues)
    setStepperStatus(calculatedValues.risco === MChatRiscoEnum.MEDIO ? 'finalizado-com-seguimento' : 'finalizado')
    setCurrentTab(MChatModalTab.RESULTADO)
  }

  const handleFormChange = ({ values }: FormState<MChatAplicacaoModel>) => {
    setNumberOfAnswers(Object.keys(values).length)
  }

  const handleClose = () => {
    const closeModal = () => history.push(match.url.split(MCHAT_URL_MODAL)[0])

    if (currentTab === MChatModalTab.APLICACAO) {
      mChatConfirmDialog(() => {
        onDelete()
        closeModal()
      })
    } else {
      closeModal()
    }
  }

  const handleGoBackToAplicacao = () => {
    setCurrentTab(MChatModalTab.APLICACAO)
    setStepperStatus('respondendo')
  }

  const renderModal = (renderProps: FormRenderProps<MChatAplicacaoModel>) => {
    return (
      <Modal open closeOnBackdropClick={false} onClose={handleClose} style={styles.modal}>
        <HFlow hSpacing={0} style={styles.hFlow}>
          <div css={styles.sidebar}>
            <MChatStepper status={stepperStatus} numberOfAnswers={numberOfAnswers} />
            <Text color='secondary'>© 2009 Robins, Fein, & Barton</Text>
          </div>
          <ModalBody style={styles.modalBody}>
            <div css={styles.modalContent}>
              {currentTab === MChatModalTab.APLICACAO ? (
                <MChatModalSectionAdicionar handleFormChange={handleFormChange} />
              ) : (
                <MChatModalSectionResultado values={value} onClickVoltar={handleGoBackToAplicacao} />
              )}
            </div>
          </ModalBody>
        </HFlow>

        <ModalFooter style={styles.modalFooter}>
          <HFlow justifyContent='flex-end' alignItems='center'>
            {currentTab === MChatModalTab.RESULTADO && (
              <IconLabel
                icon='checkCircleOutline'
                text='Resultado salvo no atendimento'
                color={theme.pallete.status.success.c40}
              />
            )}

            <FooterButton onClick={handleClose}>
              {currentTab === MChatModalTab.RESULTADO ? 'Fechar' : 'Cancelar'}
            </FooterButton>

            {currentTab === MChatModalTab.APLICACAO ? (
              <Button kind='primary' onClick={renderProps.handleSubmit} disabled={!isAllAnswered}>
                Calcular resultado
              </Button>
            ) : (
              shouldAvaliarConsultaSeguimento && <Button kind='primary'>Avaliar Consulta de seguimento</Button>
            )}
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return <Form<MChatAplicacaoModel> onSubmit={handleSubmit} render={renderModal} initialValues={value.aplicacao} />
}

const mChatConfirmDialog = (handleConfirm: () => void) => {
  confirm({
    confirmLabel: 'Sair da tela',
    cancelLabel: 'Continuar aqui',
    type: 'primary',
    size: 'auto',
    onConfirm: handleConfirm,
    render: () => (
      <HFlow>
        <Icon icon='exclamationTriangleOutline' fill='alert' size={3} />
        <VFlow vSpacing={0.125}>
          <Heading level={1}>Deseja sair sem calcular o resultado?</Heading>
          <Text>As alterações realizadas serão perdidas, inclusive resultados já calculados.</Text>
        </VFlow>
      </HFlow>
    ),
  })()
}

const createStyles = (theme: Theme, showMChatResult: boolean) => ({
  modal: css`
    width: 62rem;
    overflow: hidden;
  `,
  hFlow: css`
    max-height: inherit;
  `,
  sidebar: css`
    width: 17rem;
    max-height: calc(80vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 3.75rem;
    padding-left: 2.5rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    background: ${theme.pallete.gray.c90};
    border-right: 1px solid ${theme.pallete.divider};
  `,
  modalBody: css`
    width: 44.95rem;
    padding: 0;
    overflow: auto;
    max-height: inherit;
  `,
  modalContent: css`
    overflow: hidden auto;
    max-height: calc(80vh - 5rem);
    padding: 2.5rem;
    padding-top: ${showMChatResult ? 1 : 2.5}rem;
  `,
  modalFooter: css`
    border-top: 1px solid ${theme.pallete.divider};
  `,
})
