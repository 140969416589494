/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Icon, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { VerticalStep, VerticalStepper } from 'components/vertical-stepper'
import { useMemo } from 'react'

import { MCHAT_PERGUNTAS } from '../model-mchat'

export type MChatModalStatus = 'respondendo' | 'finalizado' | 'finalizado-com-seguimento'

interface MChatStepperProps {
  status: MChatModalStatus
  numberOfAnswers: number
}

export function MChatStepper(props: MChatStepperProps) {
  const { status, numberOfAnswers } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, numberOfAnswers), [numberOfAnswers, theme])

  return (
    <VerticalStepper>
      <VerticalStep hasConnector={false} status={status === 'respondendo' ? 'active' : 'completed'}>
        <VFlow vSpacing={0}>
          <Text color='primary'>M-CHAT-R&#8482;</Text>
          {status === 'respondendo' && (
            <Text>
              {numberOfAnswers} de {numberOfQuestions} perguntas
            </Text>
          )}
        </VFlow>
      </VerticalStep>

      <VerticalStep
        status={status !== 'respondendo' ? 'active' : 'incompleted'}
        overrides={{
          Icon: status === 'respondendo' ? StepTwoIcon : null,
        }}
      >
        <span css={styles.stepTwoConnector} />
        <Text>Resultado e encaminhamento</Text>
      </VerticalStep>

      <VerticalStep
        connectorStyle={
          status === 'finalizado-com-seguimento' ? styles.stepThreeConnector : styles.stepThreeDashedConnector
        }
        overrides={{
          Icon: status === 'finalizado-com-seguimento' ? StepThreeIcon : StepThreeDisabledIcon,
        }}
        style={styles.stepThree}
      >
        <VFlow vSpacing={0}>
          <Text>Consulta de seguimento</Text>
          {status === 'finalizado-com-seguimento' && (
            <Text fontStyle='italic' fontWeight='normal'>
              Recomendado
            </Text>
          )}
        </VFlow>
      </VerticalStep>
    </VerticalStepper>
  )
}

const numberOfQuestions = Object.keys(MCHAT_PERGUNTAS).length

const StepTwoIcon = () => {
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  return <div css={styles.stepTwoIcon} />
}

const StepThreeIcon = () => {
  return <Icon icon='exclamationTriangleFilled' fill='alert' />
}

const StepThreeDisabledIcon = () => {
  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  return <Icon icon='minusCircleFilled' style={styles.stepThreeDisabledIcon} />
}

const createStyles = (theme: Theme, numberOfAnswers: number = 0) => ({
  stepThree: css`
    margin-bottom: 1rem;
  `,
  stepThreeConnector: css`
    border-color: ${theme.pallete.gray.c60};
  `,
  stepThreeDashedConnector: css`
    border-left-style: dashed;
  `,
  stepThreeDisabledIcon: css`
    fill: ${theme.pallete.gray.c80};
  `,
  stepTwoIcon: css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    min-width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${theme.pallete.gray.c60};
  `,
  stepTwoConnector: css`
    width: 0.25rem;
    height: ${(numberOfAnswers * 5.5) / numberOfQuestions}rem;
    background: ${theme.pallete.primary.main};
    position: absolute;
    top: -5rem;
    left: 0.625rem;
    right: 0;
    border-radius: 4px;
    transition: height 0.2s ease;
  `,
})
