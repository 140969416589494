import api from 'api'
import { defaultHandleError } from 'util/downloadFile'
import { previewFile } from 'util/previewFile'

export const visualizarArquivoAnexado = (arquivoId: ID) => {
  return api.anexoArquivos
    .baixarArquivo(arquivoId)
    .then((response) => {
      previewFile(new Blob([response.data], { type: response.headers['content-type'] }), 'Arquivo Anexado')
    })
    .catch(defaultHandleError)
}
