/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'
import {
  AccordionDataTableRowDefault,
  AccordionDataTableRowDefaultProps,
} from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'
import { EditableRow } from 'view/atendimentos/detail/components/EditableList'

import { convertAnexoArquivoRowToForm } from '../../converter-anexoArquivos'
import { AnexoArquivoFormModel, AnexoArquivoRowModel } from '../../model-anexoArquivos'
import { AnexoArquivosForm } from './AnexoArquivosForm'

interface AnexoArquivosTableRowProps extends AccordionDataTableRowDefaultProps<AnexoArquivoRowModel> {
  isEditing: boolean
  onUpdate(index: number, value: AnexoArquivoFormModel): void
  onCancel(): void
}

export function AnexoArquivosTableRow(props: AnexoArquivosTableRowProps) {
  const { row, isEditing, onUpdate, onCancel } = props

  const handleChange = (anexoArquivo: AnexoArquivoFormModel) => {
    onUpdate(row.cacheId, anexoArquivo)
    onCancel()
  }

  return (
    <VFlow vSpacing={0}>
      <AccordionDataTableRowDefault {...props} />
      {isEditing && (
        <EditableRow editing={isEditing}>
          <div
            css={css`
              padding: 1rem 0;
            `}
          >
            <AnexoArquivosForm
              onSubmit={handleChange}
              initialValues={convertAnexoArquivoRowToForm(row)}
              onCancel={onCancel}
            />
          </div>
        </EditableRow>
      )}
    </VFlow>
  )
}
