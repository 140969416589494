/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme } from 'bold-ui'
import { capitalize } from 'lodash'
import { useMemo } from 'react'

import { MCHAT_RESULTADOS, MChatRiscoEnum } from '../model-mchat'

interface MChatResultadoLineProps {
  pontuacao: number
  riscoResultado: MChatRiscoEnum
  line: MChatRiscoEnum
}

export function MChatResultadoLine(props: MChatResultadoLineProps) {
  const { line, pontuacao, riscoResultado } = props

  const { min, max } = calculateRiskRange(line)
  const showProgress = riscoResultado === line || pontuacao >= min
  const divisor = max - min + (riscoResultado === MChatRiscoEnum.ELEVADO && pontuacao === max ? 0 : 1)
  const indicatorPosition = ((pontuacao - min) * 100) / divisor

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme, indicatorPosition, line, riscoResultado), [
    indicatorPosition,
    line,
    riscoResultado,
    theme,
  ])

  const lineTitle = capitalize(MCHAT_RESULTADOS[line].shortTitle)

  return (
    <div css={styles.div}>
      <HFlow css={styles.tableHeader}>
        <Text style={styles.tableHeaderPrimaryText}>{lineTitle}</Text>
        <Text style={styles.tableHeaderSecondaryText}>
          ({min}-{max})
        </Text>
      </HFlow>
      <div css={styles.progressBarContainer}>{showProgress && <div css={styles.progressBar} />}</div>
    </div>
  )
}

const calculateRiskRange = (risco: MChatRiscoEnum): { min: number; max: number } => {
  switch (risco) {
    case MChatRiscoEnum.BAIXO:
      return { min: 0, max: 2 }
    case MChatRiscoEnum.MEDIO:
      return { min: 3, max: 7 }
    case MChatRiscoEnum.ELEVADO:
      return { min: 8, max: 20 }
  }
}

const createStyles = (
  theme: Theme,
  indicatorPosition: number,
  line: MChatRiscoEnum,
  riscoResultado: MChatRiscoEnum
) => ({
  div: css`
    padding: 0;
    margin: 0;
  `,
  tableHeader: css`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.25rem;
    background: ${theme.pallete.gray.c90};
    height: 1.5rem;
    margin-bottom: 1.125rem;
  `,
  tableHeaderPrimaryText: css`
    color: ${theme.pallete.gray.c10};
  `,
  tableHeaderSecondaryText: css`
    color: ${theme.pallete.gray.c40};
  `,
  progressBarContainer: css`
    width: 100%;
    height: 0.25rem;
    background-color: ${theme.pallete.gray.c80};
    position: relative;
    overflow: hidden;
    margin-top: 1rem;
  `,
  progressBar: css`
    height: 100%;
    background-color: ${MCHAT_RESULTADOS[riscoResultado].lineColor};
    width: ${indicatorPosition}%;
  `,
})
