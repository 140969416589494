import { Text, TextColor } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Agendado, OrigemAgendamentoEnum } from 'graphql/types.generated'
import React, { Fragment } from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'
import { formatNomeProfissional } from 'view/profissional/utils/utils-profissional'

import { ResponsavelAgendamentoModel } from '../cidadao/model-cidadao'
import { lotacaoAgendamentoToString } from '../util-agenda'

export interface AgendamentoCriacaoProps extends Pick<Agendado, 'dataCriacao' | 'origem'> {
  responsavelCriador?: ResponsavelAgendamentoModel
  color?: TextColor
}

export function AgendamentoCriacao(props: AgendamentoCriacaoProps) {
  const { color, dataCriacao, responsavelCriador, origem } = props

  const isAgendadoPeloCidadao = origem === OrigemAgendamentoEnum.MEUDIGISUS

  return (
    <HLabel
      title={`Adicionado ${dataCriacao ? `em ${formatDateAndHoursMinutes(dataCriacao)}` : ''} por:`}
      color={color}
    >
      {isAgendadoPeloCidadao ? (
        <Fragment>
          Cidadão via{' '}
          <Text fontStyle='italic' color={color}>
            Meu SUS Digital
          </Text>
        </Fragment>
      ) : (
        lotacaoAgendamentoToString({
          nomeProfissional: formatNomeProfissional(responsavelCriador?.profissional),
          nomeCbo: responsavelCriador?.cbo?.nome,
          ineEquipe: responsavelCriador?.equipe?.ine,
          nomeEquipe: responsavelCriador?.equipe?.nome,
        })
      )}
    </HLabel>
  )
}
