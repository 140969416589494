import { DateRange, getDomainPoints } from 'bold-ui/lib/components/Chart'
import { SexoEnum } from 'graphql/types.generated'
import moment, { Duration, Moment } from 'moment'
import { createParameters, TipoMedicao } from 'util/atendimento/percentis/calculatePercentil'

export type ReferenceAreasCalculator = {
  date: Moment
  referenceAreaCalculator: (percentil: number) => number
}

export function getLinearAreaInRange(xRange: DateRange, range: { max?: number }) {
  return [
    {
      x: xRange.init,
      upperLimit: range.max,
    },
    {
      x: xRange.end,
      upperLimit: range.max,
    },
  ]
}

export function getReferenceAreasCalculators(
  sexo: SexoEnum,
  dataNascimento: Moment,
  xRange: DateRange,
  medicao: TipoMedicao
): ReferenceAreasCalculator[] {
  const maxIdadeNoRange = moment.duration(moment(xRange.end).diff(dataNascimento))

  return getDomainPoints<Moment>(getRefAreaDomain(maxIdadeNoRange, xRange)).map((date) => ({
    date: date,
    referenceAreaCalculator: getReferenceAreaCalculator(sexo, date, dataNascimento, medicao),
  }))
}

function getRefAreaDomain(maxIdadeNoRange: Duration, dataRange: DateRange): DateRange {
  if (maxIdadeNoRange.years() <= 2) return { ...dataRange, step: { unit: 'day', amount: 1 } }
  else if (maxIdadeNoRange.years() <= 5) return { ...dataRange, step: { unit: 'day', amount: 10 } }
  else return { ...dataRange, step: { unit: 'month', amount: 1 } }
}

function getReferenceAreaCalculator(
  sexo: SexoEnum,
  dataMedicao: Moment,
  dataNascimento: Moment,
  medicao: TipoMedicao
): (percentil: number) => number {
  const precision = 'days'
  const idade = moment.duration(moment(dataMedicao).diff(dataNascimento, precision), precision)
  const params = createParameters(
    sexo === SexoEnum.MASCULINO ? SexoEnum.MASCULINO : SexoEnum.FEMININO,
    idade.asYears(),
    medicao,
    Math.round
  )

  return (x) => params.calcularPercentil(x)
}
