import { PageLoading } from 'components/loading'
import { useAtendimentoProfissionalViewQuery } from 'graphql/hooks.generated'
import React from 'react'
import { useParams } from 'react-router'

import { AtendimentoBaseView } from './AtendimentoBaseView'
import { convertAtendimentoProfissional } from './converter-atendimento'

export interface AtendimentoRetificacaoViewParams {
  atendimentoProfissionalId: string
}

export function AtendimentoRetificacaoView() {
  const { atendimentoProfissionalId } = useParams<AtendimentoRetificacaoViewParams>()

  const {
    data: { atendimentoIndividual },
    loading,
  } = useAtendimentoProfissionalViewQuery({
    variables: { atendimentoProfissionalId },
  })

  if (loading) {
    return <PageLoading message='Carregando atendimento...' />
  } else {
    return <AtendimentoBaseView atendimento={convertAtendimentoProfissional(atendimentoIndividual)} isRetificacao />
  }
}
