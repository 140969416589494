import { PageContent } from 'components/layout/PageContent'
import React from 'react'
import { AgendamentosCidadaoView } from 'view/agenda/cidadao/AgendamentosCidadaoView'
import { TipoAtendimentoFooter } from 'view/atendimentos/model-atendimento'

import { AtendimentoFooter } from '../AtendimentoFooter'

export interface AgendamentosCidadaoAtendimentoView {
  atendimentoId: ID
  isRegistroTardio?: boolean
  isAtendimentoObservacao?: boolean
  isObservacaoAndResponsavel?: boolean
  clearCache(updateState?: boolean): void
  tipoAtendimentoProfissional: TipoAtendimentoFooter
}

export function AgendamentosCidadaoAtendimentoView(props: AgendamentosCidadaoAtendimentoView) {
  const {
    atendimentoId,
    clearCache,
    tipoAtendimentoProfissional,
    isRegistroTardio = false,
    isAtendimentoObservacao = false,
    isObservacaoAndResponsavel = false,
  } = props

  return (
    <>
      <PageContent fluid type='filled'>
        <AgendamentosCidadaoView />
      </PageContent>
      <AtendimentoFooter
        atendimentoId={atendimentoId}
        tipoAtendimentoProfissional={tipoAtendimentoProfissional}
        clearCache={clearCache}
        isRegistroTardio={isRegistroTardio}
        isAtendimentoObservacao={isAtendimentoObservacao}
        isObservacaoAndResponsavel={isObservacaoAndResponsavel}
      />
    </>
  )
}
