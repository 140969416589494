/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, Text, VFlow } from 'bold-ui'
import { gray, orange } from 'bold-ui/lib/styles/colors'
import { DateTime } from 'components/date'
import { CuidadoCompartilhadoFinalizacaoModel } from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

import { CuidadoCompartilhadoItemWrapper } from './CuidadoCompartilhadoItemWrapper'

interface CuidadoCompartilhadoFinalizacaoItemProps {
  finalizacao: CuidadoCompartilhadoFinalizacaoModel
  isFirstFinalizacao: boolean
  isLastFinalizacao: boolean
}

const fraseCuidadoObito = (acao: string) =>
  `Esse cuidado foi ${acao} automaticamente devido à alteração do cadastro do cidadão sobre falecimento.`

export const CuidadoCompartilhadoFinalizacaoItem = (props: CuidadoCompartilhadoFinalizacaoItemProps) => {
  const { finalizacao, isFirstFinalizacao, isLastFinalizacao } = props

  const styles = createStyles()

  return (
    <CuidadoCompartilhadoItemWrapper
      Icon={() => <Icon icon='exclamationTriangleFilled' size={1} />}
      backgroundColorWrapper={gray.c90}
      backgroundColorIcon={orange.c40}
      isFirstItem={isFirstFinalizacao}
      isLastItem={isLastFinalizacao}
    >
      <VFlow vSpacing={0} style={styles.contentColumn}>
        <HFlow hSpacing={0.25} alignItems='center'>
          <Text fontWeight='bold'>Finalizado automaticamente</Text>
          <Text>|</Text>
          <Text color='secondary'>
            <DateTime value={finalizacao.dataFinalizado} />
          </Text>
        </HFlow>
        <Text>{fraseCuidadoObito('finalizado')}</Text>
        {finalizacao.dataReaberto && (
          <VFlow vSpacing={0} style={styles.contentColumn}>
            <HFlow hSpacing={0.25} alignItems='center'>
              <Text fontWeight='bold'>Reaberto automaticamente</Text>
              <Text>|</Text>
              <Text color='secondary'>
                <DateTime value={finalizacao.dataReaberto} />
              </Text>
            </HFlow>
            <Text>{fraseCuidadoObito('reaberto')}</Text>
          </VFlow>
        )}
      </VFlow>
    </CuidadoCompartilhadoItemWrapper>
  )
}

const createStyles = () => ({
  contentColumn: css`
    padding: 0.5rem 0.5rem 0.25rem 0;
  `,
})
