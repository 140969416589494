import { FieldStatusEnum } from 'components/form/final-form/components/FieldStatus/model-fieldStatus'
import { isEqualWith } from 'lodash'
import { useMemo } from 'react'
import { MetaArray } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'
import { RowType } from 'view/atendimentos/detail/components/EditableList'

import { usePecField } from '../useField'
import { getFieldStatus } from './getFieldStatus'

interface ListFieldStatus<T> {
  fieldStatus: Map<T, FieldStatusEnum>
  disabledValues: T[]
}

interface UseListFieldStatusParams<T> {
  name: MetaArray<T>
  showModifiedStatus?: boolean
  isSameRow(itemA: T, itemB: T): boolean
}

export function useListFieldStatus<T extends RowType>(params: UseListFieldStatusParams<T>): ListFieldStatus<T> {
  const { name, isSameRow, showModifiedStatus = false } = params

  const { input, meta } = usePecField({ name })

  const initialValues: T[] = meta.initial
  const currentValues: T[] = input.value

  return useMemo(() => {
    if (!showModifiedStatus || !Array.isArray(currentValues)) return { fieldStatus: new Map(), disabledValues: [] }

    const newStatusMap = new Map<T, FieldStatusEnum>(
      initialValues?.map((initialValueItem) => {
        const currentValueItem = currentValues.find((item) => isSameRow(initialValueItem, item))

        const dirty = isDirty(initialValueItem, currentValueItem)
        const status = getFieldStatus(initialValueItem, currentValueItem, isEmpty, dirty)

        return [!currentValueItem ? initialValueItem : currentValueItem, status]
      })
    )

    currentValues?.forEach((currentValueItem) => {
      const initialValueItem = initialValues?.find((initialValueItem) => isSameRow(currentValueItem, initialValueItem))

      if (!initialValueItem)
        newStatusMap.set(currentValueItem, getFieldStatus(initialValueItem, currentValueItem, isEmpty))
    })

    return { fieldStatus: newStatusMap, disabledValues: Array.from(newStatusMap.keys()) }
  }, [showModifiedStatus, currentValues, initialValues, isSameRow])
}

const isDirty = <T extends RowType>(initialValue: T, currentValue: T) =>
  !isEqualWith(initialValue, currentValue, (_initialValueItem, _currentValueItem, key) => {
    return key === '_id' ? true : undefined
  })
