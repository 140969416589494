import {
  CuidadoCompartilhadoEvolucaoMerged,
  CuidadoCompartilhadoEvolucaoModel,
  CuidadoCompartilhadoEvolucaoQueryModel,
  CuidadoCompartilhadoFinalizacaoModel,
  CuidadoCompartilhadoFinalizacaoQueryModel,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'

export const joinCuidadoCompartilhadoEvolucaoFinalizacao = (
  cuidadoCompartilhadoEvolucoes: CuidadoCompartilhadoEvolucaoQueryModel[],
  cuidadoCompartilhadoFinalizacoes: CuidadoCompartilhadoFinalizacaoQueryModel[]
): CuidadoCompartilhadoEvolucaoMerged[] => {
  const convertedEvolucoes = cuidadoCompartilhadoEvolucoes.map(convertEvolucaoToMerged)
  const convertedFinalizacoes = cuidadoCompartilhadoFinalizacoes.map(convertFinalizacoesToMerged)

  return [...convertedEvolucoes, ...convertedFinalizacoes].sort((a, b) => {
    if (a.sequenciaEvolucao !== b.sequenciaEvolucao) {
      return a.sequenciaEvolucao - b.sequenciaEvolucao
    }

    if (a.type !== b.type) {
      return a.type === 'evolucao' ? -1 : 1
    }

    return a.data - b.data
  })
}

const convertEvolucaoToMerged = (
  cuidadoCompartilhadoEvolucoes: CuidadoCompartilhadoEvolucaoQueryModel
): CuidadoCompartilhadoEvolucaoModel => ({
  ...cuidadoCompartilhadoEvolucoes,
  sequenciaEvolucao: cuidadoCompartilhadoEvolucoes.codigoSequencialEvolucao,
  type: 'evolucao',
  data: cuidadoCompartilhadoEvolucoes.dataEvolucao,
})

const convertFinalizacoesToMerged = (
  cuidadoCompartilhadoFinalizacoes: CuidadoCompartilhadoFinalizacaoQueryModel
): CuidadoCompartilhadoFinalizacaoModel => ({
  ...cuidadoCompartilhadoFinalizacoes,
  type: 'finalizacao',
  data: cuidadoCompartilhadoFinalizacoes.dataFinalizado,
})
