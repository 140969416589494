/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { AxiosRequestConfig } from 'axios'
import { Alert, Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps, SubmitButton, TextAreaField, TextField } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { confirm } from 'components/modals/confirm'
import { FileUploaderField } from 'components/upload/FileUploaderField'
import { Fragment, useState } from 'react'
import { FileError } from 'react-dropzone'
import { metaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'
import { v4 as uuidv4 } from 'uuid'
import { DownloadInfo } from 'view/cnes/ImportacaoCnesView'

import {
  ANEXO_ARQUIVOS_ACCEPTED_MEDIA_TYPES,
  ANEXO_ARQUIVOS_DEFAULT_ERROR_MESSAGE,
  ANEXO_ARQUIVOS_MAX_FILE_SIZE,
  ANEXO_ARQUIVOS_OBSERVACOES_PLACEHOLDER,
  AnexoArquivoFormModel,
  AnexoArquivosStatusMessagesModel,
  fileErrorMessageRecord,
} from '../model-anexoArquivos'
import { salvarAnexoArquivo } from '../salvarAnexoArquivo'
import { CategoriaArquivoSelectField } from './CategoriaArquivosSelectField'
import { validateAnexoArquivo } from './validator-anexoArquivosForm'

const path = metaPath<AnexoArquivoFormModel>()

interface AnexoArquivosModalProps {
  onConfirm: (value: AnexoArquivoFormModel) => void
  onClose: () => void
}

export function AnexoArquivosModal(props: AnexoArquivosModalProps) {
  const { onConfirm: handleConfirm, onClose } = props

  const alert = useAlert()
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [hasFileErrors, setHasFileErrors] = useState<boolean>(false)
  const [fileUploaderKey, setFileUploaderKey] = useState<string>(uuidv4())
  const [statusMessage, setStatusMessage] = useState<AnexoArquivosStatusMessagesModel>(null)

  const setErrorMessage = (message: string) => setStatusMessage({ type: 'danger', message })

  const setSuccessMessage = (message: string) => setStatusMessage({ type: 'success', message })

  const clearMessage = () => setStatusMessage(null)

  const handleSubmitWrapper = (value: AnexoArquivoFormModel) => {
    handleConfirm(value)
    alert('success', 'Anexo de arquivo registrado com sucesso')
  }

  const handleUpload = (formData: FormData, config: AxiosRequestConfig) => {
    setIsUploading(true)
    setHasFileErrors(false)
    return salvarAnexoArquivo(formData, config)
      .catch((error) => {
        const errorMessage = error.response.data?.message ?? error.response.data ?? ANEXO_ARQUIVOS_DEFAULT_ERROR_MESSAGE

        setHasFileErrors(true)
        setErrorMessage(errorMessage)

        throw error
      })
      .finally(() => {
        setIsUploading(false)
      })
  }

  const handleRejection = (errors: FileError[]) => {
    setHasFileErrors(true)
    setErrorMessage(fileErrorMessageRecord[errors[0]?.code] ?? ANEXO_ARQUIVOS_DEFAULT_ERROR_MESSAGE)
  }

  const resetFileUploaderState = () => setFileUploaderKey(uuidv4())

  const handleClose = (dirty: boolean) => {
    if (dirty) {
      confirm({
        title: <Heading level={2}>Deseja sair sem salvar?</Heading>,
        body: <Text>As alterações realizadas serão perdidas.</Text>,
        cancelLabel: 'Não, continuar aqui',
        confirmLabel: 'Sim, sair da tela',
        onConfirm: () => {
          onClose()
        },
      })()
    } else {
      onClose()
    }
  }

  const renderForm = (formRenderProps: FormRenderProps<AnexoArquivoFormModel>) => {
    const {
      handleSubmit,
      form,
      dirty,
      valid,
      values: { arquivoId },
    } = formRenderProps

    const handleSave = (event: React.SyntheticEvent<HTMLFormElement>, closeOnSave: boolean = false) => {
      handleSubmit(event)
      if (valid) {
        setTimeout(form.reset)
        form.getRegisteredFields().forEach((item) => form.resetFieldState(item))
        resetFileUploaderState()

        if (closeOnSave) {
          onClose()
        } else {
          setSuccessMessage('Anexo de arquivo registrado com sucesso.')
        }
      } else if (!hasFileErrors) {
        setErrorMessage(
          isEmpty(arquivoId)
            ? 'É necessário incluir um arquivo anexado para registrar um anexo de arquivos.'
            : 'Existem campos preenchidos de forma incorreta.'
        )
      }
    }

    return (
      <Modal open size='auto' closeOnBackdropClick={false} onClose={() => handleClose(dirty)}>
        <ModalBody style={styles.modalBody}>
          <Grid>
            <Cell size={12}>
              <Heading level={1}>Anexo de arquivos</Heading>
            </Cell>

            {statusMessage && (
              <Cell size={12}>
                <Alert type={statusMessage.type} styles={styles.alertContainer} onCloseClick={clearMessage}>
                  {statusMessage.message}
                </Alert>
              </Cell>
            )}

            <Cell size={12}>
              <Alert type='info' styles={styles.alertContainer}>
                Inclua abaixo o arquivo que deseja anexar ao prontuário do cidadão. Resultados de exames laboratoriais
                devem ser registrados no &nbsp;
                <Text component='span' color='info' fontWeight='bold'>
                  Adicionar resultados de exames.
                </Text>
              </Alert>
            </Cell>

            <Cell size={12}>
              <FileUploaderField
                name={path.arquivoId}
                key={fileUploaderKey}
                text='Clique para importar ou arraste o arquivo'
                disabled={false}
                uploadRequest={handleUpload}
                accept={ANEXO_ARQUIVOS_ACCEPTED_MEDIA_TYPES}
                maxSize={ANEXO_ARQUIVOS_MAX_FILE_SIZE}
                onRejection={handleRejection}
              />
              <DownloadInfo text={downloadInfoText} />
            </Cell>

            <Cell size={12}>
              <TextField
                label='Título'
                placeholder='Informe um título que identifique o arquivo anexado'
                name={path.titulo}
                required
                maxLength={80}
              />
            </Cell>

            <Cell size={6}>
              <CategoriaArquivoSelectField
                required
                placeholder='Busque por uma categoria'
                name={path.categoria}
                label={
                  <Fragment>
                    Categoria do arquivo &nbsp;
                    <InfoIcon
                      icon='infoCircleFilled'
                      text='A categoria do arquivo tem como objetivo auxiliar a busca pelos arquivos posteriormente. Selecione a categoria que mais está relacionada ao arquivo que está sendo anexado.'
                    />
                  </Fragment>
                }
              />
            </Cell>

            <Cell size={12}>
              <TextAreaField
                label='Observações'
                placeholder={ANEXO_ARQUIVOS_OBSERVACOES_PLACEHOLDER}
                name={path.observacao}
                maxLength={200}
                style={styles.textArea}
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HFlow justifyContent='space-between'>
            <HFlow justifyContent='flex-start'>
              <Button style={styles.footerButtons} onClick={() => handleClose(dirty)}>
                Cancelar
              </Button>
            </HFlow>
            <HFlow justifyContent='flex-end'>
              <SubmitButton style={styles.footerButtons} handleSubmit={handleSave} disabled={isUploading}>
                Salvar e adicionar outro
              </SubmitButton>
              <SubmitButton
                style={styles.footerButtons}
                kind='primary'
                handleSubmit={(event) => handleSave(event, true)}
                disabled={isUploading}
              >
                Salvar
              </SubmitButton>
            </HFlow>
          </HFlow>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Form<AnexoArquivoFormModel>
      render={renderForm}
      onSubmit={handleSubmitWrapper}
      validate={validateAnexoArquivo}
      suppressNotificationError
    />
  )
}

const downloadInfoText = <Text>Apenas arquivos com extensão PDF, JPEG, JPG ou PNG são permitidos.</Text>

const styles = {
  modalBody: css`
    width: 40rem;
    padding-left: 1rem;
    padding-right: 1rem;
  `,
  textArea: css`
    min-height: 4rem;
    resize: vertical;
  `,
  footerButtons: css`
    min-width: 8rem;
  `,
  alertContainer: {
    container: css`
      padding: 0.5rem 0;
    `,
  },
}
