/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { AccordionInformation } from 'components/accordion/AccordionInformation'
import { FormDebouncedValueSpy, SimNaoEnum } from 'components/form'
import { SwitchButtonField } from 'components/form/field/SwitchButtonField'
import { FormState } from 'final-form'
import { useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import { MCHAT_PERGUNTAS, MChatAplicacaoModel, MChatPerguntaEnum } from './model-mchat'

const meta = metaPath<MChatAplicacaoModel>()

interface MChatModalSectionAdicionarProps {
  handleFormChange: (formState: FormState<MChatAplicacaoModel>) => void
}

export function MChatModalSectionAdicionar(props: MChatModalSectionAdicionarProps) {
  const { handleFormChange } = props

  const theme = useTheme()
  const styles = useMemo(() => createStyles(theme), [theme])

  return (
    <VFlow>
      <FormDebouncedValueSpy wait={0} onChange={handleFormChange} />
      <Heading level={1}>Avaliar M-CHAT-R™</Heading>
      <AccordionInformation uuid='mchat-info' type='normal' header='Sobre o M-CHAT™'>
        <Text fontSize={0.875} component='p' fontWeight='bold'>
          O M-CHAT-R™ pode ser aplicado por profissionais que desejam avaliar o risco de Transtorno do espectro autista
          (TEA).
        </Text>
        <Text fontSize={0.875} component='p' fontWeight='bold'>
          O principal objetivo do M-CHAT-R™ é detectar o máximo possível de casos de TEA.
        </Text>
        <VFlow vSpacing={0} style={styles.listItemsBox}>
          <Text fontSize={0.875} component='li'>
            Portanto, existe alta taxa de falso positivo, o que significa que nem todas as crianças que apresentarem
            escore de risco serão diagnosticadas com TEA. Por isso, foram acrescentadas as questões da consulta de
            seguimento (M-CHAT-R/F™). Os usuários do instrumento devem estar cientes que, mesmo com a consulta de
            seguimento, um número significativo de crianças com pontuação positiva no M-CHAT-R™ não serão diagnosticadas
            com TEA. Entretanto, essas crianças são de elevado risco para outros transtornos ou atrasos do
            desenvolvimento, de modo que, estas devem ser reavaliadas.
          </Text>
          <Text fontSize={0.875} component='li'>
            Em caso de suspeita por parte da família ou do profissional da equipe, a criança deverá ser encaminhada para
            avaliação mesmo que o resultado seja de baixo risco.
          </Text>
        </VFlow>
      </AccordionInformation>
      <VFlow vSpacing={0} style={styles.box}>
        <div css={styles.header}>
          <Text fontWeight='bold'>
            “Por favor, responda as questões abaixo sobre o seu filho. Pense em como ele geralmente se comporta. Se você
            viu o seu filho apresentar o comportamento descrito poucas vezes, ou seja, se não for um comportamento
            frequente, então responda não.”
          </Text>
        </div>
        {Object.entries(MCHAT_PERGUNTAS).map(([perguntaEnum, perguntaText]) => (
          <HFlow justifyContent='space-between' alignItems='center' style={styles.item} key={perguntaEnum}>
            <Text component='p'>{perguntaText}</Text>

            <SwitchButtonField<SimNaoEnum>
              name={meta[perguntaEnum as MChatPerguntaEnum]}
              options={[
                { label: 'Sim', value: SimNaoEnum.SIM },
                { label: 'Não', value: SimNaoEnum.NAO },
              ]}
            />
          </HFlow>
        ))}
      </VFlow>
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  listItemsBox: css`
    margin-left: 1.2rem;
  `,
  box: css`
    background: ${theme.pallete.surface.main};
    border-radius: 2px;
    border: 1px solid ${theme.pallete.divider};
    & > * {
      border-bottom: 1px solid ${theme.pallete.divider};

      &:last-child {
        border-bottom: 0;
      }
    }
  `,
  header: css`
    padding: 1rem;
    background: ${theme.pallete.gray.c90};
  `,
  item: css`
    padding: 1rem;
  `,
})
