import { Cbo } from 'graphql/types.generated'
import { ProfissionalAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { AnexoArquivoFormModel, AnexoArquivoRowModel } from '../model-anexoArquivos'
import { sortAnexoArquivos, SortAnexoArquivosField } from './sortAnexoArquivos'

export function mergeSortAnexoArquivos(
  anexoArquivosDatabase: ReadonlyArray<AnexoArquivoRowModel>,
  anexoArquivosCache: ReadonlyArray<AnexoArquivoFormModel>,
  atendimentoProfissionalId: ID,
  dataAtendimento: Instant,
  lotacaoId: ID,
  profissional: ProfissionalAtendimento,
  cbo: Cbo,
  sort: ReadonlyArray<SortAnexoArquivosField> = []
): AnexoArquivoRowModel[] {
  const anexoArquivosCacheMapeados = anexoArquivosCache.map((value, index) =>
    createAnexoArquivoFromCache(value, index, atendimentoProfissionalId, dataAtendimento, lotacaoId, profissional, cbo)
  )
  const anexoArquivosDatabaseOrdenados = sortAnexoArquivos(anexoArquivosDatabase, sort)
  return [...anexoArquivosCacheMapeados, ...anexoArquivosDatabaseOrdenados]
}

function createAnexoArquivoFromCache(
  anexoArquivoCache: AnexoArquivoFormModel,
  index: number,
  atendimentoProfissionalId: ID,
  dataAtendimento: Instant,
  lotacaoId: ID,
  profissional: ProfissionalAtendimento,
  cbo: Cbo
): AnexoArquivoRowModel {
  return {
    cacheId: index,
    arquivo: { id: anexoArquivoCache.arquivoId },
    categoria: anexoArquivoCache.categoria,
    titulo: anexoArquivoCache.titulo,
    observacao: anexoArquivoCache.observacao,
    atendimentoProfissional: {
      id: atendimentoProfissionalId,
      iniciadoEm: dataAtendimento,
      lotacao: {
        id: lotacaoId,
        cbo: {
          id: cbo.id,
          nome: cbo.nome,
        },
        profissional: {
          id: profissional.id,
          nome: profissional.nome,
          cpf: profissional.cpf,
          cns: profissional.cns,
        },
      },
    },
  }
}
